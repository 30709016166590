import React from "react";

import { CompactPicker } from "react-color";
import { IUiSchemaElemArgs, registerComponentHandler } from './SchemaController';





const colorPickerExtension = (args: IUiSchemaElemArgs) => {

	const { key, value, update, readOnly } = args;

	return (
		args.embedObject(<CompactPicker disabled={readOnly} key={key} triangle="hide" color={value} onChange={
			(color, event) => !readOnly && update({ value: color.hex })
		} />)
	);
};


registerComponentHandler("color-picker", colorPickerExtension);
