import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';

import { faAdd, faEdit } from '@fortawesome/free-solid-svg-icons'

import { IRowType, pageUrlPart, pageTitle } from "./BlockedGateways";
import { getAppBase } from '../../utils/consts';
import { strings } from "../../services/Localization";
import { TAB_ACCEPT_GATEWAY, TAB_ADD_PARAM } from '../../datatypes/tabsconstants'

const detailPageNav = ( navigate, tab, id, row = null, prevPageUrl) => {

    if(id) {
        navigate(`${getAppBase()}/${pageUrlPart}/${id}/${tab}`, {state: {row: row, prevPageUrl}, replace:false})
    }
}

export const GatewayParametersProps: DetailPagePropsType =  {
    getBreadCrumbArr: (breadCrumbArgs) => {
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/blocked-gateways`;
        
        const breadCrumb:BreadCrumbType[] = [
            { label: pageTitle, url: prevPageUrl },
            { label:  "Add Parameter", url:''}
        ]

        return breadCrumb;
    },
    getActions : (actionArgs, navigate) => {
        
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/blocked-gateways`;

        const tabs: DetailPageActionType[] = [
            {
                type: "tab",
                text: "Add Parameter",
                icon: faAdd,
                key: TAB_ADD_PARAM,
                action: (id) => detailPageNav(navigate, TAB_ADD_PARAM, '', {}, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/defaultgatewayparameters?get_schema=dassui,post`,
                    backUrl: prevPageUrl,
                },
            }
        ];
        return tabs;
    }
}



export const BlockedGatewaysRowProps: DetailPagePropsType =  {

    getBreadCrumbArr: (breadCrumbArgs) => {
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/${pageUrlPart}`;
        const { row } = breadCrumbArgs;
        const breadCrumb:BreadCrumbType[] = [
            {label:strings.NAV_GATEWAYS_ITEM, url:`${getAppBase()}/omc-gateways`},
            {label: pageTitle, url: prevPageUrl},
            {label: "Edit Parameter", url:'', comment: row.uuid}
        ]

        return breadCrumb;
    },
    getActions: (actionArgs, navigate) => {

        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/${pageUrlPart}`;
        const row: IRowType = actionArgs.row

        const tabs: DetailPageActionType[] = [
            {
                type: "tab",
                text: "Accept Gateway",
                icon: faEdit,
                key:TAB_ACCEPT_GATEWAY,
                action: (row: IRowType) => detailPageNav(navigate, TAB_ACCEPT_GATEWAY, row.id, row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/blocked-gateways?get_schema=dassui,accept-gateway,put`,
                    backUrl: prevPageUrl
                },
                EditObject: row.id ? { id: row.id } : {id: row.uuid}
            }
        ];

        return tabs;    
    }
}


