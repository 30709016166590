import React, { useState , useEffect } from "react";
import ReactDOM from 'react-dom';
import { Modal, Button } from 'react-bootstrap';
import confirmDialogImage from "../../../resources/images/delete.png";

import LocStr$ from "../../localization/LocaleString";

import { faTriangleExclamation, IconDefinition }  from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IConfirmDialogeSettings {
    title: string;
    description: string | any[];
    actionLabel: string;
    cancelLabel?: string;
    faIcon?: IconDefinition;
};


interface IConfirmDialogProps {

    confirmDialogeSettings: IConfirmDialogeSettings;
    closeConfirmDialog?: () => void;
    confirmAction?: () => void;
    show?: boolean;
}


export { IConfirmDialogProps }

const ConfirmDialog: React.FC<IConfirmDialogProps> = ( props ) => {
    const [show, setShow] = useState(true);

    const handleClose = () =>  {
        setShow(oldshow => false)
        props.closeConfirmDialog();
    }

    const handleClick = () =>  {
        setShow(oldshow => false)
        props.confirmAction();
    }

    useEffect(() => {
        setShow(preShow => props.show);
    },[props.show])

    return (<Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header className="border-bottom-0" closeButton>
            </Modal.Header>
            <Modal.Body className="text-center">

                {props.confirmDialogeSettings.faIcon && <FontAwesomeIcon icon={props.confirmDialogeSettings.faIcon} size="6x" />}
                {props.confirmDialogeSettings.faIcon && <br />}
                {!props.confirmDialogeSettings.faIcon && <img className="mb-3" src={confirmDialogImage} />}
                <br/>
                <p className="confirm-dialog-title">{props.confirmDialogeSettings.title}</p>
                <strong> {props.confirmDialogeSettings.description}</strong>
            </Modal.Body>
            <Modal.Footer className="border-top-0">
                {(props.confirmDialogeSettings.cancelLabel == null || props.confirmDialogeSettings.cancelLabel) &&
                <Button variant="outline-dark" size="sm" onClick={handleClose}>
                    {props.confirmDialogeSettings.cancelLabel || LocStr$("Cancel") }
                </Button>}
                <Button variant="dark" size="sm" onClick={handleClick}>
                    {props.confirmDialogeSettings.actionLabel}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const dialog = async (confirmDialogeSettings: IConfirmDialogeSettings) => {

    const result = new Promise<boolean>((resolve, reject) => {

    let confirmAction = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById("dailog"))
        resolve(true)
    }

    let closeConfirmDialog = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById("dailog"))
        resolve(false)
    }

    ReactDOM.render(<ConfirmDialog
        confirmAction={confirmAction}
        confirmDialogeSettings={confirmDialogeSettings}
        show={true}
        closeConfirmDialog={closeConfirmDialog} />, document.getElementById("dailog"));
    });

    return result;
}

const dialogDescription = async(ids) => {

    if (ids[0].length < 17 && ids.length == 1) {
        return `${ids[0]}`;
    } else if (ids[0].length < 17 && ids.length == 2) {
        return `${ids[0]} and ${ids[1]}`;
    } else if (ids[0].length < 17 && ids.length == 3) {
        return `${ids[0]}, ${ids[1]} and ${ids[2]}`;
    } else if (ids[0].length > 17 && ids[0].length < 50 && ids.length == 1 ) {
        return `${ids[0]}`;
    } else if (ids[0].length > 17 && ids[0].length < 35 && ids.length == 2 ) {
        return `${ids[0]} and ${ids[1]}`;
    } else if (ids[0].length > 17 && ids[0].length < 33 && ids.length > 2 ) {
        return "";
    } else if (ids[0].length > 34 && ids.length > 1) {
        return "";
    } else {
        return ""; 
    } 
}

const notImplemented = async () => {
    return await dialog({
        title: "Not implemented",
        description: "This feature is not yet implemented. Please check back later.",
        actionLabel: "OK",
        cancelLabel: "",
        faIcon: faTriangleExclamation,
    });
}


export { dialog, notImplemented, dialogDescription}
