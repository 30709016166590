import { strings } from "./../../services/Localization";

import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';

import { faAdd } from '@fortawesome/free-solid-svg-icons'

import { getAppBase } from '../../utils/consts';

import { TAB_GATEWAY_CONFIGURATION, TAB_ADD_GATEWAY } from '../../datatypes/tabsconstants'


import { pageTitle, pageUrlPart, IRowType } from "./MyGateways";


const detailPageNav = (detailPageNavArgs) => {
    const { navigate, tab, id, row } = detailPageNavArgs;

    let prevPageUrl = detailPageNavArgs.hasOwnProperty('detailPageNavArgs') ? detailPageNavArgs['prevPageUrl'] : null;

    if (id) {
        navigate(`${getAppBase()}/${pageUrlPart}/${id}/${tab}`, { state: { row: row, prevPageUrl: prevPageUrl } })
    } else {
        navigate(`${getAppBase()}/${pageUrlPart}/${tab}`, { state: { tab: tab, prevPageUrl: prevPageUrl } })
    }
}




const GatewayProps: DetailPagePropsType = {
    getBreadCrumbArr: (breadCrumbArgs) => {

        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '';

        const breadCrumb:BreadCrumbType[] = [
            { label: pageTitle, url: prevPageUrl },
            { label: strings.ADD_GATEWAY, url: '' }
        ]
        return breadCrumb;
    },
    getActions: (actionArgs, navigate) => {

        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';

        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.ADD_GATEWAY,
                icon: faAdd,
                key: TAB_ADD_GATEWAY,
                action: (id) => detailPageNav({ navigate: navigate, tab: TAB_ADD_GATEWAY, id: '', row: null, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/gateways?get_schema=dassui,post`,
                    backUrl: prevPageUrl
                },
                EditObject: null
            },
        ]
        return actions;
    }
}


export { GatewayProps };


const GatewayRowProps: DetailPagePropsType = {
    getBreadCrumbArr: (breadCrumbArgs: any) => {
        const { id, row } = breadCrumbArgs;
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '-1';
        const breadCrumb:BreadCrumbType[] = [
            { label: pageTitle, url: prevPageUrl },
            { label: `${id}`, url: '', comment: row.name }
        ]
        return breadCrumb;
    },
    getActions: (actionArgs, navigate) => {
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';
        let row = actionArgs.hasOwnProperty('row') ? actionArgs['row'] : {};
        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.GATEWAY_CONFIGURATION,
                icon: faAdd,
                key: TAB_GATEWAY_CONFIGURATION,
                action: (row: IRowType) => detailPageNav({ navigate: navigate, tab: TAB_GATEWAY_CONFIGURATION, id: '', row: row, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/gateways?get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: row.id ? { gtwid: row.id } : null
            },
            // {
            //     type: "tab",
            //     text: strings.VIEW_GATEWAY,
            //     icon: faAdd,
            //     key: TAB_GATEWAY_LOCATION,
            //     action: (row: IRowType) => detailPageNav(navigate, TAB_GATEWAY_LOCATION, row.id, row),
            //     content: {
            //         type: 'SchemaModal',
            //         url: `/rest/gateways?get_schema=dassui,put`,
            //         backUrl: `${getAppBase()}/${pageUrlPart}`
            //     },
            //     EditObject: row.id ? { gtwid: row.id } : null
            // },
        ]
        return actions;
    }
}


export { GatewayRowProps }