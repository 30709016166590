import { faAdd } from '@fortawesome/free-solid-svg-icons'
import { strings } from "./../../services/Localization";
import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';
import { getAppBase } from '../../utils/consts';
import { IRowType } from "./OmcGateways";
import { TAB_ADD_GATEWAY, TAB_GATEWAY_CONFIGURATION } from '../../datatypes/tabsconstants'
import AppContext from '../../context/AppContext'
import { useContext } from "react";

/* Below are omc Gateway rights required to peform specific operation action or edit in gateway info */
const omcGatewaysRights = [

    "omc_mng_gateway_logs",
    "omc_mng_gtw_rf_conf",
    "omc_mng_gtw_antennagain_txpower",
    "omc_mng_gtw_wan_conf",
    "omc_mng_gtw_info",
    "omc_reboot_gtw",
    "omc_delete_gtw",
    "omc_mng_gtw_tags",
    "omc_start_scanner",
    "omc_mng_gtw_alarms",
    "omc_can_access_terminal",
]


const detailPageNav = ( detailPageNavArgs ) => {

    const { navigate, tab, gtwid, row } = detailPageNavArgs;
    const prevPageUrl = detailPageNavArgs.hasOwnProperty('detailPageNavArgs') ? detailPageNavArgs['prevPageUrl'] : null;
    if (gtwid) {
        navigate(`${getAppBase()}/omc-gateways/${gtwid}/${tab}`, {state: {row: row, prevPageUrl}})
    } else {
        navigate(`${getAppBase()}/omc-gateways/${tab}`, {state: {tab: tab, prevPageUrl}})
    }

}


export const OmcGatewayProps:DetailPagePropsType =  {

    getBreadCrumbArr: (breadCrumbArgs) => {
        const prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '';
        const breadCrumb:BreadCrumbType[] = [{label: strings.ACCOUNTS, url:prevPageUrl}]
        return breadCrumb;

    },
    getActions : (actionArgs, navigate) => {
        const prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';
        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.ADD_GATEWAY,
                icon: faAdd,
                key: TAB_ADD_GATEWAY,
                action: (id) => detailPageNav({ navigate: navigate, tab:TAB_ADD_GATEWAY, id:'', row:null, prevPageUrl:prevPageUrl}),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/gateways?get_schema=dassui,post`,
                    backUrl: prevPageUrl
                },
                EditObject: null
            },
        ]
        return actions;
    }
}


export const OmcGatewayRowProps: DetailPagePropsType =  {

    getBreadCrumbArr: (breadCrumbArgs) => {
        const { id , row } = breadCrumbArgs;
        const prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '-1';

        const breadCrumb:BreadCrumbType[] = [

            {label: strings.NAV_GATEWAYS_ITEM, url:prevPageUrl},
            {label: `${id}`, url:'', comment: row.name}

        ]
        return breadCrumb;
    },
    getActions : (actionArgs, navigate) => {
        const prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';
        const row =  actionArgs.hasOwnProperty('row') ? actionArgs['row'] : {};

        /* We are here checking of specific rights related to gateway in user and 
        if anyone rights is present then Click to Unlock button is shown on UI
        */
        const AppContextObj = useContext(AppContext);
        const user = AppContextObj.user;
        const __readonly = omcGatewaysRights.filter(x => Object.keys(user).includes(x)).length > 0
        
        const actions:DetailPageActionType[] = [

            {
                type: "tab",
                text: strings.GATEWAY_CONFIGURATION,
                icon: faAdd,
                key: TAB_GATEWAY_CONFIGURATION,
                action: (row) => detailPageNav({ navigate: navigate, tab:TAB_GATEWAY_CONFIGURATION, id:row.id, row:row, prevPageUrl:prevPageUrl}),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/gateways?get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: { gtwid: row.id ? row.id : row?.uuid || row?.gatewayUuid,  __readonly: __readonly } 
            },
        ]

        return actions;
    }
}


export const OmcGatewayMapRowProps: DetailPagePropsType =  {

    getBreadCrumbArr: (breadCrumbArgs) => {
        const { id , row } = breadCrumbArgs;
        const prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '/nst/omc-gateways';

        const breadCrumb:BreadCrumbType[] = [
            
            {label: strings.NAV_GATEWAYS_ITEM, url: prevPageUrl},
            {label: `${id}`, url:'', comment: row.name}

        ]
        return breadCrumb;
    },
    getActions : (actionArgs,  navigate) => {
        
        const prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';
        const row:IRowType =  actionArgs.hasOwnProperty('row') ? actionArgs['row'] : {};

        /* We are here checking of specific rights related to gateway in user and 
        if anyone rights is present then Click to Unlock button is shown on UI
        */
        const AppContextObj = useContext(AppContext);
        const user = AppContextObj.user;
        const __readonly = omcGatewaysRights.filter(x => Object.keys(user).includes(x)).length > 0

        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.GATEWAY_CONFIGURATION,
                icon: faAdd,
                key: TAB_GATEWAY_CONFIGURATION,
                action: (row) => detailPageNav({ navigate: navigate, tab:TAB_GATEWAY_CONFIGURATION, id:row.id, row:row, prevPageUrl:prevPageUrl}),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/gateways?get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: { gtwid: row?.uuid || row?.id,  __readonly: __readonly}
            },
        ]
        return actions;
    }
}