import { strings } from "./../../services/Localization";

import { DetailPageActionType, DetailPagePropsType } from '../../datatypes/datatypes';

import { faAdd, faList } from '@fortawesome/free-solid-svg-icons'

//import DeviceDataProps from './DeviceDataProps';

import { TAB_DEVICE_DETAIL, TAB_ADD_DEVICE, TAB_SEND_TO_DEVICE, TAB_VIEW_DATA } from '../../datatypes/tabsconstants'
import { getAppBase } from "../../utils/consts";

import AppContext from '../../context/AppContext'
import { useContext } from "react";



const detailPageNav = (detailPageNavArgs) => {

    const { navigate, tab, deveui, row } = detailPageNavArgs;

    let prevPageUrl = detailPageNavArgs.hasOwnProperty('prevPageUrl') ? detailPageNavArgs['prevPageUrl'] : null;
    //const deveui = id;
    if (deveui) {

        navigate(`/app/my-devices/${deveui}/${tab}`, { state: { row: row, prevPageUrl: prevPageUrl } })

    } else {

        navigate(`/app/my-devices/${tab}`, { state: { tab: tab, prevPageUrl: prevPageUrl } })
    }

}


const DevicePageProps: DetailPagePropsType = {
    getBreadCrumbArr: (breadCrumbArgs) => {

        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '-1';


        return [
            { label: strings.MY_DEVICES_TITLE, url: prevPageUrl },
            { label: strings.ADD_DEVICE_BTN, url: '' }
        ]
    },
    getActions: (actionArgs, navigate) => {

        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '';

        return [
            {
                type: "tab",
                text: strings.ADD_DEVICE_BTN,
                icon: faAdd,
                key: TAB_ADD_DEVICE,
                action: (id) => detailPageNav({ navigate: navigate, tab: TAB_ADD_DEVICE, deveui: '', row: {}, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/nodes?get_schema=dassui,post`,
                    backUrl: prevPageUrl
                },
            }

        ]
    }
}

export { DevicePageProps };




const DeviceRowProps: DetailPagePropsType = {
    getBreadCrumbArr: (breadCrumbArgs) => {
        const { id, row, prevPageUrl } = breadCrumbArgs;


        return [
            { label: strings.MY_DEVICES_TITLE, url: prevPageUrl },
            { label: `${id}`, url: '', comment: row.comment }
        ]
    },
    getActions: (actionArgs, navigate) => {
        const { row } = actionArgs;
        const deveui = row.hasOwnProperty('deveui') ? row.deveui : (row.hasOwnProperty('id') ? row.id : null);
        const prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs.prevPageUrl : null;
        const AppContextObj = useContext(AppContext);
        const user = AppContextObj.user;
        const isTransmitCapable = row?.transmit_capable_only ?? false
        //const DeviceDataObj = new DeviceDataProps(deveui, user);
     

        const actions: DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.MY_DEVICES_DETAIL,
                icon: faAdd,
                key: TAB_DEVICE_DETAIL,
                action: (row) => detailPageNav({ navigate: navigate, tab: TAB_DEVICE_DETAIL, deveui: deveui, row: row, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/nodes?get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: deveui ? { deveui } : null
            },

            {
                type: "tab",
                text: strings.MY_DEVICES_ACTION_SEND,
                icon: faAdd,
                key: TAB_SEND_TO_DEVICE,
                action: (row) => detailPageNav({ navigate: navigate, tab: TAB_SEND_TO_DEVICE, deveui: deveui, row: row, prevPageUrl: prevPageUrl }),
                visible: () => !user?._readonly && !isTransmitCapable,
                content: {
                    type: 'SchemaModal',
                    url: `/rest/nodes/${deveui}/payloads/dl?get_schema=dassui,post`,
                    backUrl: `${getAppBase()}/my-devices/${deveui}/${TAB_VIEW_DATA}`
                },
                EditObject: null
            },

            {
                type: "tab",
                text: strings.MY_DEVICES_ACTION_DATA,
                icon: faList,
                key: TAB_VIEW_DATA,
                action: (row) => detailPageNav({ navigate: navigate, tab: TAB_VIEW_DATA, deveui: deveui, row: row, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'Component',
                    url: '',
                    backUrl: prevPageUrl,
                    props: {deveui:deveui, user:user},
                    ComponentName: 'DeviceViewDataTable'
                    // pageButtons: DeviceDataObj.getPageButtons(),
                    // columns: DeviceDataObj.getColumns(),
                }
            },

        ]

        return actions;
    }
}


export { DeviceRowProps }