import React, { forwardRef, ReactNode, Ref, useImperativeHandle, useRef } from "react";

import Masonry, { MasonryOptions, MasonryPropTypes } from "react-masonry-component";


import "./SchemaLayout.css";

interface ISchemaLayoutProps {
	children: ReactNode;
	useLegacyMasonry?: boolean;
}

export interface ISchemaLayoutRef {
	reLayout: () => void;
}

const SchemaLayout = forwardRef((props: ISchemaLayoutProps, ref: Ref<ISchemaLayoutRef>) =>  {

	const masonryRef = useRef<React.Component<MasonryPropTypes>>(null);
	const masonryOptions: MasonryOptions = {
		transitionDuration: 100,
		percentPosition: true,
		initLayout: true,
	};

	// Expose the layout function
	useImperativeHandle(ref, () => ({
		reLayout: () => { (masonryRef.current as any)?.performLayout(); }
	}));

	if (props.useLegacyMasonry ?? true) {
		const MasonryAny: any = Masonry;
		return (
			<MasonryAny ref={masonryRef} className="schema-modal-masonry" options={masonryOptions} >
				{props.children}
			</MasonryAny>
		);
	} else {
		return <div className="parent-container">
			{props.children}
		</div>;
	}

});

export default SchemaLayout;
