import React, { useContext } from 'react';
import { useParams, useNavigate , useLocation  } from 'react-router-dom';
import AppContext from "../../context/AppContext";
import DeviceViewDataTable from '../../pages/Devices/DeviceViewDataTable';

import GroupActivityDataTable from '../../pages/Groups/GroupActivityDataTable';

import JobsDataTable from '../../pages/GatewayJobs/GatewayJobsViewTable'

import { SchemaModal } from "../../components/SchemaModal/SchemaModal";

import { DetailPageActionType } from '../../datatypes/datatypes';

import { isTableViewTab, TAB_ADD_FUOTA_CAMPAIGN } from '../../datatypes/tabsconstants';
import { MyCampaignProps } from './MyCampaignProps';

const FuotaDetails = ( props  ) => {
    let  detailProps = MyCampaignProps;
    const appContextObj = useContext(AppContext);
    // TODO: just hardcode the tabname to device-detail to make it render at the moment
    const { id   } =  useParams();
    const tabname = TAB_ADD_FUOTA_CAMPAIGN
 

    const navigate = useNavigate();
    const { state } = useLocation();

    let profileType = null;

    let row:any = {id:id};
    
    let prevPageUrl = null;
    
    let selectedIds;

    let selectedDevices;

    let selectedFirmware;

    if(state) {
        row = (state.hasOwnProperty("row")) ? state['row'] : {id:id};
        
        prevPageUrl = (state.hasOwnProperty("prevPageUrl")) ? state['prevPageUrl'] : null;

        selectedIds = (state.hasOwnProperty("selectedIds")) ? state['selectedIds'] : null;

        selectedDevices = (state.hasOwnProperty("selectedDevices")) ? state['selectedDevices'] : null;

        selectedFirmware = (state.hasOwnProperty("selectedFirmware")) ? state['selectedFirmware'] : null;
    }
    
    if(props.hasOwnProperty('profileType')) {
        profileType = props.profileType;
    }
    

    let breadCrumbArgs = {}
    let actionArgs = {};
    
    if(id) {
        breadCrumbArgs['id'] = id;
    }

    if (tabname) {
        breadCrumbArgs['tabname'] = tabname;
    }
    
    if(prevPageUrl) {
        breadCrumbArgs['prevPageUrl'] = prevPageUrl;
        actionArgs['prevPageUrl'] = prevPageUrl;
    }

    if(selectedDevices) {
        actionArgs['selectedDevices'] = selectedDevices;
    }

    if(selectedFirmware) {
        actionArgs['selectedFirmware'] = selectedFirmware;
    }

    if(profileType) {
        breadCrumbArgs['profileType'] = profileType;
        actionArgs['profileType'] = profileType;
    }
    
    if(row) {
        breadCrumbArgs['row'] = row;
        actionArgs['row'] = row;
    }
    
    if(selectedIds) {
        actionArgs['selectedIds'] = selectedIds;
    }

    if (tabname) {
        actionArgs['tabname'] = tabname;
    }

    
    let actions: any = [];
    
    actions = detailProps.getActions(actionArgs, navigate);
    const user = appContextObj?.user || null; 
    const impersonatedClass = (appContextObj.isSignedIn(user) && appContextObj.isForwardedSignIn() && appContextObj.navBarState.forwardedSignIn )? 'child-tab-wrapper-impersonated' : 'child-tab-wrapper'

    const mainClass = (isTableViewTab(tabname)) ? "child-table-wrapper" : impersonatedClass;

    return (<div className={`${mainClass}`} >
                <div className="d-flex flex-column flex-grow border-2 border-primary" style={{height: '100%'}}>
                    <BuildContent actions={actions} tabname={tabname}  id={id}  row={row} profileType={profileType} />
                </div>
        </div>
        )
}

export default FuotaDetails;


const BuildContent: React.FC<{actions: DetailPageActionType[], tabname: string, id: string, row: any, profileType: any}> = ({ actions, tabname, id , row , profileType }) => {
    
    const filteredActions = actions.filter(item => { return (item.icon && item.type === 'tab' && item.content.type === 'SchemaModal') ? true : false  })
                                    .filter(item => typeof item.visible === "function" ? item.visible() : true)
    
    const list = filteredActions.map(function(action, index) {
         return (<OutputSchemaModal action={action} tabname={tabname} id={id} key={index} />)
    })

    const filteredDataTables = actions.filter(item => { return (item.icon && item.type === 'tab' && item.content.type === 'Component') ? true : false  })
    
    const tableList = filteredDataTables.map(function(action, index) {
        return (<OutputDataTable action={action} tabname={tabname} id={id} key={index} row={row} profileType={profileType} />)
   })

    return (<div style={{display: 'flex', flexDirection: 'row', justifyContent:'start', height: '100%'}}>{list}{tableList}</div>)
}

const OutputDataTable: React.FC<{action: DetailPageActionType, id: string, tabname: string, row: any, profileType: any}> = ( { action, id, tabname, row, profileType } ) => {
    
    const getDisplay = ( selected, current ) => {

        if(selected === current) {
            return "d-flex";
        }else {
            return "d-none";
        }
    }
    

    let disp = getDisplay(action.key, tabname);
    
    const ComponentName = action.content['ComponentName']
    const componentProps = action.content.props

    const RendrTable = ( props ) => {
        if(props.ComponentName === 'DeviceViewDataTable') {
            return (<DeviceViewDataTable   {...componentProps} key="device-view-data-table" />);
        } else if (props.ComponentName === 'GroupActivityDataTable') {
            return (<GroupActivityDataTable   {...componentProps} key="group-activity-data-table" />);
        } else if (props.ComponentName === 'GatewayJobsViewTable') {
            return (<JobsDataTable   {...componentProps} key="jobs-data-table" />);
        } else {
            return null;
        }

    }
    
    return (
        <div className={`${action.key} ${getDisplay(action.key, tabname)} flex-column flex-grow`} id={action.key} style={{width: '100%', marginTop: '60px'}}>
            {disp === 'd-flex' && <RendrTable ComponentName={ComponentName} />}
        </div>
    )

}

const OutputSchemaModal: React.FC<{action: DetailPageActionType, id: string, tabname: string}> = ({ action, id, tabname }) => {
    const navigate = useNavigate();

    const getDisplay = ( selected, current ) => {

        if(selected === current) {
            return "d-flex";
        }else {
            return "d-none";
        }
    }


    let displayProp = getDisplay(action.key, tabname);

    
    return (
        <div className={`${action.key} ${displayProp} flex-grow`} id={action.key} style={{width: '100%'}}>
            {displayProp === 'd-flex' && <SchemaModal
                SchemaUrl={`${action.content.url}`}
                EditObject={action.EditObject}
                loadDataOnOpen={true}
                OnClose={(flag,refreshRequired) => {
                    if(action.content.backUrl) {
                        if(action.content.backUrl === '-1') {
                            navigate(-1);
                            
                        }else {
                            navigate(action.content.backUrl, { replace: false, state: {updatedRow: flag, refreshRequired:refreshRequired}})
                        }
                    }
                    else {

                        navigate(-1);
                    }
                    
                }}
                type="page"
            />}
        </div>
    )
}