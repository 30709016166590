
    import { GenericDassQuery } from "./BasicDassQueries";

    //BatchRegServiceFn.$inject = ['$http', "$q"];

//const BatchRegDataService = () => {
        // Get individual gateway data
        // function getUserDataImpl() {
        //     var defer = $q.defer();
        //     $http.get('/whoami/').then(function (response) {
        //         defer.resolve(response.data);
        //     }, function (response) {
        //         defer.reject(response);
        //     });
        //     return defer.promise;
        // }

        // function getUserImpl() {
        //     var defer = $q.defer();

        //     getUserDataImpl().then(function (data) {
        //         defer.resolve(data.user)
        //     }).catch(function (err) {
        //         defer.reject(err);
        //     });

        //     return defer.promise;
        // }


const loadRegDataImpl =  () => {
    //var defer = $q.defer();

    const response = GenericDassQuery('registration_file.json', {prefix:'/'});
    return response;

    // $http.get('/registration_file.json').then(function (response) {
    //     defer.resolve(response.data);
    // }, function (response) {
    //     defer.reject(response);
    // });
    // return defer.promise;
}

const refreshDevicesImpl = async ( data ) => {
    const response = await GenericDassQuery('getstatus_devices', {prefix: '/', method: "PUT"});
    return response
    
}

const registerDevicesImpl = async  ( data ) => {
    const response = await GenericDassQuery('register_devices', {prefix:'/', data:data, method:"PUT"});
    return response
    
    
}

const updateDevicesImpl = async (data) => {
    const response = await GenericDassQuery('update_devices', {prefix:'/', data:data,method:"PUT"});
    return response
    //return $http.put('/update_devices', data);
}

const deleteDevicesImpl = async (data) => {
    const response = await GenericDassQuery('delete_devices', {prefix:'/', data:data,method:"PUT"});
    return response
}

const clearDevicesImpl = async  () => {
    const response = await GenericDassQuery('clear_devices', {prefix:'/',method:"PUT"});
    return response
}


export {
    loadRegDataImpl as loadRegDataService,
    refreshDevicesImpl as refreshDevicesService,
    registerDevicesImpl as registerDevicesService,
    updateDevicesImpl as updateDevicesService,
    deleteDevicesImpl as deleteDevicesService,
    clearDevicesImpl as clearDevicesService
}



//export default BatchRegDataService;