import { IUser, IWhoAmI } from "src/dassTypes";
import { IConstants } from "src/types";

declare const headerContentTypeJson;
declare const headerNoContentType;
declare const constants: IConstants;


export const userData = async (username: string | object, password?: string, otp?: string) => {

    try {
        const tz = new Date().getTimezoneOffset();
        const tf = new Date().toLocaleTimeString().match(/am|pm/i) ? "ampm" : "24h";

        await constants.wait;
        const response = await fetch("/signin?tz=" + tz + "&tf=" + tf, {
            body: JSON.stringify({
                password: password || undefined,
                otp: otp || undefined,
                userid: typeof username === "string" ? username : undefined,
                code:   typeof username === "object" ? (username as any).code : undefined,
                state:  typeof username === "object" ? (username as any).state : undefined,
            }),
            credentials: "same-origin",
            headers: headerContentTypeJson,
            method: "PUT",
        });
        if (response.status === 200) {
            return await response.json();
        } else {
            const errorText = await response.text();
            throw { status: response.status, errorText };
        }
    } catch (error) {
        throw error;
    }
};

const emptyUuid = "00000000-0000-0000-0000-000000000000";


export const Whoami = async (caller?: string) => {
    await constants.wait;
    const response = await fetch("/whoami" + (caller ? "?caller=" + caller : ""), {
        credentials: "same-origin",
        headers: headerNoContentType,
        method: "GET",
    });

    const data: IWhoAmI = await response.json();
    if (data && data.user) {
        data.user.is_tenant = !(!data.user.tenant_id || data.user.tenant_id === emptyUuid);
    }
    return data;
};

export const SignInForward = async (userid: string, baseUrl: string): Promise<IUser> => {
    await constants.wait;
    const response = await fetch("/signinforward" + (userid ? "?userid=" + userid : ""), {
        credentials: "same-origin",
        headers: headerContentTypeJson,
        method: "PUT",
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 401) { // the status should be changed!!!
        window.location.href = baseUrl + '/signout?resignin';
        return null as IUser;
    } else {
        throw response;
    }
};

export const SelectAccount = async (userid: string, baseUrl: string): Promise<IUser>  => {
    await constants.wait;
    const response = await fetch("/selectaccount" + (userid ? "?selectuserid=" + userid : ""), {
        credentials: "same-origin",
        headers: headerContentTypeJson,
        method: "PUT",
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 401) { // the status should be changed!!!
        window.location.href = baseUrl + '/signout?resignin';
        return null as IUser;
    } else {
        throw response;
    }
};

export const SessionInfo = async () => {
    await constants.wait;
    return await fetch("/session_info", {
                credentials: "same-origin",
                headers: headerNoContentType,
                method: "GET",
    });
};


export const checkLogin = ( user ) => {

    if(user.userid) {
        return true;
    }else {
        let savedUser = localStorage.getItem("user");
        if(savedUser) {
            let parsedSavedUser = JSON.parse(savedUser);
            if(parsedSavedUser.userid) {
                return true;
            }else {
                return false;
            }
        }
        return false;
    }
}