import "babel-polyfill";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import ActiveConnections from "./pages/Applications/ActiveConnections";
import EventLog from "./pages/Applications/EventLog";
import Profiles from "./pages/Profiles/Profiles";
import Accounts from "./pages/Accounts/Accounts";
import NetworkMap from "./pages/Devices/NetworkMap";
import { SignIn } from "./pages/SignIn/SignInPage";
import { ResetPassword } from "./pages/ResetPassword/ResetPasswordPage";
import { Welcome } from "./pages/Welcome/WelcomePage";
import { SwaggerPage } from "./pages/Swagger/SwaggerPage";
import { VerifyEmail } from "./pages/VerifyEmail/VerifyEmail";
import Groups from "./pages/Groups/Groups";
import MyDevices from "./pages/Devices/MyDevices";
import Alarms from "./pages/Alarms/Alarms";
import { ServerAlarmRowProps, GatewayAlarmRowProps } from "./pages/Alarms/AlarmProps";
import ServersStatus from "./pages/ServerMonitoring/ServersStatus";
import { ServerStatusProps } from "./pages/ServerMonitoring/ServersStatusProps"
import GatewayTags from "./pages/GatewayTags/GatewayTags";
import { GatewayTagsProps, GatewayTagsRowProps, GatewayTagCategorysProps, GatewayTagCategorysDeleteProps } from "./pages/GatewayTags/GatewayTagsProps";
import GatewayAutitTrail from "./pages/GatewayAuditTrail/GatewayAuditTrail";
import GatewayJobs from "./pages/GatewayJobs/GatewayJobs";
import GatewayManageAuth from "./pages/GatewayManageAuthorization/GatewayManageAuth"
import { GatewayJobsRowProps } from "./pages/GatewayJobs/GatewayJobsProps";
import GatewayParameters from "./pages/GatewayParameters/GatewayParameters";
import Tenants from "./pages/Tenants/Tenants";
import { TenantProps, TenantRowProps } from "./pages/Tenants/TenantProps";

import GroupDetailProps from "./pages/Groups/GroupDetailProps";
import GroupAddProps from "./pages/Groups/GroupAddProps";
import GroupAppAssignmentProps from "./pages/Groups/GroupAppAssignmentProps";
import DeviceAppAssignmentProps from "./pages/Devices/DeviceBulkActionsProps"
import { MyAppDetailProps, MyAppAddDetailProps, MyAppEditGlobalPushProps} from "./pages/Applications/MyAppDetailProps";
import { DevicePageProps, DeviceRowProps} from "./pages/Devices/DeviceProps";

import { GatewayRowProps, GatewayProps } from "./pages/Gateways/GatewayProps";
import { ProfileProps, ProfileRowProps } from "./pages/Profiles/ProfileProps";

import { AccountsUserProps, AccountsRowUserProps, AccountsRowCustProps, AccountsCustProps, AccountsRowOrgProps, AccountsOrgProps, AccountsRowLoginProps, AccountsLoginProps } from "./pages/Accounts/AccountsProps";


import { SchemaModal } from "./components/SchemaModal/SchemaModal";

import DetailPage from "./components/Common/DetailPage";

import BatchRegistration from "./pages/Devices/BatchRegistration";


import MyApps from "./pages/Applications/MyApps";
import MyGateways from "./pages/Gateways/MyGateways";
import AdminDevices from "./pages/Find/AdminDevices";
import AdminUsers from "./pages/Find/AdminUsers";
import Dashboard from "./pages/Dashboard";

import OmcGateways from "./pages/OmcGateways/OmcGateways";
import { OmcGatewayProps, OmcGatewayRowProps, OmcGatewayMapRowProps } from "./pages/OmcGateways/OmcGatewayProps";
import GatewayBulkActionProps from "./pages/OmcGateways/OmcGatewayBulkActionProps";

import OmcDevices from "./pages/OmcDevices/OmcDevices";
import { OmcDeviceProps, OmcDeviceRowProps } from "./pages/OmcDevices/OmcDevicesProps";



import { NavBar } from "./components/NavBar/NavBar";
import { NstNavBar } from "./components/NavBar/NstNavBar";
import { Whoami, userData } from "./services/Login";
import AppContext from "./context/AppContext";


import Timer from './components/Timer';
import BlockedGateways from "./pages/BlockedGateways/BlockedGateways";
import { BlockedGatewaysRowProps } from "./pages/BlockedGateways/BlockedGatewaysProps"
import { GatewayManageAuthProps } from "./pages/GatewayManageAuthorization/GatewayManageAuthProps"

import { JoinServers, GlobalJoinServers } from "./pages/JoinServers/JoinServers";
import { JoinServersRowProps, JoinServersProps, GlobalJoinServersRowProps, GlobalJoinServersProps } from  "./pages/JoinServers/JoinServerProps";


import { GatewayParametersProps, GatewayParametersRowProps } from "./pages/GatewayParameters/GatewayParametersProps";
import { ToastContainer } from "react-toastify";
import NstContainer from './components/NavBar/NstContainer';
import MyCampaigns from "./pages/Campaigns/MyCampaigns";

//import CreateCampaign from "./pages/Campaigns/CreateCampaign";


import { IUser } from "./dassTypes"; 
import CampaignStepper from "./pages/Campaigns/MyCampaignStepper";
import MyCampaignsSessions from "./pages/Campaigns/MyCampaignSessions";
import SessionDevices from "./pages/Campaigns/SessionDevices";
import { strings } from "./services/Localization";
import { getAppBase } from "./utils/consts";
import { IConstants } from "./types";
import { isMobile } from "react-device-detect";


declare const constants: IConstants;

function cookieMap() {
    let cookies = {};
    for (const cookie of (document.cookie?.split(';') || [])) {
        let [k, v] = cookie.split('=');
        cookies[k.trim()] = (v || "").trim();
    }
    return cookies;
}


const App = () => {
    const appContextObj = useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();
    const isNst = (String(location.pathname).indexOf('/nst'));
    const [selectedCookie, setSelectedCookie] = useState(cookieMap()["connect.sid"]);
    const user = appContextObj?.user || null; 
    const mar_imp = (appContextObj.isSignedIn(user) && appContextObj.isForwardedSignIn() && appContextObj.navBarState.forwardedSignIn )?  isMobile ? 'mar-120-mobile' : 'mar-120' : ''
    const effectCalled = useRef(false);     // In preparations for React 18

    let navWrapperClass = (location.pathname === '/signin') ? 'besides-data-table' : `${mar_imp} nav-wrapper besides-data-table` ;
    let wrapperClass = "wrapper";

    if (location.pathname === '/' || location.pathname === '/app' || location.pathname === '/nst') {

      wrapperClass = 'welcome';

    } else if (location.pathname === '/signin' || location.pathname === '/nst/network_map' || location.pathname === '/app/network_map') {

        wrapperClass = "";

    }
  
    useEffect(() => {
        async function checkWhoami() {

            try {
                // Handle remote login
                const searchObj: any = {};
                const hashObj: any = {};
                if (window.location.search) {
                    for (const arg of window.location.search.slice(1).split("&")) {
                        const i = arg.indexOf("=");
                        if (i > 0) {
                            searchObj[arg.slice(0, i)] = decodeURIComponent(arg.slice(i + 1));
                        }
                    }
                }
                if (window.location.hash) {
                    for (const arg of window.location.hash.slice(1).split("&")) {
                        const i = arg.indexOf("=");
                        if (i > 0) {
                            hashObj[arg.slice(0, i)] = decodeURIComponent(arg.slice(i + 1));
                        }
                    }
                }

                if (searchObj.code || hashObj.code) {
                    const user = await userData(searchObj.code ? searchObj : hashObj);

                    // Remove the arguments from the URL to avoid seeing the code
                    history.replaceState(null, null as any, searchObj.code ? window.location.pathname : " ");
                    appContextObj.updateUser(user);
                    
                    // After login we reload the whole page
                    window.location.assign( 
                        user.ui_settings?.landing_page === "nst"
                        && Object.keys(user).filter(k => k.startsWith("omc_")).length > 0
                            ? "/nst/network_map"
                            : (constants.landing_page_after_signin || `/`)
                    );
                    return;
                }

            } catch (error) {
                console.log("ERROR", error);
                appContextObj.signOut();
            }

            const response: any = await Whoami();
            if (response.user && response.user.userid) {
                if (appContextObj.user !== response.user) {
                    appContextObj.updateUser(response.user);
                }
            } else {
                appContextObj.updateUser({} as IUser);
            }
        }

        // React 18 will mount, unmount then mount again. Therefore this will be called twice. To avoid that we
        // need to check if it has already been called.
        if (!effectCalled.current) {
            effectCalled.current = true;
            checkWhoami();
        }
    }, []);

    useEffect(() => {

        // Extract the connection ID cookie and compare with the current ID. If changed we need to reload the page
        const connectId = cookieMap()["connect.sid"];
        if(selectedCookie != connectId) {
            console.log("Session changed, old=", selectedCookie, "new=", connectId);
            if (selectedCookie) {
                window.location.reload()
            }
            setSelectedCookie(connectId);
        }
        
    }, [document.cookie])

      // Use refs to store previous and current paths
    const previousPathRef = useRef('');
    const currentPathRef = useRef('');
 
     useEffect(() => {
         previousPathRef.current = currentPathRef.current;
         // Update the current path with the new location pathname
         currentPathRef.current = location.pathname;
     }, [location.pathname]);

    let rootClassName = (isNst === -1) ? "ob-wrapper" : "ow-nst-wrapper";
    
    return (
        <div className={rootClassName} 
            onDrop={(event) => { event.preventDefault(); return false; }}
            onDragOver={(event) => { event.preventDefault(); return false; }}
        >

            <ToastContainer />
            <div className={`${navWrapperClass}`} >
                {isNst === -1 ? <NavBar /> : <NstNavBar />}
                <Timer />
            </div>
            <div className={`${wrapperClass}`} id={`id-${wrapperClass}`}>
            <Routes>
                <Route path="/" element={<Welcome />}></Route>
                <Route path="/app" element={<Welcome />}></Route>
                <Route path="/app/mydashboard" element={<Dashboard />}></Route>
                <Route path="/app/signin" element={<SignIn />}></Route>
                <Route path="/app/reset_password" element={<ResetPassword />}></Route>
                <Route path="/app/verify_email" element={<VerifyEmail />}></Route>
                <Route path="/app/network_map" element={<NetworkMap />}>

                </Route>

                <Route path="/app/profiles/device" element={<Profiles key="device" profileType="device" />}>
                    <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps}  profileType="device" />} />
                    <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps}  profileType="device" />} />
                </Route>

                <Route path="/app/profiles/service" element={<Profiles key="service" profileType="service" />}>
                    <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="service" />} />
                    <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="service" />} />
                </Route>
                <Route path="/app/profiles/connectivity" element={<Profiles key="connectivity" profileType="connectivity" />}>
                    <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="connectivity" />} />
                    <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="connectivity" />} />
                </Route>
                <Route path="/app/profiles/roaming" element={<Profiles key="roaming" profileType="roaming" />}>
                    <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="roaming" />} />
                    <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="roaming" />} />
                </Route>  
                <Route path="/app/profiles/qos" element={<Profiles key="qos" profileType="qos" />}>
                    <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="qos"/>} />
                    <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="qos" />} />
                </Route>                                      
                <Route path="/app/profiles/channel" element={<Profiles key="channel" profileType="channel" />}>
                    <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="channel" />} />
                    <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="channel" />} />
                </Route>                                                          

                <Route path="/app/join-servers" element={<JoinServers />}>
                    <Route path=":tabname" element={<DetailPage detailProps={JoinServersProps} />} />
                    <Route path=":id/:tabname" element={<DetailPage detailProps={JoinServersRowProps} />} />
                </Route>

                <Route path="/app/sys-join-servers" element={<GlobalJoinServers />}>
                    <Route path=":tabname" element={<DetailPage detailProps={GlobalJoinServersProps} />} />
                    <Route path=":id/:tabname" element={<DetailPage detailProps={GlobalJoinServersRowProps} />} />
                </Route>


                <Route path="/app/active_connections-v2" element={<ActiveConnections />}></Route>
                <Route path="/app/event_log" element={<EventLog />}></Route>
                <Route path="/app/swagger" element={<SwaggerPage />}></Route>

                <Route path="/app/accounts/logins" element={<Accounts accountType="logins" />}>
                        <Route path=":id/:tabname" element={<DetailPage detailProps={AccountsRowLoginProps} />} />
                        <Route path=":tabname" element={<DetailPage detailProps={AccountsLoginProps} />} />
                </Route>

                <Route path="/app/accounts/users" element={<Accounts accountType="users" />}>
                        <Route path=":id/:tabname" element={<DetailPage detailProps={AccountsRowUserProps} />} />
                        <Route path=":tabname" element={<DetailPage detailProps={AccountsUserProps} />} />
                </Route>

                <Route path="/app/accounts/customers" element={<Accounts accountType="customers" />}>
                        <Route path=":id/:tabname" element={<DetailPage detailProps={AccountsRowCustProps} />} />
                        <Route path=":tabname" element={<DetailPage detailProps={AccountsCustProps} />} />
                </Route>


                <Route path="/app/accounts/organisations" element={<Accounts accountType="organisations" />}>
                        <Route path=":id/:tabname" element={<DetailPage detailProps={AccountsRowOrgProps} />} />
                        <Route path=":tabname" element={<DetailPage detailProps={AccountsOrgProps} />} />
                </Route>

                <Route path="/app/groups" element={<Groups />}>
                        <Route path=":id/:tabname" element={<DetailPage detailProps={GroupDetailProps} />} />
                        <Route path=":tabname" element={<DetailPage detailProps={GroupAddProps} />} />
                        <Route path="bulkaction/:tabname" element={<DetailPage detailProps={GroupAppAssignmentProps} />} />
                </Route>

                <Route path="/app/my-devices" element={<MyDevices />}>
                    <Route path=":tabname" element={<DetailPage detailProps={DevicePageProps} />} />
                        <Route path=":id/:tabname" element={<DetailPage detailProps={DeviceRowProps} />} />
                        <Route path="bulkaction/:tabname" element={<DetailPage detailProps={DeviceAppAssignmentProps} />} />
                </Route>
                

                <Route path="/app/my-apps" element={<MyApps />}>
                    <Route path=":id/:tabname" element={<DetailPage  detailProps={MyAppDetailProps} />} />
                    <Route path=":tabname" element={<DetailPage detailProps={MyAppAddDetailProps} />} />
                    <Route path="edit/:tabname" element={<DetailPage detailProps={MyAppEditGlobalPushProps} />} />
                </Route>

                <Route path="/app/my-gateways" element={<MyGateways />}>
                    <Route path=":id/:tabname" element={<DetailPage  detailProps={GatewayRowProps} />} />
                    <Route path=":tabname" element={<DetailPage  detailProps={GatewayProps} />} />
                </Route>

                <Route path="/app/admin-devices" element={<AdminDevices />}></Route>
                
                <Route path="/app/admin-users" element={<AdminUsers />}></Route>

                <Route path="/app/active_connections" element={<ActiveConnections />}></Route>
                
                <Route path="/app/mydevice-register" element={<BatchRegistration />}></Route>

                <Route path="/app/product-license" element={
                    <SchemaModal key="product-license" SchemaUrl="/rest/license?get_schema=dassui,put" type="page"
                                 breadCrumbArr={[{label: strings.PRODUCT_LICENSE_TITLE, url: ''}]}
                                 EditObject={{}}
                                 OnClose={() => { appContextObj.reloadUser(); 
                                    navigate(previousPathRef.current); }} />}
                />

                <Route path="/app/eula" element={
                    <SchemaModal key="eula" SchemaUrl="/rest/users?get_schema=dassui,eula" type="page"
                                 breadCrumbArr={[{label: "Software license", url: ''}]}
                                 OnClose={() => navigate(previousPathRef.current)} />}
                />


                <Route path="/app/user-settings" element={
                    <SchemaModal key="user-settings"
                        breadCrumbArr={[{ label: strings.NAV_USER_SETTINGS, url: "" }]}
                        SchemaUrl={`/rest/${ appContextObj.accountType(appContextObj.user)}s?get_schema=dassui,put`}
                        EditObject={{ userid: appContextObj.user?.loginid || appContextObj.user?.userid, _editself: true}}
                        OnClose={() => { appContextObj.reloadUser(); 
                            navigate(previousPathRef.current);} }
                        type="page"
                        loadDataOnOpen={true} />}
                />

                <Route path="/app/server-alarms" element={<Alarms key="server" alarmtype="server"/>}>
                    <Route path=":id/:tabname" element={<DetailPage detailProps={ServerAlarmRowProps} />} />
                </Route>

                    <Route path="/app/gateway-alarms" element={<Alarms key="gateway" alarmtype="gateway" />}>
                    <Route path=":id/:tabname" element={<DetailPage detailProps={GatewayAlarmRowProps} />} />
                </Route>

                <Route path="/app/servers-status" element={<ServersStatus />} />

                <Route path="/app/gateway-tags" element={<GatewayTags />} >
                    <Route path=":tabname" element={<DetailPage key="add" detailProps={GatewayTagsProps} />} />
                    <Route path=":id/:tabname" element={<DetailPage key="edit" detailProps={GatewayTagsRowProps} />} />
                </Route>
                

                <Route path="/app/gateway-parameters" element={<GatewayParameters />} >
                    <Route path=":tabname" element={<DetailPage key="add" detailProps={GatewayParametersProps} />} />
                    <Route path=":id/:tabname" element={<DetailPage key="edit" detailProps={GatewayParametersRowProps} />} />
                </Route>

                <Route path="/app/gateway-audit-trail" element={<GatewayAutitTrail />} />

                <Route path="/app/tenants" element={<Tenants />}>
                    <Route path=":tabname" element={<DetailPage key="add" detailProps={TenantProps} />} />
                    <Route path=":id/:tabname" element={<DetailPage key="edit" detailProps={TenantRowProps} />} />
                </Route>

                <Route path="/app/omc-gateways" element={<OmcGateways />}>
                        <Route path=":id/:tabname" element={<DetailPage detailProps={OmcGatewayRowProps} />} />
                        <Route path=":tabname" element={<DetailPage detailProps={OmcGatewayProps} />} />
                </Route>

                <Route path="/app/gateway-manage-auth" element={<GatewayManageAuth />} >
                    <Route path=":tabname" element={<DetailPage detailProps={GatewayManageAuthProps} />} />
                </Route>

                <Route path="/app/omc-devices" element={<OmcDevices />}>
                        <Route path=":id/:tabname" element={<DetailPage detailProps={OmcDeviceRowProps} />} />
                        <Route path=":tabname" element={<DetailPage detailProps={OmcDeviceProps} />} />
                </Route>
                    <Route path="/app/my-campaigns" element={<MyCampaigns></MyCampaigns>}></Route>
                    <Route path="/app/my-campaigns/:campaignid" element={<MyCampaignsSessions />} />
                    <Route path="/app/my-campaigns/:campaignid/:sessionId" element={<SessionDevices />} />
                    <Route path="/app/my-campaigns/create" element={<CampaignStepper />}></Route>
                {NstAppFunc()}
            </Routes>
            </div>
        </div>
    );
}


export default App;

const NstAppFunc = () => {

    const navigate = useNavigate();
    const appContextObj = useContext(AppContext);

    // Use refs to store previous and current paths
    const previousPathRef = useRef('');
    const currentPathRef = useRef('');
 
    useEffect(() => {
         previousPathRef.current = currentPathRef.current;
         // Update the current path with the new location pathname
         currentPathRef.current = location.pathname;
    }, [location.pathname]);

    return (
        <Route path="/nst" element={<NstContainer />}>
           <Route path="mydashboard" element={<Dashboard />}></Route>
            <Route path="verify_email" element={<VerifyEmail />}></Route>
            <Route path="network_map" element={<NetworkMap />}></Route>
            
            <Route path="profiles/qos" element={<Profiles key="qos" profileType="qos" />}>
                <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="qos"/>} />
                <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="qos" />} />
            </Route>                                      
            <Route path="profiles/channel" element={<Profiles key="channel" profileType="channel" />}>
                <Route path=":id/:tabname" element={<DetailPage  detailProps={ProfileRowProps} profileType="channel" />} />
                <Route path=":tabname" element={<DetailPage  detailProps={ProfileProps} profileType="channel" />} />
            </Route>                                                          

            <Route path="active_connections-v2" element={<ActiveConnections />}></Route>
            <Route path="event_log" element={<EventLog />}></Route>
            <Route path="swagger" element={<SwaggerPage />}></Route>

            <Route path="accounts/users" element={<Accounts accountType="users" />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={AccountsRowUserProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={AccountsUserProps} />} />
            </Route>

            <Route path="accounts/customers" element={<Accounts accountType="customers" />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={AccountsRowCustProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={AccountsCustProps} />} />
            </Route>


            <Route path="accounts/organisations" element={<Accounts accountType="organisations" />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={AccountsRowOrgProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={AccountsOrgProps} />} />
            </Route>

            <Route path="groups" element={<Groups />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={GroupDetailProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={GroupAddProps} />} />
                <Route path="app-assignment" element={<DetailPage detailProps={GroupAppAssignmentProps} />} />
            </Route>

            <Route path="my-devices" element={<MyDevices />}>
                <Route path=":tabname" element={<DetailPage detailProps={DevicePageProps} />} />
                <Route path=":id/:tabname" element={<DetailPage detailProps={DeviceRowProps} />} />
            </Route>



            <Route path="my-apps" element={<MyApps />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={MyAppDetailProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={MyAppAddDetailProps} />} />
            </Route>

            <Route path="my-gateways" element={<MyGateways />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={GatewayRowProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={GatewayProps} />} />
            </Route>

            <Route path="admin-devices" element={<AdminDevices />}></Route>
            <Route path="admin-users" element={<AdminUsers />}></Route>
            <Route path="active_connections" element={<ActiveConnections />}></Route>
            <Route path="mydevice-register" element={<BatchRegistration />}></Route>

            <Route path="omc-settings" element={
                <SchemaModal key="settings" SchemaUrl="/rest/omc/generalparameters?get_schema=dassui,put" type="page"
                             EditObject={{ __readonly: Object.keys(appContextObj.user).includes("omc_mng_general_settings")}}
                             OnClose={() => { appContextObj.reloadUser(); navigate(previousPathRef.current);} }
                             breadCrumbArr={[ { label: strings.NAV_NST_GENERAL_SETTINGS, url: "" } ]} />} 
            />
            
            <Route path="omc-gateway-defaults" element={
                <SchemaModal key="default-settings" SchemaUrl="/rest/omc/rfconfigs?get_schema=dassui,put" type="page"
                            breadCrumbArr={[{label:strings.NAV_GATEWAYS_ITEM, url:`${getAppBase()}/omc-gateways`},{ label: strings.DEFAULT_GATEWAYS_CONFIG, url: "" }]} OnClose={() => navigate(previousPathRef.current)}/>} 
            />
            <Route path="server-versions" element={
                <SchemaModal key="versions" SchemaUrl="/rest/omc/info?get_schema=dassui,put" type="page"
                        breadCrumbArr={[ { label: strings.NAV_NST_SERVER_VERSIONS, url: "" } ]} OnClose={() => navigate(previousPathRef.current)} />} 
            />

            <Route path="server-alarms" element={<Alarms key="server" alarmtype="server" />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={ServerAlarmRowProps} />} />
            </Route>

            <Route path="gateway-alarms" element={<Alarms key="gateway" alarmtype="gateway" />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={GatewayAlarmRowProps} />} />
            </Route>

            {/* <Route path="servers-status" element={<ServersStatus />} /> */}


            <Route path="servers-status" element={<ServersStatus />} >
            <Route path=":id/:tabname" element={<DetailPage detailProps={ServerStatusProps} />} />
            </Route>        

            <Route path="gateway-tags" element={<GatewayTags />} >
                <Route path=":tabname" element={<DetailPage key="add" detailProps={GatewayTagsProps} />} />
                <Route path=":id/:tabname" element={<DetailPage key="edit" detailProps={GatewayTagsRowProps} />} />
            </Route>

            <Route path="gateway-tag-category" element={<GatewayTags />} >
                <Route path=":tabname" element={<DetailPage key="add" detailProps={GatewayTagCategorysProps} />} />
                <Route path="delete/:tabname" element={<DetailPage key="delete" detailProps={GatewayTagCategorysDeleteProps} />} />
            </Route>

            <Route path="gateway-parameters" element={<GatewayParameters />} >
                <Route path=":tabname" element={<DetailPage key="add" detailProps={GatewayParametersProps} />} />
                <Route path=":id/:tabname" element={<DetailPage key="edit" detailProps={GatewayParametersRowProps} />} />
            </Route>

            <Route path="blocked-gateways" element={<BlockedGateways />} >
                <Route path=":id/:tabname" element={<DetailPage key="edit" detailProps={BlockedGatewaysRowProps} />} />
            </Route>


            <Route path="gateway-audit-trail" element={<GatewayAutitTrail />} />

            <Route path="gateway-manage-auth" element={<GatewayManageAuth />} >
                <Route path=":tabname" element={<DetailPage detailProps={GatewayManageAuthProps} />} />
            </Route>
            

            <Route path="gateway-jobs" element={<GatewayJobs />}>
                    <Route path=":id/:tabname" element={<DetailPage  detailProps={GatewayJobsRowProps} />} />
                </Route>

            <Route path="tenants" element={<Tenants />}>
                <Route path=":tabname" element={<DetailPage key="add" detailProps={TenantProps} />} />
                <Route path=":id/:tabname" element={<DetailPage key="edit" detailProps={TenantRowProps} />} />
            </Route>

            <Route path="omc-gateways" element={<OmcGateways />}>

                <Route path=":id/:tabname" element={<DetailPage  detailProps={OmcGatewayRowProps} />} />
                <Route path=":id/:tabname/:map" element={<DetailPage  detailProps={OmcGatewayMapRowProps} />} />
                <Route path=":tabname" element={<DetailPage  detailProps={OmcGatewayProps} />} />
                <Route path="bulkaction/:tabname" element={<DetailPage  detailProps={GatewayBulkActionProps} />} />
            </Route>

            <Route path="omc-devices" element={<OmcDevices />}>
                <Route path=":id/:tabname" element={<DetailPage detailProps={OmcDeviceRowProps} />} />
                <Route path=":tabname" element={<DetailPage detailProps={OmcDeviceProps} />} />
            </Route>
            
            <Route path="user-settings" element={
                    <SchemaModal key="user-settings"
                        breadCrumbArr={[{ label: strings.NAV_USER_SETTINGS, url: "" }]}
                        SchemaUrl={`/rest/${ appContextObj.accountType(appContextObj.user)}s?get_schema=dassui,put`}
                        EditObject={{ userid: appContextObj.user?.loginid || appContextObj.user?.userid, _editself: true}}
                        OnClose={() => { appContextObj.reloadUser(); navigate(previousPathRef.current);} }
                        type="page" loadDataOnOpen={true} />}
            />

            <Route path="product-license" element={
                    <SchemaModal key="product-license" SchemaUrl="/rest/license?get_schema=dassui,put" type="page"
                        breadCrumbArr={[{label: strings.PRODUCT_LICENSE_TITLE, url: ''}]}
                        EditObject={{}}
                        OnClose={() => { appContextObj.reloadUser(); navigate(previousPathRef.current); }} />}
            />

            <Route path="eula" element={
                <SchemaModal key="eula" SchemaUrl="/rest/users?get_schema=dassui,eula" type="page"
                                breadCrumbArr={[{label: strings.NAV_SOFTWARE_INFO, url: ''}]}
                                OnClose={() => navigate(previousPathRef.current)} />}
            />

            <Route path="my-campaigns" element={<MyCampaigns></MyCampaigns>}></Route>


        </Route >
    )
}
