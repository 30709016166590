import { IMarkdownProps } from "./MarkdownComponent";
import { IUiSchemaElemArgs, registerComponentHandler, registerExtensionComponent } from "./SchemaController";
import React, { Suspense, lazy } from "react";


const Markdown = lazy(() => import("./MarkdownComponent"));


export default function LazyMarkdown(props: IMarkdownProps) {

    return <Suspense fallback={<div>Loading...</div>}>
                <Markdown markdown={props.markdown}/>
            </Suspense>;
}

const markdownExtension = (args: IUiSchemaElemArgs) => {

	const { value, fullkey } = args;

	return args.embedObject(<LazyMarkdown key={fullkey} markdown={value} />);
};

registerComponentHandler("markdown", markdownExtension);
registerExtensionComponent("Markdown", LazyMarkdown);
