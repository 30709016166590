
import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';

import { strings } from "./../../services/Localization";

import { faList } from '@fortawesome/pro-regular-svg-icons'

import { getAppBase } from '../../utils/consts';

import {  TAB_APP_ASSIGNMENT } from '../../datatypes/tabsconstants'

const detailPageNav = ( navigate, tab) => {
    
    navigate( `${getAppBase()}/groups/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/groups`}})
}

const getDataFromLocalStorage = (key) => {
    const item = JSON.parse(localStorage.getItem(key)) || null;
    if(item) {
        return item;
    }
    return [];
}

const GroupAppAssignmentProps:DetailPagePropsType =  {
    getBreadCrumbArr: ( breadCrumbArgs ) => {
        
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '-1';
        let breadCrumbArr:BreadCrumbType[] =  [
            {label: "Groups",  url: prevPageUrl},
            {label: strings.APP_ASSIGNMENT,  url:''}
        ]

        return breadCrumbArr;
    },    
    getActions : (actionArgs, navigate) => {
        
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';
        let selectedIds = actionArgs.hasOwnProperty('selectedIds') ? actionArgs['selectedIds'] : getDataFromLocalStorage('selectedIds');
        
        let actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.APP_ASSIGNMENT,
                icon: faList,
                key: TAB_APP_ASSIGNMENT,
                action: (id) => detailPageNav(navigate, TAB_APP_ASSIGNMENT),
                content: {
                    type: 'SchemaModal',
                    url:'/rest/applications?get_schema=dassui,put,bulk_application',
                    backUrl: prevPageUrl,
                },
                EditObject: {selectedIds: selectedIds, __readonly: false}

            },            
        ]

        return actions;
    }
}

export default GroupAppAssignmentProps;


