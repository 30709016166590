
import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';

import { strings } from "./../../services/Localization";

import { faList } from '@fortawesome/pro-regular-svg-icons'

import { getAppBase } from '../../utils/consts';

import {  TAB_APP_ASSIGNMENT, TAB_GROUP_ASSIGNMENT, TAB_BULK_CHANGE_OWNER, TAB_EDIT_BULK_DEVICE } from '../../datatypes/tabsconstants'


const BreadCrumbValues = {

    "change-bulk-device-owner": "Change Owner",
    "edit-bulk-device":     "Edit Device",
    "app-assignment":       "Applications Assignment",  
    "group-assignment":     "Groups Assignment",

}

const detailPageNav = ( navigate, tab) => {
    navigate( `${getAppBase()}/my-devices/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/my-devices`}})
}

const getDataFromLocalStorage = (key) => {
    const item = JSON.parse(localStorage.getItem(key)) || null;
    if(item) {
        return item;
    }
    return [];
}

const DeviceAppAssignmentProps:DetailPagePropsType =  {
    getBreadCrumbArr: ( breadCrumbArgs ) => {

        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '-1';
        let tabName = breadCrumbArgs.hasOwnProperty('tabname') ? BreadCrumbValues[breadCrumbArgs?.tabname] : ""

        let breadCrumbArr:BreadCrumbType[] =  [
            {label: "Device",  url: prevPageUrl},
            {label: "Bulk",    url: prevPageUrl},
            {label: `${tabName}`,  url:''}
        ]

        return breadCrumbArr;
    },    
    getActions : (actionArgs, navigate) => {
        
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';
        let selectedIds = actionArgs.hasOwnProperty('selectedIds') ? actionArgs['selectedIds'] : getDataFromLocalStorage("selectedIds");
        let RFRegion = actionArgs.hasOwnProperty('RFRegion') ? actionArgs['RFRegion'] : getDataFromLocalStorage("RFRegion");
        let actions:DetailPageActionType[] = [
            {
                type: "bulk_action_tab",
                text: "Edit Bulk Device",
                icon: faList,
                key: TAB_EDIT_BULK_DEVICE,
                action: (id) => detailPageNav(navigate, TAB_EDIT_BULK_DEVICE),
                content: {
                    type: 'SchemaModal',
                    url:`/rest/nodes?get_schema=dassui,put,bulk_edit_device`,
                    backUrl: prevPageUrl,
                },
                EditObject: {selectedIds: selectedIds, RFRegion: RFRegion ,  __readonly: false}

            },
            {
                type: "bulk_action_tab",
                text: strings.APP_ASSIGNMENT,
                icon: faList,
                key: TAB_APP_ASSIGNMENT,
                action: (id) => detailPageNav(navigate, TAB_APP_ASSIGNMENT),
                content: {
                    type: 'SchemaModal',
                    url:'/rest/applications?get_schema=dassui,put,bulk_device',
                    backUrl: prevPageUrl,
                },
                EditObject: {selectedIds: selectedIds, __readonly: false}

            },  
            {
                type: "bulk_action_tab",
                text: strings.GROUP_ASSIGNMENT,
                icon: faList,
                key: TAB_GROUP_ASSIGNMENT,
                action: (id) => detailPageNav(navigate, TAB_GROUP_ASSIGNMENT),
                content: {
                    type: 'SchemaModal',
                    url:'/rest/groups?get_schema=dassui,put,bulk_group',
                    backUrl: prevPageUrl,
                },
                EditObject: {selectedIds: selectedIds, __readonly: false}

            },         
            {
                type: "bulk_action_tab",
                text: strings.CHANGE_OWNER,
                icon: faList,
                key: TAB_BULK_CHANGE_OWNER,
                action: (id) => detailPageNav(navigate, TAB_BULK_CHANGE_OWNER),
                content: {
                    type: 'SchemaModal',
                    url:'/rest/nodes?get_schema=dassui,put,bulk_mig_device',
                    backUrl: prevPageUrl,
                },
                EditObject: {selectedIds: selectedIds, __readonly: false}

            },        
        ]

        return actions;
    }
}

export default DeviceAppAssignmentProps;


