import React  from "react";

import { PageButtonsComponent} from './PageButtons';

import { BreadcrumbComponent } from './';

import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';

import { ipadWidth  } from '../../utils/consts';

import { ColumnType } from './DataTable/DataTypes';

//import AppContext from '../../context/AppContext'



interface PageHeaderProp {
    breadCrumbArr?: BreadCrumbType[];
    pageButtons: PageButtonType[];
    countLabel?: string;
    columns?:ColumnType[];
    name?:string;
    scope?:string;
}

const PageHeader: React.FC<PageHeaderProp> = ( { breadCrumbArr, pageButtons , countLabel, columns, name, scope } ) => {
    var extraClass = "";
    if(document.body.clientWidth > ipadWidth) {
         extraClass = "justify-content-between";
    }

    return ( 
            <div className={`row mb-2 mr-0-only d-lg-flex single-page-header ${extraClass} border-2 besides-data-table`} id="page-header" >
                <div className="col-lg-8 col-md-auto col-xs-12" >
                    {breadCrumbArr && <BreadcrumbComponent breadCrumbArr={breadCrumbArr} countLabel={countLabel} name={name} />}
                </div>
                <div  className="col-lg-4 col-md-12 col-xs-12 d-flex flex-sm-row flex-column justify-content-end ">
                    <div style={{minWidth:'250px', marginRight:'10px'}}>
                        <div id="extra-search-box"></div>
                    </div>
                    <PageButtonsComponent pageButtons={ pageButtons } columns={columns}  scope={scope} />
                </div>
            </div> 
    )    
}

export {  PageHeader };