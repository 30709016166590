
import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';

import { faAdd, faEdit } from '@fortawesome/free-solid-svg-icons'

import { strings } from "./../../services/Localization";

import { getAppBase } from '../../utils/consts';

import { IRowType } from "./Tenants";

import { TAB_TENANT_CERT, TAB_ADD_TENANT, TAB_TENANT_INFO } from '../../datatypes/tabsconstants'

const detailPageNav = ( navigate, tab, id, row = null, prevPageUrl) => {

    if(id) {
        navigate(`${getAppBase()}/tenants/${id}/${tab}`, {state: {row: row, prevPageUrl}})
    }
}


const TenantProps: DetailPagePropsType =  {
    getBreadCrumbArr: (breadCrumbArgs) => {

        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/tenants`;

        const breadCrumb:BreadCrumbType[] = [
            { label: strings.TENANT_TITLE, url:prevPageUrl },
            { label:  strings.TENANT_ADD, url:''}
        ]
        return breadCrumb;
    },
    getActions : (actionArgs, navigate) => {
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/tenants`;
        
        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.TENANT_ADD,
                icon: faAdd,
                key: TAB_ADD_TENANT,
                action: (id) => detailPageNav(navigate, TAB_ADD_TENANT, '', {}, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/tenants?get_schema=dassui,post`,
                    backUrl: prevPageUrl
                },
            }
        ];
        return actions;
    }
}

export { TenantProps };


export const TenantRowProps: DetailPagePropsType =  {
    getBreadCrumbArr: (breadCrumbArgs) => {
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/tenants`;
        const { row } = breadCrumbArgs;
        const breadCrumb:BreadCrumbType[] = [
            {label: strings.TENANT_TITLE, url: prevPageUrl},
            {label: `${row.tenantid} (${row.name})`, url:''}
        ]
        return breadCrumb;
    },
    getActions: (actionArgs, navigate) => {

        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/tenants`;
        const { row } = actionArgs;

        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.TENANT_CERTIFICATE,
                icon: faEdit,
                key: TAB_TENANT_CERT,
                action: (row: IRowType) => detailPageNav(navigate, TAB_TENANT_CERT, row.tenantid, row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/tenants?get_schema=dassui,get,cert`,
                    backUrl: prevPageUrl
                },
                EditObject: { tenantid: row.tenantid ? row.tenantid : null, name: row?.name, __readonly: false }
            },
            {
                type: "tab",
                text: strings.TENANT_INFO,
                icon: faEdit,
                key: TAB_TENANT_INFO,
                action: (row: IRowType) => detailPageNav(navigate, TAB_TENANT_INFO, row.tenantid, row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/tenants?get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: row.tenantid ? { tenantid: row.tenantid } : null
            }
        ];

        return actions;
    }
}
