import React, {useLayoutEffect, useState, useContext, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { strings } from "../../services/Localization";
import { userData, Whoami } from "../../services/Login";
import { toast } from "../../utils/Toaster";
import "./signin.css";
import { useNavigate } from "react-router-dom";
import AppContext from '../../context/AppContext'

import orbiwiseLogo from '../../../resources/images/orbiwise-logo-dark.png';

// import { icon_size } from '../../utils/consts';
import { Button, Container, Form } from "react-bootstrap";
import { IUser } from "src/dassTypes";

declare const constants;
export class SignIn extends React.Component<any, any> {



    public render() {

        return (
            <Container fluid className="background-login">
                <div className="d-flex align-items-center justify-content-center" style={{height: "500px"}}>
                    <Container>

                        <div className="row  login-wrapper justify-content-center">
                            <div className="col-md-4 form-signin">
                                <img className="img-responsive img_padding_bottom" src={orbiwiseLogo} />
                                <LoginForm />
                            </div>
                        </div>

                    </Container>
                </div>
            </Container>
        );

    }

}



const LoginForm = () =>  {

    let navigate = useNavigate();
    const appContextObj = useContext(AppContext);

    const loginStateDefault = {
        password: "",
        otp: "",
        needOtp: false,
        signedIn: false,
        userid: "",
        auco: (constants.disable_autocomplete_for_credentials === true) ? "off" : "on",
        hideContent: false,
    };

    const [loginState, setLoginState] = useState(loginStateDefault);
    const passwordRef = useRef(null);

    useLayoutEffect(() => {

        const fetchData = async () => {
            const response: any = await Whoami();
            if(response.user && response.user.userid) {
                appContextObj.updateUser(response.user);
                if(!canRedirectToApp(response?.user)){
                    navigate("/nst/network_map", { replace: true });
                } else {
                    navigate("/app", { replace: true });
                }
            }
        }
        fetchData()

    },[])

    const validateForm = () => {
        return loginState.userid.length > 0 && loginState.password.length > 0;
    }


    const userIdHandleKeyPress = (e) => {
        if(e.key === "Enter") {
            passwordRef.current?.focus();
        }
    }
    const handleKeyPress = (e) => {
        if(e.key === "Enter") {
            handleSubmit(e);
        }
    }

    const needOtp = loginState.needOtp;

    const canRedirectToApp = (user: IUser) => {
        if (user.ui_settings?.landing_page === "nst") {
            return Object.keys(user).filter(k => k.startsWith("omc_")).length === 0;
        } 
        return true
    }

    // Press login button
    const  handleSubmit = async (event) => {
        event.preventDefault();
        
        if(validateForm()) {
            try {
                const response: any = await userData(loginState.userid, loginState.password, loginState.otp);
                if (response.user.reset_password) {
                    window.location.href = "new_password.html?userid=" + response.user.userid;
                } else {
                    appContextObj.updateUser(response.user);
                    if (!canRedirectToApp(response?.user)) {
                        window.location.href = "/nst/network_map";
                    } else {
                        window.location.href = constants.landing_page_after_signin || `/`;
                    }
                }

            } catch (e) {
                if (e.errorText?.match(/MFA/)) {

                    // First time we receive the Multifactor required, we set the needOtp to show the OTP input
                    if (loginState.needOtp === false) {
                        setLoginState(prevState => ({ ...prevState, needOtp: true, signedIn: false }));
                    } else {
                        // But seconds time (i.e. if the MFA is wrong), we reset otp and password and go back to password screen
                        setLoginState(prevState => ({ ...prevState, needOtp: false, otp: "", password: "", signedIn: false }));
                        toast.error(strings.LOGIN_FAILED, { autoClose: 3000 });
                    }

                } else if (e.errorText?.match(/too many failed password/i)) {

                    toast.error(strings.LOGIN_BLOCKED_DUE_TO_TOO_MANY_ATTEMPTS, { autoClose: 5000 });
                    
                } else {

                    setLoginState( prevState => { return {...prevState, signedIn: false} } );
                    if (e.status === 402) {
                        toast.error(e.errorText, { autoClose: 3000 });
                    } else {
                        toast.error(strings.LOGIN_FAILED, { autoClose: 3000 });
                    }
                }
            }
        }

    }


    return (
        <Form autoComplete={loginState.auco}>
            {!needOtp && <Form.Group className="mb-3">
                <Form.Control
                    autoComplete={loginState.auco} type="text" value={loginState.userid}
                    onChange={v => setLoginState(prev => ({...prev, userid: v.target.value}))} onKeyDown={userIdHandleKeyPress}>
                </Form.Control>
            </Form.Group>}

            {!needOtp && <Form.Group>
                <Form.Control
                    ref={passwordRef}
                    autoComplete={loginState.auco} type="password" value={loginState.password} onKeyDown={handleKeyPress}
                    onChange={v => setLoginState(prev => ({...prev, password: v.target.value}))}>
                </Form.Control>
            </Form.Group>}

            {needOtp && <Form.Group>
                <Form.Control
                        autoComplete="off" type="text" value={loginState.otp} onKeyDown={handleKeyPress}
                        onChange={v => setLoginState(prev => ({...prev, otp: v.target.value}))}
                        placeholder="MFA Code">
                </Form.Control>
            </Form.Group>}


            <div className="pt-2 mt-5 d-grid">
                <Button variant="dark" onClick={handleSubmit}>{strings.SIGN_IN}</Button>
            </div>

            <div className="row pb-2">
                {((constants.forgot_username_link !== undefined) &&
                    (constants.forgot_username_link === true)) && (
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <a href="/app/reset_password"
                            className="returnLogin a-link"
                        >
                            {strings.FORGOT_USERNAME}
                        </a>
                    </div>
                )}
                {((constants.forgot_password_link !== undefined) &&
                    (constants.forgot_password_link === true)) && (
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <a href="/app/reset_password"
                            className="returnLogin pull-right a-link"
                        >
                            {strings.FORGOT_PASSWORD}
                        </a>
                    </div>
                )}
            </div>

        </Form>
    );
}