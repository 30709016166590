import React, { useState, useEffect, useRef } from 'react';

import AppContext, { IDassUiAppContext, INavbarState } from './AppContext'

import { appBaseUrl, } from '../utils/consts';

import { toast } from "../utils/Toaster";

import { IUser } from "../dassTypes"

import {
        toast as toastlib,
        //ToastContainer
    } from "react-toastify";

import { GenericDassQuery, getDassInfo } from "../services/BasicDassQueries";

import {
    Whoami,
    SignInForward,
    SessionInfo,
    SelectAccount
    } from "../services/Login";

import { IConstants } from "../types";

import { PageStateType } from "../datatypes/datatypes";

import Cookies from "universal-cookie";

declare const constants: IConstants;


const AppState = ( props ) => {

    let localUser: IUser;

    try {
        localUser = JSON.parse(localStorage.getItem("user"));
    } catch (e) {}


    const cookies = new Cookies();
    const pageInit = {
        totalRecords:0,
        isLoading:false
    }
    const [user, setUser] = useState<IUser>(localUser || ({} as IUser));
    const debugObj = useRef({});

    const [recordCount, setRecordCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [pageState, setPageState] = useState<PageStateType>(pageInit);
    const [countLabel, setCountLabel] = useState<string>("");
    const [debugMessage, setDebugMessageRaw] = useState<string>("");

    const setDebugMessage = (key: string, message: string) => {

        if (constants.debugLevel > 0) {
            debugObj.current[key] = message;
            let str = "";
            let sep = "";
            for (const k of Object.keys(debugObj.current).sort()) {
                if (debugObj.current[k]) {
                    str += sep + k + ": " + debugObj.current[k];
                    sep = " | ";
                }
            }
            setDebugMessageRaw(str);
        }
    }
    


    const isForwardedSignIn = () => {
        return user?.forwarding_userid ? true : false;
    }

    const navStateInit = {
        forwardedSignIn: isForwardedSignIn(),
        language: cookies.get("language") ? cookies.get("language") : (constants.languages ? constants.languages[0] : ""),
        numMenusVisible: 2,
        originalUser: isForwardedSignIn() ? user.forwarding_userid : '',
        profiles: false,
        signedIn: false,
        user: null,
        userid_name: "",
        helpdeskMailto: "",
        menu: "",
        click: "",
        smallScreen:false,
    };


    const [navBarState, setNavBarState] = useState<INavbarState>(navStateInit);

    const signOut = () => {
        updateUser({} as IUser);
        localStorage.removeItem("currentGroup");
        window.location.href = appBaseUrl + '/signout';
    }

    const retrnToOldUser = async () => {
        const isNst = (String(location.pathname).indexOf('/nst'));
        const user = await SignInForward("", appBaseUrl);    // FIXME: was userId
        window.location.href = isNst === -1 ? '/' : '/nst/network_map';
        updateUser(user);
    }

    const UserSettings = async () => {
        setNavBarState(prevState => {return {...prevState,
            ShowUserSettingsModal: true,
        }});
    }

    const isSignedIn = (user: IUser) => {
        if(user && user.userid) {
            return true;
        }else {
            return false;
        }
    }


    const signInForward = async () => {
        try {
            await SignInForward(undefined, appBaseUrl);
            localStorage.removeItem("currentGroup");
            window.location.href = constants.landing_page_after_signin || "/";
        } catch (error) {
            console.log(error);
            //toast.error(strings.CAN_NOT_RETURN_TO_PARENT);
        }
    }

    const selectAccount = async (accountId: string) => {
        try {
            const user = await SelectAccount(accountId, appBaseUrl);
            localStorage.removeItem("currentGroup");
            updateUser(user);


//            window.location.href = constants.landing_page_after_signin || "/";
        } catch (error) {
            console.log(error);
            //toast.error(strings.CAN_NOT_RETURN_TO_PARENT);
        }
    }




    const changeLanguage = (event) => {

        cookies.set("language", event.target.id, { path: "/" });
        location.reload();
    }


    const onStorageUpdate = (e) => {

        let user: IUser;
        try { 
            user = JSON.parse(localStorage.getItem("user"));
        } catch (e) {}
        setUser(user || ({} as IUser));
    };

    const updateUser = (user: IUser) => {
        setUser(prevState => user);
        localStorage.setItem("user", JSON.stringify(user));

        setNavBarState(prevState => {
            return {
                ...prevState,
                forwardedSignIn: user?.forwarding_userid ?  true : false,
                originalUser: user?.forwarding_userid || "",
            }
        })

    }




    // FIXME: this logic is wrong. The clearTimeout statements are clearly
    // not doing what they are supposed to do.

    // checking the session by the getting session info
    const SessionCheck = (time = 1000) => setTimeout(GetSessionInfo, time);

    // get session info from the server, that will return state and expiration time of the session.
    // check the state and time, As per condition clear the session timeout or logout the user
    const GetSessionInfo = async () => {
        try {
            const response: any = await SessionInfo();            
            const { isActive, maxAge } = await response.json();
            if (!isActive || (maxAge < 10000)) {
                signOut();
            } else {
                clearTimeout(SessionCheck());
                SessionCheck(maxAge - 5000);
            }
        } catch (error) {
            console.log(error);
            SessionCheck();
        }
    }

    const GetCurrentSessionInfo = async () => {
        try {
            const response: any = await SessionInfo();
            const { isActive, maxAge } = await response.json();
            return {
                maxAge: maxAge,
                isActive: isActive
            }
        } catch (error) {
            return null
        }
    }

    let systemErrorToast: any;

    const CheckSystemErrorPeriod = 30000;

    let CheckSystemErrorTimer: NodeJS.Timeout;

    let lastToastMessage = "";

    const CheckSystemError = async () => {
        let systemErrorText = "";
        try {
            const dassinfo = (await getDassInfo()).data;
            systemErrorText = dassinfo.systemErrorTxt;
        } catch (e) {
            systemErrorText = "System unreachable";
        }

        if ((systemErrorToast && toastlib.isActive(systemErrorToast)) &&
            (lastToastMessage !== systemErrorText || !systemErrorText)) {
            // Toast is open but message is not the same or the alarm is over, we close the toast
            toastlib.dismiss(systemErrorToast);
            systemErrorToast = null;
        }

        if (systemErrorText) {
            if (systemErrorToast == null || !toastlib.isActive(systemErrorToast)) {
                lastToastMessage = systemErrorText;
                systemErrorToast = toast.error(systemErrorText, { position: "top-right" });
            }
        }

        if (systemErrorText != null) {
            // we only continue to check with the timer if the systemErrorText is non null.
            // if it is null it means the field is not pressent and we can stop checking.
            CheckSystemErrorTimer = setTimeout(() => CheckSystemError(), CheckSystemErrorPeriod);
        }
    }

    const forwardLoginCheck = async (userData) => {
        const user = navBarState.user;
        try {
            if (isSignedIn(user)) {
                const users = userData.loginuserid.split("/");
                if (users.length > 1) {
                    setNavBarState(prevState => { return {...prevState,  forwardedSignIn: true }});
                } else {
                    setNavBarState(prevState => {return {...prevState, forwardedSignIn: false }});
                }
            }
        } catch (error) {
            toast.error(error);
        }
    }



    const emailHelpdesk = async () => {
        try {

            const res = await GenericDassQuery("/rest/users/$?_get_helpdesk_email=true");
            const set = res.data;
            setNavBarState(prevState => {return {...prevState, helpdeskMailto: set.mailto }});
        } catch (e) {
            console.log("Can't get helpdesk email", e.message);
        }
    }

    const accountType = (user: IUser) => {
        if (user.loginid) { return "login"; }
        if (user.is_customer) { return "customer"; }
        if (user.is_organisation ) { return "organisation"; }
        if (user.accountid ) { return "application"; }
        return "user";
    }

    const formatName = (userid: string) => {
        const IsEmail = /@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (IsEmail.test(userid)) {
            return userid.substring(0, userid.lastIndexOf("@"));
        } else {
            return userid;
        }
    }

    const loadUserUnsafe = async () => {

        const response: any = await Whoami();

        if (response.user) {
            if (response.user._environment?.helpdesk_email_template_id) {
                emailHelpdesk();
            }
            SessionCheck();
            setNavBarState(prevState => {return {...prevState,
                menu: window.location.pathname,
                userid_name: formatName(response.user.userid),
                originalUser: formatName(response.loginuserid.split("/")[0]),
                profiles: response.user.can_list_device_profile  || response.user.can_list_service_profile ||
                        response.user.can_list_channel_profile || response.user.can_list_connectivity_profile ||
                        response.user.can_list_roaming_profile || response.user.can_list_qos_profile,
                signedIn: true,
                user: response.user,
            }});
            setUser(response.user);

            forwardLoginCheck(response);
        }
    }

    const reloadUser = async () => {
        try {
            loadUserUnsafe();
        } catch (e) {
        }
    }

    // const updateGState = ( key, value ) => {
    //     setGState(prevState => { 
    //         return {...prevState, [key]: value} 
    //         }
    //     );
    // }

    useEffect(() => {
        window.addEventListener("storage", onStorageUpdate);

        // TODO: why is this here? And why is it not just calling onStorageUpdate()?
        let localUser = localStorage.getItem("user");
        
        if(localUser) {
            setUser(prevState => JSON.parse(localUser) || {});
        }

        return () => {
          window.removeEventListener("storage", onStorageUpdate);
        };

    }, []);

    const values: IDassUiAppContext = {
        user,
        updateUser,
        recordCount,
        setRecordCount,
        isLoading,
        setIsLoading,
        pageState,
        setPageState,
        navBarState,
        signOut,
        UserSettings,
        signInForward,
        selectAccount,
        changeLanguage,
        isSignedIn,
        reloadUser,
        setNavBarState,
        accountType,
        CheckSystemErrorTimer,
        SessionCheck,
        GetCurrentSessionInfo,
        CheckSystemError,
        countLabel,
        setCountLabel,
        isForwardedSignIn,
        retrnToOldUser,
        debugMessage,
        setDebugMessage,
    }


    return  (
        <AppContext.Provider value={values}>
            {props.children}
        </AppContext.Provider>
    )
}


export default AppState;