// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    max-width:70px;
    margin-top: 0px;
}
.welcomePowerBy {
    position: absolute;
    bottom: 10px;
    right: 10px;  
    text-align:right;
}
.logo_orbiwise {
    max-width:90px;
    margin-top: 0px;
}
.welcomePageButtonLearn{
    border: 1px solid #ce0008;
    border-radius: 0px;
    color:#ce0008;
    background: transparent;
}
.welcomePageButtonLearn:hover{
    color: #10171e;
    background: #fff;
    border:1px solid #ce0008;
}
.welcomePageButtonSingUp{
    border: 1px solid #ce0008;
    border-radius: 0px;
    color: white;
    background-color: #ce0008;
}
.welcomePageButtonSingUp:hover{
    color: #10171e;
}

/* .welcomePageContent{
    vertical-align: middle;
    display: table-cell;
    height: 400px;
}
.welcomePageContent img{
    margin: 0px auto;
}
.welcomePageTitle{
    color: #ce0008;
    font-size:44px;
    font-family: "MarkBold", sans-serif;
}
.welcomePageMessage{
    color: #10171e;
    font-size: 20px;
    margin-bottom: 18px;
}
.welcomePageButtonLearn{
    border: 1px solid #ce0008;
    border-radius: 0px;
    color:#ce0008;
    background: transparent;
}
.welcomePageButtonSingUp{
    border: 1px solid #ce0008;
    border-radius: 0px;
    color: white;
    background-color: #ce0008;
} */`, "",{"version":3,"sources":["webpack://./app_react/src/pages/Welcome/welcome.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,wBAAwB;AAC5B;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;AAC7B;AACA;IACI,cAAc;AAClB;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA6BG","sourcesContent":[".logo {\n    max-width:70px;\n    margin-top: 0px;\n}\n.welcomePowerBy {\n    position: absolute;\n    bottom: 10px;\n    right: 10px;  \n    text-align:right;\n}\n.logo_orbiwise {\n    max-width:90px;\n    margin-top: 0px;\n}\n.welcomePageButtonLearn{\n    border: 1px solid #ce0008;\n    border-radius: 0px;\n    color:#ce0008;\n    background: transparent;\n}\n.welcomePageButtonLearn:hover{\n    color: #10171e;\n    background: #fff;\n    border:1px solid #ce0008;\n}\n.welcomePageButtonSingUp{\n    border: 1px solid #ce0008;\n    border-radius: 0px;\n    color: white;\n    background-color: #ce0008;\n}\n.welcomePageButtonSingUp:hover{\n    color: #10171e;\n}\n\n/* .welcomePageContent{\n    vertical-align: middle;\n    display: table-cell;\n    height: 400px;\n}\n.welcomePageContent img{\n    margin: 0px auto;\n}\n.welcomePageTitle{\n    color: #ce0008;\n    font-size:44px;\n    font-family: \"MarkBold\", sans-serif;\n}\n.welcomePageMessage{\n    color: #10171e;\n    font-size: 20px;\n    margin-bottom: 18px;\n}\n.welcomePageButtonLearn{\n    border: 1px solid #ce0008;\n    border-radius: 0px;\n    color:#ce0008;\n    background: transparent;\n}\n.welcomePageButtonSingUp{\n    border: 1px solid #ce0008;\n    border-radius: 0px;\n    color: white;\n    background-color: #ce0008;\n} */"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
