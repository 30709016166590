import { strings } from "./../../services/Localization";
import { BreadCrumbType } from '../../datatypes/datatypes';
import {  faList } from '@fortawesome/free-solid-svg-icons'
import AppContext from '../../context/AppContext';
import { useContext } from "react";
import {TAB_VIEW_JOBS} from "../../datatypes/tabsconstants";


const detailPageNav = (detailPageNavArgs) => {

    const { navigate, tab, id, row } = detailPageNavArgs;

    let prevPageUrl = detailPageNavArgs.hasOwnProperty('prevPageUrl') ? detailPageNavArgs['prevPageUrl'] : null;

    if (id) {
        navigate(`/nst/gateway-jobs/${id}/${tab}`, { state: { row: row, prevPageUrl: prevPageUrl } })
    } else {
        navigate(`/nst/gateway-jobs/${tab}`, { state: { tab: tab, prevPageUrl: prevPageUrl } })
    }

}

interface ActionType {
    type: string;
    text: string;
    icon: any;
    key: string;
    action: (id, navigate) => void;
    content?: {
        type: string;
        url: string;
        backUrl: string;
        props?: any;
        pageButtons?: any;
        columns?: any;
        ComponentName?:string;

    }
    EditObject?: any;
}

interface DevicePropsType {
    getBreadCrumbArr?: (breadCrumbArgs: any) => BreadCrumbType[];
    getActions: (actionArgs: any, navigate: any) => ActionType[];

}


const GatewayJobsRowProps: DevicePropsType = {
    getBreadCrumbArr: (breadCrumbArgs) => {
        const { id, row, prevPageUrl } = breadCrumbArgs;
        return [
            { label: 'Jobs', url: prevPageUrl },
            { label: `${id}`, url: '', comment: row.comment }
        ]
    },
    getActions: (actionArgs, navigate) => {

        const { row } = actionArgs;
        const id = row.hasOwnProperty('batchId') ? row.batchId : (row.hasOwnProperty('id') ? row.id : null);
        const prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs.prevPageUrl : null;
        const AppContextObj = useContext(AppContext);
        const user = AppContextObj.user;
     

        const actions: ActionType[] = [

            {
                type: "tab",
                text: strings.MY_DEVICES_ACTION_DATA,
                icon: faList,
                key: TAB_VIEW_JOBS,
                action: (row) => detailPageNav({ navigate: navigate, tab: TAB_VIEW_JOBS, id: id, row: row, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'Component',
                    url: '',
                    backUrl: prevPageUrl,
                    props: {id:id, user:user},  
                    ComponentName: 'GatewayJobsViewTable'
                }
            },

        ]

        return actions;
    }
}


export { GatewayJobsRowProps }