export const icon_size = 26;
export const ipadWidth = 768;
export const desktopWidth = 1366;
export const appBaseUrl = "/app";
export const nstBaseUrl = "/nst";
export const APP = "app";
export const NST = "nst";
export const localSessionIdleTimeout = 1000 * 60 * 30;  // default to 30 min
export const whoAmICheckTime = 1000 * 60;               // default to 1 min
export const maxActionIcons = 3;
export const errLog = 0;
export const defaultPageSize = 5;
export const defaultInputLength = 50;
export const autoRefreshInterval = 30000;;

const mobileView = (  ) => {
    return (window.outerWidth < ipadWidth) ? true : false;;
}

const getIsNst = ( location ) => {
    const r = (String(location.pathname).indexOf('/nst'));
    return r;
}

export { getIsNst }

const getAppName = ( location ) => {
    const r = (String(location.pathname).indexOf('/nst'));
    if(r === -1) {
        return 'app';
    }else {
        return 'nst';
    }
}

export { getAppName }

export { mobileView }

export const MapMarkerColors = {
    gray: "#878786",  // Gray
    green: "#2cbb36",  // Green
    yellow: "#ffcc40",  // Yellow
    orange: "#ffa307",  // Orange
    red: "#f22e2e",  // red
    blue: "#53567e",  // red
};




// For OMC gateways severity is 0-3
export const MapMarkerColorCodesOmcGateways = [
    MapMarkerColors.green,
    MapMarkerColors.yellow,
    MapMarkerColors.orange,
    MapMarkerColors.red,
    MapMarkerColors.blue
];

// App side gateways has the following descrete states
export const MapMarkerColorCodesGateways = {
    MAJOR_ISSUE: MapMarkerColors.red,
    NEVER_SEEN: MapMarkerColors.gray,
    OK: MapMarkerColors.green,
    WARNING: MapMarkerColors.yellow,
    
};

// Devices use the traffic status:
export const MapMarkerColorCodesDevices = {
    NA: MapMarkerColors.gray,
    NOMINAL: MapMarkerColors.green,
    RATE_TOO_LOW: MapMarkerColors.yellow,
    RATE_TOO_HIGH: MapMarkerColors.yellow,
    NO_TRAFFIC: MapMarkerColors.red,
    SAVED_LOCAITON: MapMarkerColors.blue,
};


const MapDeviceStatus = [{statusKey: 'MAJOR_ISSUE', statusValue:"Major Issue"}, {statusKey:'NEVER_SEEN', statusValue: "Never Seen"},  {statusKey:'OK', statusValue:"Active"}]

export { MapDeviceStatus }

const windowWidth = (  ) => {
    return document.body.clientWidth;
}

export { windowWidth }

const getAppBase = () => {
    const isNst = (String(location.pathname).indexOf('/nst'));
    
    if(isNst > -1 ) {
        return '/nst';
    }else {
        return '/app';
    }
}

export { getAppBase }

const windowWidthRef = ( myRef ) => {
    if(myRef === undefined) {
        return screen.availWidth;
    }else {
        const { width } = myRef.current.getBoundingClientRect()
        return width;
    }
}

export { windowWidthRef }


const log = ( msg ) => {
   
    if(window.location.search.indexOf('log=1') )  {
        console.log(msg);
    }

}

export { log }