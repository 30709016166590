import { IUiSchemaElemArgs, registerComponentHandler } from "./SchemaController";
import React, { lazy, Suspense } from "react";


import "./ExtAmChart.css"

const AmStockChart = lazy(() => import("./AmChart"));


const amChartExtension = (args: IUiSchemaElemArgs) => {

	const { value, fullkey } = args;
	let   { x, y, title, dataset } = value;

	if (dataset == null && Array.isArray(x) && Array.isArray(y)) {
		dataset = [];
		for (let i = 0; i < (value.x || []).length; i++) {
			dataset.push({ x: new Date(value.x[i]), y: value.y[i] });
		}
		x = "x";
		y = "y";
	}

	return args.embedObject(
		<Suspense key={fullkey} fallback={<div>Loading...</div>}>
			<AmStockChart keyId={fullkey} dataSet={dataset} x={x} y={y} title={title}/>
		</Suspense>,
		{ isContainer: true }
	);
};

registerComponentHandler("amchart", amChartExtension);
