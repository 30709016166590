
import { BreadCrumbType } from '../../datatypes/datatypes';
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import { getAppBase } from '../../utils/consts';

import { TAB_ADD_FUOTA_CAMPAIGN } from '../../datatypes/tabsconstants'



const addPageNav = (actionArgs) => {

    const { navigate, tab } = actionArgs;

    navigate(`${getAppBase()}/my-campaigns/create/${tab}`)
}

interface ActionType {
    type: string;
    text: string;
    icon: any;
    key: string;
    action: (deveui, navigate) => void;
    content?: {
        type:string;
        url:string;
        backUrl:string;
    }
    EditObject?: any;
}

interface AlarmPropsType {
    getBreadCrumbArr ?: (breadCrumbArgs:any) => BreadCrumbType[];
    getActions: (actionArgs: any, navigate:any) => ActionType[];
}



export const MyCampaignProps: AlarmPropsType =  {
    getBreadCrumbArr: (breadCrumbArgs) => {
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/my-campaigns`;
        const { row } = breadCrumbArgs;
        return [
            {label: "FUOTA CAMPAIGN", url: prevPageUrl},
            {label: ``, url:'', comment: row.name}
        ]
    },
    getActions: (actionArgs, navigate) => {
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/my-campaigns`;
        let selectedIds = actionArgs.hasOwnProperty('selectedDevices') ? actionArgs['selectedDevices'].map(v => v.deveui) : [];
        let selectedFirmWare = actionArgs.hasOwnProperty('selectedFirmware') ? actionArgs['selectedFirmware'].find(v => v.firmwareName) : [];
        // const { row } = actionArgs;
        
        const tabs: ActionType[] = [
            {
                type: "tab",
                text: "Edit Alarm",
                icon: faEdit,
                key: TAB_ADD_FUOTA_CAMPAIGN,
                action: (row) => addPageNav({navigate,TAB_ADD_FUOTA_CAMPAIGN}),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/fuota/campaigns?get_schema=dassui,post,fuota`,
                    backUrl: `${prevPageUrl}`
                },
                // EditObject: row.id ? { id: row.id } : null
                EditObject: {selectedIds: selectedIds, __readonly: false, selectedFirmWare: selectedFirmWare.firmwareName}
            }
        ];

        return tabs;
    }
}




