import React, { useState, useLayoutEffect, useEffect, useContext } from "react";
import { strings } from "./../../services/Localization";
import { toast } from "../../utils/Toaster";

import { checkLogin } from "./../../services/Login";

import { useParams, useNavigate, NavigateFunction  } from 'react-router-dom';

import AppContext from '../../context/AppContext'

import { dialog, dialogDescription } from '../../components/Common';
import { appBaseUrl, getAppBase } from '../../utils/consts';
import { getVisibleActions } from "./../../utils/filters";

import {TAB_ADD_CONNECTIVITY_PROFILE
        ,TAB_ADD_DEVICE_PROFILE
        ,TAB_ADD_SERVICE_PROFILE
        ,TAB_ADD_ROAMING_PROFILE
        ,TAB_ADD_QOS_PROFILE
        ,TAB_ADD_CHANNEL_PROFILE
        //,TAB_ADD_LINKED_PROFILE
        ,TAB_PROFILE_EDIT_ACTION
        ,TAB_CREATE_LINKED_PROFILE_ACTION
        ,TAB_IMPORT_DEVICE_PROFILE
        //,TAB_VIEW_PROFILE
    } from '../../datatypes/tabsconstants';

import { faRefresh, faAdd } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt, faLinkSlash } from '@fortawesome/pro-regular-svg-icons';

import { ID_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";
import { IUser } from "src/dassTypes";
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { ActionType, ColumnType, DataTableOption, BulkActionType } from "src/components/Common/DataTable/DataTypes";
import { GenericDassQuery } from "../../../src/services/BasicDassQueries";

import PageContent from "../PageContent";
import { IConstants } from "src/types";

declare const constants: IConstants;


export interface IRowType {
    profile_uuid: string;
    profile_name: string;
    profile_image_url: string;
    shared_with_all_users: boolean;
    is_shared: boolean;
    can_be_inspected: boolean;
    link_to_profile_uuid: string;
    id?:string;
}

interface breadCrumbArrType {
    key: string;
    pathArray: BreadCrumbType[];
};

interface IDeviceProfileState {
    loggedUser: IUser | null;
    ProfileUUID: string | undefined;
    breadCrumbArr: BreadCrumbType[];
    countLabel:string;
    refresh:boolean;
    isRowDeleted?:boolean;
}

type ProfileType =  "device" | "connectivity" | "qos" | "roaming" | "service" | "channel";

 interface ProfilesPropsType {
    profileType: ProfileType;
 }

const Profiles: React.FC<ProfilesPropsType> = ( props ) => {


    //let can_list_profile: string;
    //let can_inspect_profile: string;
    let can_create_profile: string;
    let can_link_profile: string;
    let profileType = props.profileType;

    const AppContextObj = useContext(AppContext);

    //can_list_profile    = "can_list_" + profileType + "_profile";
    //can_inspect_profile = "can_inspect_" + profileType + "_profile";
    can_create_profile  = "can_create_" + profileType + "_profile";
    can_link_profile    = "can_link_" + profileType + "_profile";



    let  breadCrumpOptions: breadCrumbArrType[] =  [
        {
            key: '/profiles/device',
            pathArray: [{label:strings.NAV_PROFILES_ITEM,url:''}, {label: strings.NAV_DEVICE_PROFILES, url: ''}],
        },
        {
            key: '/profiles/service',
            pathArray: [{label:strings.NAV_PROFILES_ITEM,url:''}, {label: strings.NAV_SERVICE_PROFILES, url: ''}],
        },
        {
            key: '/profiles/connectivity',
            pathArray: [{label:strings.NAV_PROFILES_ITEM,url:''}, {label: strings.NAV_CONNECTIVITY_PROFILES, url: ''}],
        },
        {
            key: '/profiles/roaming',
            pathArray: [{label:strings.NAV_PROFILES_ITEM,url:''}, {label: strings.NAV_ROAMING_PROFILES, url: ''}],
        },
        {
            key: '/profiles/qos',
            pathArray: [{label:strings.NAV_PROFILES_ITEM,url:''}, {label: strings.NAV_QOS_PROFILES, url: ''}],
        },
        {
            key: '/profiles/channel',
            pathArray: [{label:strings.NAV_PROFILES_ITEM,url:''}, {label: strings.NAV_CHANNEL_PROFILES, url: ''}],
        }
    ]

    const path = window.location.pathname;

    function checkPath(item: breadCrumbArrType) {
        return path.indexOf(item.key) > -1;
    }

    const { id, tabname } = useParams();
    const navigate = useNavigate();
    const singleBreadCrump: breadCrumbArrType = breadCrumpOptions.find(checkPath);


    const stateInit = {
        loggedUser: AppContextObj.user,
        ProfileUUID: undefined,
        breadCrumbArr: singleBreadCrump.pathArray,
        countLabel: 'Profiles',
        refresh:false,
        isRowDeleted:false
    };
    const [state, setDeviceState] = useState<IDeviceProfileState>(stateInit);
    const isInitialMount = React.useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        }else {
            setDeviceState(prevState => {return {...prevState, breadCrumbArr:singleBreadCrump.pathArray }})
        }

    },[singleBreadCrump.key])


    useLayoutEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {
            //LoggedIn();
            if(checkLogin(AppContextObj.user)) {
                setDeviceState(prevState => { return {...prevState, loggedUser: AppContextObj.user}} )
            }else {
                window.location.href = appBaseUrl + "/signin";
            }
        }
    },[])

        const showDeleteProfileConfirmDialog = async ( row: IRowType ) => {
            const confirmDialogeSettings = {
                title:  strings.DELETE_PROFILE_MODAL_TITLE,
                description: strings.formatString(strings.DELETE_PROFILE_MESSAGE_MODAL_BODY, {ProfileName: row.profile_name}),
                actionLabel: strings.ACTION_DELETE,
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    await GenericDassQuery(`/rest/${profileType}-profiles/${row.profile_uuid}`, { method: "DELETE" });
                    refreshTable(true);
                    toast.success("Profile successfully deleted");
                } catch (e) {
                    toast.error(e.message);
                }
            }
        }



        const showUnlinkLinkConfirmDialog = async (row: IRowType) => {
            const confirmDialogeSettings = {
                title:  strings.UNLINK_LINKED_PROFILE_MODAL_TITLE,
                description: strings.formatString(strings.UNLINK_PROFILE_MESSAGE_MODAL_BODY, {ProfileName: row.profile_name}),
                actionLabel: strings.UNLINK_PROFILE_MODAL_CONFIRM_BUTTON,
            };

            if (await dialog(confirmDialogeSettings) === true) {

                try {
                    await GenericDassQuery(`/rest/${profileType}-profiles/${row.profile_uuid}`, {
                        method: "PUT",
                        data: { copy_from_profile_uuid: row?.link_to_profile_uuid }
                    });
                    refreshTable();
                    toast.success("Profile successfully unlinked");

                } catch (e) {
                    toast.error(e.message);
                }
            }
        }

        // Bulk Deleted Profile Function

        const batchProfileDelete = async ( selectedIds: IRowType[] ) => {

            const profileIds = selectedIds.map((row) => {
                return row['profile_uuid'];
            })
            const profilesName = selectedIds.map((row) => {
                return row['profile_name'];
            })
            if(profileIds && profileIds.length > 0) {
                const confirmDialogeSettings = {
                    title:  `Do you want to delete the ${selectedIds.length} selected ${profileType} profile(s)`,
                    description: await dialogDescription(profilesName),
                    actionLabel: 'Delete',
                };
                if (await dialog(confirmDialogeSettings) === true) {
                    try {
                        Promise.all(profileIds.map((profileId) => {
                            return GenericDassQuery(`/rest/${profileType}-profiles/` + profileId, { method: "DELETE" });
                        })).then((values) => {
                            refreshTable(true);
                            toast.success(`${profileIds.length} ${profileType} Profile deleted successfully`);
                        }).catch((e) => {
                            toast.error(e.message);
                            refreshTable();
                        });
                    } catch (e) {
                        toast.error(e.message);
                    }       
                }
            }
        }




        const detailPageNav = ( navigate: NavigateFunction, tab: string, id: string, row: IRowType, profileType: ProfileType) => {

            navigate(`${getAppBase()}/profiles/${profileType}/${id}/${tab}`, {state: {row, prevPageUrl:`${getAppBase()}/profiles/${profileType}`}})
        }

        const addPageNav = ( navigate: NavigateFunction, tab: string, profileType: ProfileType) => {

            navigate(`${getAppBase()}/profiles/${profileType}/${tab}`, {state: {row:{}, prevPageUrl:`${getAppBase()}/profiles/${profileType}`}} )
        }

        const getActions = () => {

            const actions: ActionType[] =[
                {
                    type: "action",
                    text: strings.UNLINK_LINKED_PROFILE_ACTION,
                    icon: faLinkSlash,
                    action: (row: IRowType) => {
                        showUnlinkLinkConfirmDialog(row);
                    },
                    visible: (row: IRowType) => (row?.link_to_profile_uuid && row?.can_be_inspected ? true : false) && !state.loggedUser._readonly ,
                },

                {
                    type: "action",
                    text: strings.PROFILE_DELETE_ACTION,
                    icon: faTrashAlt,
                    action: (row: IRowType) => { showDeleteProfileConfirmDialog(row);},
                    visible: () => { return state.loggedUser && state.loggedUser[can_create_profile] && !state.loggedUser._readonly },
                },

            ];
            console.log(state.loggedUser && state.loggedUser[can_create_profile] && !state.loggedUser._readonly)
            const bulkActions: BulkActionType<IRowType>[] = [
                {
                    type: "action",
                    text: "Delete Profiles",
                    icon: faTrashAlt,
                    action: (selectedIds) => batchProfileDelete(selectedIds),
                    visible: () => { return state.loggedUser && state.loggedUser[can_create_profile] && !state.loggedUser._readonly},
                }

            ]

            return {
                actions: actions,
                bulkActions:bulkActions
            };
        }

        const getDeviceTableOptions = () => {

            const { actions, bulkActions } = getActions();

            const columns: ColumnType<IRowType>[] = [{
                key: "profile_name",
                type: "text",
                title: strings.TABLE_PROFILE_NAME,
                filterable: true,
                filterField: 'search_name',
                filterType: 'text',
                inputValidation: '',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                detailLink: true,
                idField: 'profile_uuid',
                detailPageNav: function (row: IRowType) {
                    detailPageNav(navigate, TAB_PROFILE_EDIT_ACTION, row.profile_uuid, row, profileType)
                },
                cellWidth: 28,
                sortable:true,
                sortKey:'sort_by_name'
            }];

            if (profileType === "device") {
                columns.push({
                    key: "profile_image_url",
                    title: strings.TABLE_IMAGE,
                    type: "icon_with_tooltip",
                    filterable: false,
                    newCellWidth: "100px",
                    render: (row) => <img height="50px" src={row.profile_image_url?.startsWith("storageid:") 
                                                            ? "/storagecache/" + row.profile_image_url.substring(10)
                                                            : row.profile_image_url } />,
                    cellWidth: 6,
                    dataAlign: 'center'
                });
            }

            columns.push({
                key: "description",
                title: strings.TABLE_COMMENT,
                type: "icon_with_tooltip",
                filterable: true,
                inputValidation: '',
                filterField: 'search_description',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                sortable:true,
                cellWidth: 48,
                sortKey:'sort_by_description'
            });
            
            columns.push({
                key: "profile_uuid",
                title: strings.TABLE_UUID,
                type: "text",
                filterField: 'search_uuid',
                inputValidation: ID_INPUT_VALIDATION,
                filterable: true,
                newCellWidth: "370px",
                filterType: 'text',
                copyLink: true,
                cellWidth: 35,
            });

            if (profileType === "channel" || profileType === "service" || profileType === "device" || profileType === "qos") {
                columns.push({
                    key: "region",
                    title: strings.TABLE_REGION,
                    type: "text",
                    newCellWidth: "75px",
                    filterable: false,
                    cellWidth: 15,

                    detailLink: true,
                    copyLink: true,
                });
            }

            columns.push({
                key: "link_to_profile_uuid",
                title: strings.TABLE_LINK,
                type: "text",
                filterField: 'search_linked',
                filterable: true,
                filterType: 'select',
                newCellWidth: "80px",
				filterWidth: "65px",
                dataAlign:'center',
                filterParams: {
                    options: [
                        { label: strings.YES, value: "true" },
                        { label: strings.NO, value: "false" }
                    ]
                },
            });

            columns.push({
                key: "profile_uuid",
                title: strings.TABLE_SHARED,
                type: "text",
                newCellWidth: "90px",
                filterField: 'search_shared',
                filterable: true,
                filterType: 'select',
                dataAlign:'center',
                filterParams: {
                    options:[
                        {label:strings.YES, value: "true"}
                        ,{label:strings.NO, value: "false"}
                    ]
                },
                render: (row: IRowType) => row.shared_with_all_users || row.is_shared ? strings.YES : strings.NO,
                cellWidth: 10
            });

            const dataTableOption: DataTableOption<IRowType> = {
                allowBulkActions: true,
                columns,
                url: `/uiapi/rest/${profileType}-profiles`,
                query_param: { all:true, limit: DEFAULT_RECORD_LIMIT, get_pages:true, stream: 'progress', show_region: true },
                serial_number: false,
                id_field: 'profile_uuid',
                oboe_path: 'pages.*',
                available_key: 'profile_uuid',
                defaultSortField: 'sort_by_name',
                defaultSortOrder: 'asc',
                actions: actions,
                bulkActions: getVisibleActions(bulkActions)
            }

            return dataTableOption;
        }
        const refreshTable = (isRowDeleted = false) => {

            setDeviceState(prevState => {
                return {...prevState, refresh:!prevState.refresh, isRowDeleted: isRowDeleted}
            })

        }

        const getPageButtonType =  ( profileType: ProfileType ) => {

            const pageButtonTypesArr: { [key: string]: PageButtonType } = {
                device: {
                    title: strings.DEVICE_PROFILE,
                    action: () => addPageNav(navigate, TAB_ADD_DEVICE_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return state.loggedUser && state.loggedUser[can_create_profile] && !state.loggedUser._readonly },
                    icon: faAdd
                },
                service:{
                    title: strings.SERVICE_PROFILE,
                    action: () => addPageNav(navigate, TAB_ADD_SERVICE_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return state.loggedUser && state.loggedUser[can_create_profile]  && !state.loggedUser._readonly},
                    icon: faAdd
                },
                connectivity:{
                    title: strings.CONNECTIVITY_PROFILE,
                    action: () => addPageNav(navigate, TAB_ADD_CONNECTIVITY_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return state.loggedUser && state.loggedUser[can_create_profile] && !state.loggedUser._readonly},
                    icon: faAdd
                },
                roaming:{
                    title: strings.ROAMING_PROFILE,
                    action: () => addPageNav(navigate, TAB_ADD_ROAMING_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return state.loggedUser && state.loggedUser[can_create_profile] && !state.loggedUser._readonly},
                    icon: faAdd
                },
                qos:{
                    title: strings.QOS_PROFILE,
                    action: () => addPageNav(navigate, TAB_ADD_QOS_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return state.loggedUser && state.loggedUser[can_create_profile] && !state.loggedUser._readonly},
                    icon: faAdd
                },
                channel:{
                    title: strings.CHANNEL_PROFILE,
                    action: () => addPageNav(navigate, TAB_ADD_CHANNEL_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return state.loggedUser && state.loggedUser[can_create_profile] && !state.loggedUser._readonly },
                    icon: faAdd
                },
            }

            return pageButtonTypesArr[profileType];
        }

        const getPageButtons = () => {

            let currentPageButton = getPageButtonType(profileType);


            const pageButtons: PageButtonType[] = [];
            pageButtons.push(currentPageButton);
            pageButtons.push({
                title: strings.NEW_LINKED_PROFILE,
                //action: () => { AddNewLinkedProfile() },
                action: () => addPageNav(navigate, TAB_CREATE_LINKED_PROFILE_ACTION, profileType),
                type: 'button_with_icon',
                visible: () => { return state.loggedUser && state.loggedUser[can_link_profile] && !state.loggedUser._readonly},
                icon: faAdd
            })
            if (profileType === "device") {
                pageButtons.push({
                    title: strings.IMPORT_DEVICE_PROFILE,
                    action: () => addPageNav(navigate, TAB_IMPORT_DEVICE_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return state.loggedUser && state.loggedUser[can_create_profile] && !state.loggedUser._readonly},
                    icon: faAdd
                });
            }
            pageButtons.push({
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            });

            return pageButtons;
        }


         if (constants.enable_profiles !== true) {
             return null;
         }else {

            return (<PageContent
                    name={profileType} 
                    id={id} 
                    tabname={tabname} 
                    actions={getActions()} 
                    breadCrumbArr={state.breadCrumbArr} 
                    pageButtons={getPageButtons()} 
                    countLabel={state.countLabel} 
                    dataTableOption={getDeviceTableOptions()} 
                    isRowDeleted={state.isRowDeleted}
                    refresh={state.refresh}>
                </PageContent>)

        }
}


export default Profiles;