import React, { forwardRef, useCallback, useContext, useImperativeHandle } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { strings } from "../../services/Localization";
import PageContent from "../PageContent";
import { ActionType, BulkActionType, ColumnType, OptionType } from '../../components/Common/DataTable/DataTypes';
import { faForward } from "@fortawesome/free-solid-svg-icons";
import { DEFAULT_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT, DEVEUI_INPUT_VALIDATION } from "../../components/Common/DataTable/DataTableConsts";
import { PageButtonType } from '../../datatypes/datatypes';
import _ from 'lodash';
import { getAppBase } from "../../utils/consts";
import { DataTableContext } from "../../components/Common/DataTable/DataTableState";
import { toast } from "./../../utils/Toaster";

    const SelectDevices: React.FC<any> = forwardRef((props,ref) => {
    const navigate = useNavigate();
    let { campaignid, tabname } = useParams();
    const stateInit = {
        loggedUser: null,
        editCampaignId: "",
        addCampaign: false,
        pageTitle: strings.MY_APPLICATIONS_TITLE,     // should be translated
        showAlertModal: false,
        breadCrumbArr: [{ label: 'Select Devices', url: '' }],
        camapignid: '',
        selectedAction: '',
        showLoadingDialog: false,
        showConfirmDialog: false,
        confirmDialogeSettings: {
            title: '',
            description: '',
            actionLabel: '',
            confirmAction: () => { }
        },
        refresh: false,
        exportInit: 0,
    };

    const { state } = useLocation();

    useImperativeHandle(ref, () => ({
        goToDetails(){

            if( DataTableContextObj.searchState.selectedRowIds && DataTableContextObj.searchState.selectedRowIds.length > 0) {
                
                navigate(`${getAppBase()}/my-campaigns/create`, { state: { selectedDevices : DataTableContextObj.searchState.selectedRowIds,selectedFirmware: state['selectedFirmware'], row: {}, prevPageUrl: `${getAppBase()}/my-campaigns` } })
                
                return true;

            } else {
                toast.warning("Select Devices !!");
                return false;
            }    
            return false;        
        }    
      }));

    const getActions = useCallback(() => {
        
        let actions: ActionType[] = [
        ];

        let bulkActions: BulkActionType[] = [];

        return {
            actions: actions,
            bulkActions: bulkActions
        }

    }, []);


    let DataTableContextObj = useContext(DataTableContext);

    const initDataTable = () => {

        let actions: any = [];
        let pageButtons: PageButtonType[] = [];

        actions = getActions();


        let columns: ColumnType[] = [
            {
                key: "deveui",
                type: "text",
                title: strings.DEVEUI,
                maxInputLength: 40,
                filterable: true,
                filterField: "search_deveui",
                filterType: "text",
                inputValidation: DEVEUI_INPUT_VALIDATION,
                filterParams: {
                    mapper: x => (x || "").replace(new RegExp("-|:", 'g'), "")
                },
                sortable: true,
                sortKey: "sort_by_deveui",
                cellWidth: 20,
                detailLink: true,
                copyLink: true,
                customClass: 'font-monospace nowarp'
            },
            {
                key: "image_url",
                title: strings.TABLE_IMAGE,
                type: "icon_with_tooltip",
                filterable: false,
                render: (row) => <img height="50px" src={row.image_url} />,
                cellWidth: 20,
            },
            {
                key: "comment",
                title: strings.COMMENT,
                type: "text",
                filterable: true,
                filterField: "search_comment",
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: true,
                sortKey: "sort_by_comment",
                filterParams: {},
                cellWidth: 50,
                cellWidthType: '%',
            }
        ];

        let bulkActions: BulkActionType[] = [
            {
                type: "action",
                text: 'NEXT',
                icon: faForward,
                action: () => {
                    console.log(DataTableContextObj.searchState.selectedRowIds)
                },
            },
        ];

        let options: OptionType = {
            url: '/uiapi/rest/nodes',
            query_param: { all: true, get_pages: true, limit: DEFAULT_RECORD_LIMIT, stream: 'progress' },
            serial_number: false,
            id_field: 'deveui',
            oboe_path: 'pages.*',
            available_key: 'deveui',
            allowBulkActions: true,
            defaultSortField: 'sort_by_deveui',
            defaultSortOrder: 'asc',
            emptyDataMsg: '<b>Sorry!</b> No Device Available',
            modal: false,
            hideBulkActions: true
        }

        return {
            ...actions,
            columns: columns,
            ...options,
            pageButons: pageButtons,
            bulkActions: bulkActions
        };
    }

    let refresh = stateInit.refresh;
    
    return (
        <>
            <div className="row fuota-stepper-device">
                <div className="col">
                    <PageContent
                        name="my-select-devices"
                        id={campaignid}
                        tabname={tabname}
                        actions={getActions()}
                        breadCrumbArr={[]}
                        pageButtons={[]}
                        countLabel={`Select Devices`}
                        dataTableOption={initDataTable()}
                        exportInit={stateInit.exportInit}
                        refresh={refresh}>

                    </PageContent>
                </div>
            </div>
        </>
    )
})

export default SelectDevices;