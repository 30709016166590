export const PIXEL_IN_CHAR = 8;
export const FIRST_COL_WIDTH = ((20 / document.body.clientWidth) * 100);
export const LAST_COL_WIDTH = ((20 / document.body.clientWidth) * 100);
export const PAGES_IN_PAGINATION = 2;
export const MAX_ACTION_ICONS = 40;
export const DEFAULT_INPUT_LENGTH = 250;
export const DEFAULT_INPUT_VALIDATION = "^([a-zA-Z0-9\\s\\-\\.\\_])*$";
export const ID_INPUT_VALIDATION = "^([a-zA-Z0-9\\s\\-\\.\\_])*$";
export const DEVEUI_INPUT_VALIDATION = "^([a-fA-F0-9\\s\\-\\.\\_:])*$";
export const DEFAULT_RECORD_LIMIT = 50;

const setDebugValue  = ( newHtml, id  =  'all-extra-debug-value' ) => {
    
    try {
        if(id === 'all-extra-debug-value') {
            var html = document.getElementById(id).innerHTML;
            document.getElementById(id).innerHTML = `${html}<br/>${newHtml}`;
        }else {
            document.getElementById(id).innerHTML = `${newHtml}`;
        }
    }catch(e) {
        //console.log(e);
    }
    

}

export { setDebugValue }