
import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';

import { strings } from "./../../services/Localization";

import { faList } from '@fortawesome/pro-regular-svg-icons'

import { getAppBase } from '../../utils/consts';

import {  TAB_GATEWAY_BULK_OTA } from '../../datatypes/tabsconstants'

function getSelectedIds(value) {
      const data = value
                    .filter(ele => (ele.gtwType &&  ele.gtwType !== 'generic' && (ele.gtwType.indexOf("Orbiwise") === -1)))
                    .map(ele => { return { id: ele.uuid, gtw_type: ele.gtwType}})
        return data
    }

function uniqueGtwType(value) {
    const gateway_type = [];
    const gtwtype = new Set(value.map((ele) => ele.gtw_type));
    gtwtype.forEach((v) => { gateway_type.push(v) })  
    return gateway_type
}   


const detailPageNav = ( navigate, tab ) => {
    

    navigate( `${getAppBase()}/omc-gateways/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/omc-gateways`}})
}

const getDataFromLocalStorage = (key) => {
    const item = JSON.parse(localStorage.getItem(key)) || null;
    if(item) {
        return item;
    }
    return [];
}

const GatewayBulkActionProps:DetailPagePropsType =  {
    getBreadCrumbArr: ( breadCrumbArgs ) => {
        
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '-1';
        let breadCrumbArr:BreadCrumbType[] =  [
            {label: "Gateway",  url: prevPageUrl},
            {label: "Batch OTA Update",  url:''},
        ]

        return breadCrumbArr;
    },    
    getActions : (actionArgs, navigate) => {
        

        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';
        let selectedIds = actionArgs.hasOwnProperty('selectedIds') ? getSelectedIds(actionArgs['selectedIds']) : getDataFromLocalStorage('selectedIds');
        
        const gateway_type = uniqueGtwType(selectedIds)

        let actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.GATEWAY_CONFIGURATION,
                icon: faList,
                key: TAB_GATEWAY_BULK_OTA,
                action: () => detailPageNav(navigate, TAB_GATEWAY_BULK_OTA),
                content: {
                    type: 'SchemaModal',
                    url:'/rest/omc/gateways?get_schema=put,bulk_ota',
                    backUrl: prevPageUrl,
                },
                EditObject: {selectedIds: selectedIds, gateway_type:gateway_type, __readonly: false , }

            }     
        ]

        return actions;
    }
}

export default GatewayBulkActionProps;


