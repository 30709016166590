import React from "react";
import { strings } from "../../services/Localization";
import { Whoami } from "../../services/Login";
import "./welcome.css";
import { GenericDassQuery } from "../../services/BasicDassQueries";
declare const constants;

// import config  from '../../../../../server/config';

import OrbiwisePowerLogo from '../../../resources/images/logo-orbiwise-noir-horizontal.png';

import MockUp from '../../../resources/images/mock-up.png';

export class Welcome extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            signedIn: false,
            ready: false,
            customPage: "",
        };
    }

    public async componentDidMount() {
        try {
            const response: any = await Whoami();

            if (response.user) {
                let customPage = "";
                if (response.user._environment.welcome_page_id) {
                    try {
                        const res = await GenericDassQuery("/rest/users/$?_get_welcome_page=true");
                        const pages = res.data;
                        customPage = pages.welcomePage;
                    } catch (e) {}
                }
                this.setState({ signedIn: true, customPage });
            }
        } catch (error) { }
        this.setState({ ready: true });
    }

    public render() {
        return (
            <div>
                {this.state.ready && this.state.customPage && <div dangerouslySetInnerHTML={{__html: this.state.customPage}} />}
                {this.state.ready && !this.state.customPage && <div className=" ">

                    {constants.hideWelcomeBox !== true && <div className=" ">
                        <div className="col-md-10 welcomePage position-relative">
                            <div className="row d-flex align-items-center">
                                <div className="col-md-6 px-5">
                                    <WelcomePageContentLeft signedIn={this.state.signedIn}/>
                                </div>
                                <div className="col-md-6 d-flex justify-content-center align-items-center ">
                                    <WelcomePageConetentRight />
                                </div>
                            </div>    
                            <PoweredByV1 />    
                        </div>
                    </div>}

                </div>}
                {constants.hideWelcomeBox === true && (
                    <PoweredByV2 />
                )}
            </div>
        );
    }
}

const WelcomePageConetentRight = () => {
    return (
        <div>
            {/*constants.welcome_right_side_image !== "" &&
                <img src={constants.welcome_right_side_image || "/images/mock-up.png"} className="img-responsive" alt="" />
    */} 
        <img className="img-fluid" src={MockUp} />
            {constants.welcome_right_side_text !== "" &&
           
                <p className="text_under_mc">{constants.welcome_right_side_text || strings.WELCOME_RIGHT_MESSAGE}</p>
            }
        </div>
    )
                                    
}


const PoweredByV1 =  () => {
    return (
        <div className="position-absolute bottom-0 end-0 pe-3 pb-2 text-end">
            <div title={constants.version_long}>{constants.version}</div>
            <div className="text_center_style">{strings.POWERED_BY}
                <img className="logo_orbiwise" src={OrbiwisePowerLogo} />
            </div>
        </div>
    )
 }

const WelcomePageContentLeft = ( props:any ) => {
    return (
        <div className="welcomePageContent">
            <h1 className="welcomePageTitle">{constants.welcome_title}</h1>
            <p className="welcomePageMessage">{constants.welcome_text}</p>
            {props.signedIn === false && (
                <div>
                    <p>
                        <a className="btn btn-lg welcomePageButtonLearn"
                            href={constants.welcome_link} role="button">{strings.WELCOME_LEARN_MORE}
                        </a>
                    </p>
                </div>
            )}
        </div>
    )
}

const PoweredByV2 = () => {
    return (
        <div className="welcomePowerBy">
            <small>
                {strings.POWERED_BY}
                <img className="logo" src={OrbiwisePowerLogo} />
        </small>
        </div>
    )
}