import React, { /*useEffect, useState,*/ useContext} from "react";
import { OverlayTrigger, Tooltip  } from "react-bootstrap";

import { strings } from "../../../services/Localization";

import NoPositionOnMap from "./../../../assets/map-marker-slash.svg";
import MapContext from '../../../context/MapContext';


import {  useNavigate  } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroadcastTower } from "@fortawesome/pro-regular-svg-icons";
interface INetworkMapTableProps {
    tab:any;
    selectedStatus: string[];
}

const DeviceTable:React.FC<INetworkMapTableProps> = (props) => {
    const MapContextObj = useContext(MapContext);
    
    const navigate = useNavigate();
    
    let FilterdTableData = MapContextObj.networkState.TableData;
    if(props.tab === 'gateways') {
        let selectedStatus = props.selectedStatus;

        if(selectedStatus.length > 0) {
            FilterdTableData = FilterdTableData.filter(data => {
                return selectedStatus.includes(data.status);
            })

        }
    }

    const FlyToTheMarker = (data, type) => {
       
        MapContextObj.FlyToTheMarker( data, type, navigate )
        
    }
 
    return (
      
            <div className="panel panel-default" id="map-device-list-panel">
                <div className="panel-body panel_body_newstyle">
                <table className="table table-hover table_newstyle">
                    <tbody>
                        {FilterdTableData.map((data, index) => {
                            const type = (data.hasOwnProperty("deveui")) ? "Device" : "Gateway";
                            const id = (data.hasOwnProperty("deveui")) ? data.deveui : data.id;
                            return <tr style={{cursor:'pointer'}}  key={index} className={(!data.longitude || !data.latitude) ? "no-position" : ""}>
                                <td onClick={() => FlyToTheMarker(data, type)} >
                                    <small>
                                        {type === "Gateway" && 
                                            <> 
                                            <FontAwesomeIcon icon={faBroadcastTower} className={` ${data.status === "OK" ? "green_icon" : "red_icon"}`}    id="gateway_icon"/> &nbsp;</>
                                        }
                                        {type === "Device" && id.match(/.{2}/g).join("-").toUpperCase()}
                                        {type === "Gateway" && id?.toUpperCase()}
                                        {(!data.latitude || !data.longitude) && 
                                            <>
                                                &nbsp;
                                                <OverlayTrigger
                                                    placement="left"
                                                    overlay={
                                                        <Tooltip id="no_position_tooltip">
                                                            {strings.MAP_NO_POSITION_SET}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <img src={NoPositionOnMap} id="no_position_icon" alt="" />
                                                </OverlayTrigger>
                                            </>
                                        }
                                        {type === "Device" ? <><br/> {data.comment}</>:
                                            data.name ? <><br/> {data.name}</> : null
                                        }
                                    </small>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            </div> 
    );
    
}

export default DeviceTable;