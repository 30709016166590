import { strings } from "../../services/Localization";

import { IConstants } from "../../types";
import { INavArrayType } from "./NavBuildComponets";

declare const constants: IConstants;



const checkFlag = (license: number, bit: number, user:any) => {

        //let {user} = state;

        if (!user || !user.can_register) {
            user = {
                can_register: false,
            };
        }
        if (license && bit) {
            switch (bit) {
                case 1:
                    // tslint:disable-next-line:no-bitwise
                    return ((license & 0x1) !== 0); // legacy registration
                case 2:
                    return ((license & 0x200) !== 0); // license management
                case 4:
                    // tslint:disable-next-line:no-bitwise
                    return (((license & 0x4) !== 0)            // batch registration
                                && (user.can_register)
                                && (((license & 0x1) !== 0)    // legacy registration
                                 || ((license & 0x2) !== 0))); // profile registration
                case 5:
                    // tslint:disable-next-line:no-bitwise
                    return ((license & 0x8) !== 0);             // active connections
                case 6:
                    // tslint:disable-next-line:no-bitwise
                    return ((license & 0x10) !== 0);            // event log
                default:
                    return false;
            }
        } else {
            return false;
        }
    }


export function getNavNstArray(lang: string) {
    const NavNstArray: INavArrayType[] = [
        {
            title: strings.NAV_MAP,
            type: 'link',
            href: '/nst/network_map',
            visible: (state) => {
                return true
            }
        },


        {
            title: strings.NAV_NST_GATEWAYS,
            type:'link',
            href: '/nst/omc-gateways',
            visible: (currentState) => {
                return currentState.user.omc_see_gtw
            },
        },


        {
            title: strings.NAV_NST_DEVICES,
            type:'link',
            href: '/nst/omc-devices',
            visible: (state) => {
                return (state.user.omc_see_end_devices || state.user.omc_mng_end_devices)
            }
        },


        {
            title: strings.NAV_NST_SERVERS,
            type:'link',
            href: '/nst/servers-status',
            visible: (state) => {
                return (state.user.omc_see_servers || state.user.omc_mng_servers)
            }
        },


        {
            title: strings.NAV_NST_ALARMS,
            type:'dropdown',
            visible: (currentState) => {
                return (currentState.user.omc_mng_server_alarms || currentState.user.omc_mng_gtw_alarms)
            },
            submenu:[
                {
                    href: '/nst/gateway-alarms',
                    title: strings.NAV_NST_GATEWAY_ALARMS,
                    visible: (currentState) => {
                        return currentState.user.omc_mng_gtw_alarms || currentState.user.omc_see_gtw_alarm_timeline ;
                    }
                },
                {
                    href: '/nst/server-alarms',
                    title: strings.NAV_NST_SERVER_ALARMS,
                    visible: (currentState) => {
                        return currentState.user.omc_mng_server_alarms || currentState.user.omc_see_servers;
                    }
                }
            ]
        },


        {
            title: strings.NAV_PROFILES_ITEM,
            type: 'dropdown',
            visible: (currentState) => {
                const u = currentState.user;
                return constants.enable_profiles && !u.hasOwnProperty("is_organisation") && u.account_status === "active" &&
                                        (u.can_list_qos_profile || u.can_list_channel_profile);
            },
            submenu:[
                {
                    href: '/nst/profiles/qos',
                    title: strings.NAV_QOS_PROFILES,
                    visible: (currentState) => {
                        return currentState.user.can_list_qos_profile
                    }
                },
                {
                    href: '/nst/profiles/channel',
                    title: strings.NAV_CHANNEL_PROFILES,
                    visible: (currentState) => {
                        return currentState.user.can_list_channel_profile
                    }
                }
            ]
        },


        {
            title: strings.NAV_NST_TENANTS,
            type:'link',
            href: '/nst/tenants',
            visible: (currentState) => {
                return currentState.user && (currentState.user.omc_edit_user_settings || currentState.user.omc_mng_network_tenants )
            }
        },
        {
            title: strings.NAV_NST_AUDIT_TRAIL,
            type:'dropdown',
            visible: (currentState) => { return currentState.user.omc_see_gateway_audit_trail},
            submenu:[
                {
                    title: strings.NAV_NST_AUDIT_TRAIL_GATEWAYS,
                    href:"/nst/gateway-audit-trail",
                    visible: (currentState) => { return currentState.user.omc_see_gateway_audit_trail}
                },
            ]
        },
        {
            title: strings.NAV_NST_JOBS,
            type:'link',
            href: '/nst/gateway-jobs',
            visible: (currentState) => { return currentState.user.omc_see_activity_log },
            
        },

        {
            title: strings.NAV_NST_ANALYTICS,
            type:'dropdown',
            visible: (currentState) => {
                return (currentState.user.omc_read_reports || currentState.user.omc_read_traces || currentState.user.omc_read_raw_traces)
            },
            submenu:[
                {
                    href: `/tfw1/trace?lang=${lang}`,
                    hreftype: "extlink",
                    title: strings.NAV_GATEWAY_TRACE,
                    visible: (currentState) => {
                        return (currentState.user.omc_read_traces || currentState.user.omc_read_raw_traces)
                    }
                },
                {
                    href: '/reports/index.html',
                    hreftype: "extlink",
                    title: strings.NAV_NST_GATEWAY_REPORT,
                    visible: (currentState) => {
                        return currentState.user.omc_read_reports
                    }
                },
            ]
        },
        {
            title: strings.NAV_SYSTEM,
            type:'dropdown',
            visible: (currentState) => true,        // not sure if there is any case when it should not be visible.
            submenu:[
                {
                    href: '/nst/omc-settings',
                    title: strings.NAV_NST_GENERAL_SETTINGS,
                    visible: (currentState) => {return (currentState.user.omc_see_general_settings || currentState.user.omc_mng_general_settings)}
                },
                {
                    href: '/nst/server-versions',
                    title: strings.NAV_NST_SERVER_VERSIONS,
                    visible: (currentState) => { return (currentState.user.omc_see_servers || currentState.user.omc_mng_servers)}
                },  
                {
                    href: '/nst/product-license',
                    //onClick: (currentState) => { return currentState.ShowLicense },
                    title: strings.PRODUCT_LICENSE_TITLE,
                    visible: (currentState) => {
                        return (currentState.user && currentState.user.sys_admin && checkFlag(currentState.user._license, 2, currentState.user))
                    }
                },
                {
                    href: '/rawlog.log',
                    title: strings.LORA_UPLINK_LOGS,
                    visible: (currentState) => {
                        return constants.loralogsEnabled
                    }
                },
                {
                    href: '/svrlogs.tar',
                    title: strings.GET_SERVER_LOGS,
                    visible: (currentState) => {
                        return constants.svrlogsEnabled
                    }
                },
                {
                    href: '',
                    title: `Build: ${constants.buildtime}`,
                    visible: (currentState) => {
                        return constants.svrlogsEnabled
                    }
                }
            ]
        },
        {
            title: strings.NAV_HELP,
            type:'dropdown',
            visible: (currentState) => {
                const userenv = currentState.user?._environment || {};
                return !!(userenv?.getting_started_link || userenv?.help_page_link || userenv?.helpdesk_email_template_id || userenv?.swagger_ui)
            },
            submenu:[
                {
                    href: (currentState) => { 
                        const userenv = (currentState.user || {})._environment || {};
                        return userenv?.help_page_link;
                    },
                    title: strings.NAV_HELP_PAGE,
                    visible: (currentState) => {
                        const userenv = (currentState.user || {})._environment || {};
                        return !!userenv?.help_page_link
                    }
                },
                {
                    href: (currentState) => { 
                        const userenv = (currentState.user || {})._environment || {};
                        return userenv?.getting_started_link;
                    },
                    title: strings.NAV_HELP_GETTING_STARTED,
                    visible: (currentState) => {
                        const userenv = (currentState.user || {})._environment || {};
                        return !!userenv?.getting_started_link
                    }
                },
                {
                    href: '/nst/swagger',
                    title: strings.NAV_HELP_SWAGGER,
                    visible: (currentState) => {
                        const userenv = (currentState.user || {})._environment || {};
                        return !!userenv?.swagger_ui;
                    }
                },
                {
                    href: (currentState) => { 
                        return currentState.helpdeskMailto;
                    },
                    title: strings.NAV_HELP_SUPPORT_EMAIL,
                    visible: (currentState) => {
                        const userenv = (currentState.user || {})._environment || {};
                        return !!(userenv?.helpdesk_email_template_id && currentState.helpdeskMailto)
                    }
                }
            ]
        },     
    ];

    return NavNstArray;
}
