
import LocalizedStrings from "react-localization";
import { english } from "../localization/en";

const zh = require("../localization/zh.json");
const fr = require("../localization/fr.json");


interface ITranslate {
    [index: string]: string | number;
}

export const strings = new LocalizedStrings<typeof english>({
    en: english,
    zh: {...english, ...zh},
    fr: {...english, ...fr},
    dd: Object.keys(english).reduce((p, c) => ({...p, [c]: "[" + c + "]" }), {} as any)
});

const langMatch = decodeURIComponent(document.cookie).match(/language=([a-zA-Z]+)/);
strings.setLanguage(langMatch ? langMatch[1] : "en");

export function translate (content: string, data: ITranslate ) {

    return strings.formatString(content, data); // hack need Fromatted Object here

}