import React, {useState, useEffect, useContext } from "react";

// import { ProfilesTable } from "./../../components/Profiles/ProfilesTable";
import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
import AppContext from '../../context/AppContext'



import { useParams, useNavigate  } from 'react-router-dom';

import { /*DataTable, PageHeader, DetailPageContainer,*/ dialog, dialogDescription /*DataTableWrapper*/} from '../../components/Common';

declare const constants;

import { BreadCrumbType } from '../../datatypes/datatypes';

//import { Outlet  } from 'react-router-dom';

import { toast } from "./../../utils/Toaster";

import { appBaseUrl, getAppBase } from '../../utils/consts';

import{ ID_INPUT_VALIDATION, DEFAULT_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";
    
import { TAB_GATEWAY_CONFIGURATION, TAB_ADD_GATEWAY /*,getPageDisplayStatus */} from '../../datatypes/tabsconstants'

import { faExclamationTriangle,faExclamationCircle, faInfoCircle, faQuestion, faAdd, faRefresh }  from '@fortawesome/free-solid-svg-icons';

import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { IUser } from "src/dassTypes";
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PageContent from "../PageContent";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";


export const pageTitle = strings.NAV_GATEWAYS_ITEM;
export const pageUrlPart = "my-gateways";


interface IPageStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    pageTitle: string;
    editEntityId: string | null;            // The entity in this view is a gateway
    schemaMethod: "post" |"put" | "" | string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    isRowDeleted?:boolean;
}


export interface IRowType {

    address: {

    };
    altitude: number;
    antenna_gain: number;
    beacon_enabled: boolean;
    beacon_frequency: number;
    cable_loss: number;
    classb_tx_power: number;
    gateway_firmware_version: string;
    gateway_software_version: string;
    gateway_type: string;
    id: string;
    last_rest_keepalive_date: string;
    name: string
    position_valid: boolean;
    rx1_tx_power: number;
    rx2_tx_power: number;
    status: "NEVER_SEEN" | string;
}



type filterOptionType = {
    label:string;
    value:string;
}

const MyGateways : React.FC<{}> = () =>  {

    let user = {};

    let { id, tabname } = useParams();

    const navigate = useNavigate();


    const AppContextObj = useContext(AppContext);

    const StatusValues = {
		// ANY:                   { label: strings.GATEWAY_STATUS_ANY,    value: "" },
		NEVER_SEEN:            { label: strings.GTW_STATUS_NEVER_SEEN,            col: "black", color: "#000000" },
		GTW_INIT:              { label: strings.GTW_STATUS_GTW_INIT,              col: "red", color: "#aa0000", t: "o" },
		OK:                    { label: strings.GTW_STATUS_OK,                    col: "green",color: "#1cae2c", },
		OFF:                   { label: strings.GTW_STATUS_OFF,                   col: "red",color: "#aa0000", t: "o" },
		BACKHAUL_ISSUE:        { label: strings.GTW_STATUS_BACKHAUL_ISSUE,        col: "red",color: "#aa0000", t: "o" },
		OFF_OR_BACKHAUL_ISSUE: { label: strings.GTW_STATUS_OFF_OR_BACKHAUL_ISSUE, col: "red",color: "#aa0000", t: "o" },
		RADIO_OFF:             { label: strings.GTW_STATUS_RADIO_OFF,             col: "red",color: "#aa0000", t: "o" },

		WARNING:     { text: "Warning",     col: "yellow",color: "#ffbc3c", t: "n" },
		MINOR_ISSUE: { text: "Minor Issue", col: "orange",color: "#840d0d", t: "n" },
		MAJOR_ISSUE: { text: "Major Issue", col: "red", color: "#aa0000",   t: "n" },
	}
   
	// const useColors = true;

	// statusIcons = {
	// 	red: "fas fa-exclamation-triangle" + (useColors ? " led_red" : ""),
	// 	orange: "fas fa-exclamation-circle" + (useColors ? " led_orange" : ""),
	// 	yellow: "fas fa-info-circle" + (useColors ? " led_yellow" : ""),
	// 	green: "fas fa-check" + (useColors ? "" : ""),
	// 	black: "fas fa-question" + (useColors ? "" : ""),
	// }    

    const statusIcons = {
		red: faExclamationTriangle,
		orange: faExclamationCircle,
		yellow: faInfoCircle,
		green: faCircleCheck,
		black: faQuestion,
	}  

    
    const stateInit = {
        loggedUser: null,
        showAlertModal: false,
        pageTitle: pageTitle,
        editEntityId: '',
        schemaMethod: "",
        breadCrumbArr: [{label: pageTitle, url:''}],
        refresh:false,
        isRowDeleted:false
    };
    
    
    const [state, setState] = useState<IPageStates>(stateInit)

    //const can_list_profile = "can_list_profile";

    useEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {
            if(checkLogin(AppContextObj.user)) {
                
                setState(prevState => { return {...prevState, loggedUser: AppContextObj.user}})

                user = AppContextObj.user;

            }else {
                window.location.href = appBaseUrl + "/signin";
            }
            //LoggedIn();
        }
    },[])
    

    const detailPageNav = ( navigate, tab, id, row = null) => {
        if(id) {
            
            navigate(`/app/${pageUrlPart}/${id}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/my-gateways`}})
        }else {
            
            navigate(`/app/${pageUrlPart}/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/my-gateways`}})
        }
    }


    const deleteRow = async ( gateway: IRowType ) => {
        const confirmDialogeSettings = {
                title:  "You are about to delete a gateway",
                description: "Delete '" + gateway.id + "'?",
                actionLabel: 'Delete',
        };

        if (await dialog(confirmDialogeSettings) === true) {
            // spinnerClass.show();
            try {
                await GenericDassQuery("/rest/gateways/" + gateway.id, { method: "DELETE" });
                refreshTable(true);
                toast.success("Gateway successfully deleted");
            } catch (e) {
                toast.error(e.message);
            }
            // spinnerClass.hide();
        }
    }

    const deleteBulkAppGateways = async (selectedRows: IRowType[]) => {
        
        
        const gatewayIds = selectedRows.map((row) => {
            return row['id'];
        })

        if(gatewayIds && gatewayIds.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to delete ${gatewayIds.length} selected gateway(s)`,
                description: await dialogDescription(gatewayIds) + "",
                actionLabel: 'Delete',
            };
    
            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(gatewayIds.map((gatewayId) => {
                        return GenericDassQuery("/rest/gateways/" + gatewayId, { method: "DELETE" })
                    })).then((values) => {
                        refreshTable(true);
                        toast.success("Gateways successfully deleted");
                    });
                } catch (e) {
                    toast.error(e.message);
                }                
            }
        }
    }

    const  getActions = () => {
        
        return {
            actions: [
                {
                    type: "action",
                    text: strings.DELETE_GATEWAY,
                    icon: faTrashAlt,
                    action: (row: IRowType)=> deleteRow(row),
                    visible: (row: IRowType) => AppContextObj.user.can_add_gtw && AppContextObj.user.account_status==="active",
                }
            ],
            bulkActions:[
                {
                    type: "action",
                    text: strings.DELETE_GATEWAYS,
                    icon: faTrashAlt,
                    action: (selectedIds) => deleteBulkAppGateways(selectedIds)
                }
            ]
        }
    }


    const  initDataTable = () => {
		
        let actions;
        var pageButtons;
        
        actions = getActions();
        
        let filterOptions: filterOptionType[] = [];

		const t = user && AppContextObj.user.can_access_gtw_unfiltered ? "n" : "o";

		for (const key of Object.keys(StatusValues)) {
			const val = StatusValues[key];
			if (!val.t || val.t === t) {
				filterOptions.push({ label: val.label || val.text, value: val.value != null ? val.value : key });
			}
		}
        
		let columns = [
            {
                key: "id",
                type: "text",
                title: strings.GATEWAY_ID,
                filterable: true,
                filterField: 'search_id',
                filterType: 'text',
                newCellWidth: "250px",
                inputValidation: ID_INPUT_VALIDATION,
                render: (x) => x.id,
                filterParams: {
                    mapper: x => (x || "").replace(new RegExp("-|:", 'g'), "")
                },
                detailLink:true,
                copyLink: true,
                detailPageNav: (row: IRowType) => detailPageNav(navigate, TAB_GATEWAY_CONFIGURATION, row.id, row),
                customClass: 'font-monospace nowarp',
                cellWidth: 19
            },
            {
                key: "status",
                title: strings.STATUS,
                type: "icon_with_tooltip",
                filterable: true,
                filterField: 'search_status',
                filterType: 'select',
                newCellWidth: "80px",
                filterParams: {
                    options: filterOptions
                },
                dataAlign: 'center',
                render: (x) => <div style={{
                    color: StatusValues[x.status].color
                }}><FontAwesomeIcon icon={statusIcons[StatusValues[x.status].col] || ""} /></div>,
                render_tooltip: (x) => {
                    
                    let tip = "<ul>";
                    if (StatusValues[x.status] != null) {
                        tip = '<li>' + (StatusValues[x.status].label ? StatusValues[x.status].label : StatusValues[x.status].text);
                    }
                    if (x.active_alarms && x.active_alarms.length > 0) {
                        tip += "";
                        let br = "";
                        for (const alrm of x.active_alarms) {
                            if (alrm && alrm.description) {
                                tip += "<li>" + alrm.description  + br;
                                br = "";
                            }
                        }
                        tip += ""
                    }
                    tip += "</ul>";
                    return tip;
                },
                cellWidth: 19
            },
            {
                key: "name",
                title: strings.GATEWAY_NAME,
                type: DEFAULT_INPUT_VALIDATION,
                filterable: true,
                filterField: 'search_name',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                cellWidth: 100,
                cellWidthType: '%'
            }
        ];
        
        let options = {
            url:'/uiapi/rest/gateways',
            query_param:{all:true, get_pages:true, limit: DEFAULT_RECORD_LIMIT, stream:'progress'},
            serial_number:false,
            id_field:'id',
            oboe_path:'pages.*',
            available_key: 'id',
            
        }

        return {
             ...actions
            ,columns
            ,...options
            ,...pageButtons 
        };
	}

    const refreshTable = (isRowDeleted=false) => {

        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh,isRowDeleted:isRowDeleted}
        })
        
    }
    
    const  getPageButtons = () => {
        
        var  pageButtons = [
            {
                title: strings.GATEWAY,
                action: () => detailPageNav(navigate, TAB_ADD_GATEWAY, ''),
                type: 'button_with_icon',
                icon: faAdd,
                visible: (row: IRowType) => AppContextObj.user.can_add_gtw,
            },
            {
                title: strings.REFRESH,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh,
            },
        ]

        return pageButtons;
    }
    return (<PageContent
        name="my-gateways" 
        id={id} 
        tabname={tabname} 
        actions={getActions()} 
        breadCrumbArr={state.breadCrumbArr} 
        pageButtons={getPageButtons()} 
        countLabel={`Gateways`} 
        isRowDeleted={state.isRowDeleted}
        dataTableOption={initDataTable()} 
        refresh={state.refresh}>
    </PageContent>)

}


export default MyGateways;