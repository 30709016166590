import React, { useState } from "react";
import { IUiSchemaElemArgs } from "./SchemaController";


// ACE
import AceEditor, { IAceEditorProps } from "react-ace";

import 'ace-builds/webpack-resolver';
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";




interface ISchemaFormProps {
	args: IUiSchemaElemArgs;

	value: string;
	onFocus?: (event: any) => void;
	onBlur?: (event: any) => void;
	onTextChange: (text: string) => string | null;
	context: number;
}

const SchemaFormAceEdit : React.FC<ISchemaFormProps> = (props) =>  {

	const [value, setvalue] = useState(props.value);
	const [context, setcontext] = useState(0);
	const [update, setupdate] = useState(0);
	const { args } = props;
	const { uiElem } = args;
	const { componentOptions } = uiElem || {};

	if (props.context != context) {
		setvalue(props.value);
		setcontext(props.context);
	}

	const onChange = (value: string, event: any) => {
		const text = props.onTextChange(value);
		if (text != null) {
			setvalue(text);
		} else {
			setupdate(update + 1);
		}
	}


	const aceProps: IAceEditorProps = {
		className: "schema-modal-ace-edit",
		mode: componentOptions?.aceMode || "javascript",
		theme: "github",
		value,
		onChange,
		name: "AceEditor",
		minLines: uiElem?.multiLine,
		maxLines: componentOptions?.aceMaxLines
					? componentOptions?.aceMaxLines === "Infinity"
						? Infinity
						: componentOptions?.aceMaxLines
					: Infinity,
		width: componentOptions?.aceWidth || "100%",
		readOnly: args.readOnly,
		height: componentOptions?.aceHeight || undefined,

		setOptions: { 
			useWorker: true,
			showPrintMargin: false
		},
		editorProps: {
			$blockScrolling: true,
		}
	}

	return <AceEditor key={args.fullkey} {...aceProps}/>;
};

export default SchemaFormAceEdit;
