import React, { useEffect , useContext } from 'react';
import { Whoami, checkLogin } from "../services/Login";
import AppContext from "../context/AppContext";
import { useIdleTimer } from 'react-idle-timer'
import { IConstants } from "../types";
import { localSessionIdleTimeout, whoAmICheckTime} from '../utils/consts';

declare const constants: IConstants;

const Timer = () => {
    const appContextObj = useContext(AppContext);

    const handleOnIdle = () => {

        appContextObj.signOut();
    }

    const handleOnActive = event => {
//        console.log('user is active', event)
//        console.log('time remaining', getRemainingTime())
    }

    const handleOnAction = event => {
//        console.log('user did something', event)
    }

    const { getRemainingTime /*, getLastActiveTime*/ } = useIdleTimer({
        timeout: constants.sessionIdleTimeoutSec ? constants.sessionIdleTimeoutSec * 1000 : localSessionIdleTimeout,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 1000,
        crossTab: true,
        syncTimers: 1000,   // 1 seconds slack between the idle timer in separate tabs is ok
    })

    useEffect(() => {

        const debugTimer = setInterval(() => {
            appContextObj.setDebugMessage("IdleTimer", Math.round(getRemainingTime() / 1000) + "s");
        }, 10000);

        const timer = setInterval(() => {

            async function checkWhoami() {
                if(checkLogin(appContextObj.user)) {
                    let response = await Whoami("timer");
                    if(response.message === 'Unauthorized') {       // FIXME: this should be cleaned
                        appContextObj.signOut();
                    }
                }else {

                }
            }

           checkWhoami();

        }, (constants.whoAmICheckTime) ?  constants.whoAmICheckTime : whoAmICheckTime);

        return () => {
            clearTimeout(debugTimer);
            clearTimeout(timer);
        };

    }, []);


    return(<></>)

}

export default Timer;