

const pageHasGlobalSearchTagControl = ( pageName: string ) => {
    const pageList:string[] = [
        'gateway-alarm',
        // 'gateway-audit-trail',
        'map-gateway-table'
    ]

    return pageList.includes(pageName);
}

export { pageHasGlobalSearchTagControl };