
import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';
import { faAdd } from '@fortawesome/free-solid-svg-icons'
import { getAppBase } from '../../utils/consts';
// import { strings } from "../../services/Localization";
import { TAB_AUTHORIZE_GATEWAY } from '../../datatypes/tabsconstants'

const detailPageNav = ( navigate, tab, prevPageUrl) => {
        navigate(`/nst/gateway-manage-auth/${tab}`, {state: {row: null , prevPageUrl}})
}

export const GatewayManageAuthProps: DetailPagePropsType =  {
    
    getBreadCrumbArr: (args) => {
        
        let prevPageUrl = args.hasOwnProperty('prevPageUrl') ? args['prevPageUrl'] : `${getAppBase()}/gateway-manage-auth`;

        const breadCrumb:BreadCrumbType[] = [
            { label: `Manage Authorization`, url: prevPageUrl },
            { label: `Authorize Gateway`, url: "" }
        ] 
        return breadCrumb;
    },
    getActions : (args, navigate) => {
        
        let prevPageUrl = args.hasOwnProperty('prevPageUrl') ? args['prevPageUrl'] : `${getAppBase()}/gateway-manage-auth`;

        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: "Authorize Gateway",
                icon: faAdd,
                key: TAB_AUTHORIZE_GATEWAY,
                action: () => detailPageNav(navigate, TAB_AUTHORIZE_GATEWAY, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/gateways?get_schema=dassui,post,auth_gtw`,
                    backUrl: `${prevPageUrl}`,
                },
            }
        ];
        return actions;
    }
}



