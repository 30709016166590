import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { IUiSchemaElemArgs } from "./SchemaController";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';


interface ISchemaFormProps {
	args: IUiSchemaElemArgs;

	value: string;
	onFocus?: (event: any) => void;
	onBlur?: (event: any) => void;
	onTextChange: (text: string) => string | null;
	context: number;
}

const SchemaForm : React.FC<ISchemaFormProps> = (props, props2) =>  {

	const [value, setvalue] = useState(props.value);
	const [context, setcontext] = useState(0);
	const [showpass, setShowpass] = useState(false);
	const { args } = props;
	const { fullkey, error, type } = args;

	const types: any = {
		password: "password",
		"old-password": "password",
		"new-password": "password",
		number: "text",
		integer: "text",
	};

	if (props.context !== context) {
		setvalue(props.value);
		setcontext(props.context);
	}

	const onChange = (event: any) => {
		const text = props.onTextChange(event.target.value);
		if (text != null) setvalue(text);
	}

	return typeof args.uiElem?.multiLine === "number" && args.uiElem.multiLine > 1 ? <Form.Control
		as="textarea"
		key={"form-" + fullkey}
		disabled={args.readOnly}
		isInvalid={error}
		onFocus={props.onFocus}
		onBlur={props.onBlur}
		rows={args.uiElem?.multiLine}
		value={value}
		className="new_style_textarea"
		onChange={onChange}
		placeholder={args.uiElem?.placeholder}
	/> :

	<InputGroup key={"form-" + fullkey}	>
		<Form.Control
		 	key={"form-" + fullkey}
			type={(types[type] === "password" && showpass ? "text" : types[type]) || "text"}
			disabled={args.readOnly}
			isInvalid={error}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
			value={value}
			className="new_style_focus"
			autoComplete={type === "new-password" || type === "old-password" ? type : "off"}
			onChange={onChange}
			placeholder={args.uiElem?.placeholder}
		/>
		{types[type] === "password" && 
			<Button disabled={args.readOnly} variant="outline-secondary" className={error ? "schema-modal-error-frame" : ""}
					tabIndex={-1}
					onClick={(ev) => setShowpass(!showpass)}
					onMouseDown={(ev) => ev.preventDefault()}
			>
				<FontAwesomeIcon icon={showpass ? faEye : faEyeSlash} />
			</Button>}
	</InputGroup>;

};

export default SchemaForm;
