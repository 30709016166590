import React, { useContext, useEffect }  from "react";

import { Button, Dropdown } from 'react-bootstrap';

import { isMobile } from 'react-device-detect';

import { ColumnType } from './DataTypes';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ScopeType } from './DataTypes';

import { DataTableContext } from "./DataTableState";

import { DataTableChildContext } from "./DataTableChildState";
import { PageButtonType } from "src/datatypes/datatypes";
import { strings } from "../../../services/Localization";


interface pageButtonProps {
    pageButtons: PageButtonType[];
    columns?:ColumnType[];
    scope: ScopeType;
}

const PageButtonsComponentDataTable: React.FC<pageButtonProps> = ({ pageButtons, columns, scope }) => {
    if(isMobile) {
        return <RenderPageButtonSmallScreen pageButtons={pageButtons} columns={columns} scope={scope} />;
    }else {
        return <RenderPageButton pageButtons={pageButtons}  columns={columns} scope={scope} />
    }

}

export { PageButtonsComponentDataTable }

const refreshTable = ( DataTableContextObj ) => {

    DataTableContextObj.setSearchState(prevState => {
        return {...prevState, refresh:!prevState.refresh}
    })
    //DataTableContextObj.setReloadData();

}

const RenderPageButton: React.FC<any> = ({ pageButtons, columns, scope }) => {
    let DataTableContextObj;
    if(scope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }

    

    const buttonList = pageButtons.map(function(pageButton, index) {

        if(pageButton.type === 'button_with_icon') {
            return (
                <div key={index} style={{margin :'4px', float:'left'}}>
                    <Button size='sm' className="text-nowrap" title={pageButton.title} variant="dark" onClick={pageButton.action}>
                        {pageButton.icon &&  <FontAwesomeIcon className="mr-2" icon={pageButton.icon} />}   
                        {pageButton.title}
                    </Button>
                </div>
            );
        }else if(pageButton.type === 'button') {
            return (
                <div key={index} style={{margin:'4px', float:'left'}}>
                    <Button size='sm' className="text-nowrap" title={pageButton.title} variant="outline-dark" onClick={pageButton.action}>
                        {pageButton.icon &&  <FontAwesomeIcon icon={pageButton.icon} />}
                    </Button>
                </div>
            );
        }else if(pageButton.type === 'refresh') {
            return (
                <div key={index} style={{margin:'4px', float:'left'}}>
                    <Button size='sm' className="text-nowrap" title={pageButton.title} variant="outline-dark" onClick={() => { refreshTable (DataTableContextObj)}}>
                        {pageButton.icon &&  <FontAwesomeIcon icon={pageButton.icon} />}
                    </Button>
                </div>
            );            
        } else if(pageButton.type === 'column') {
            return ( <RenderColumnsButtons columns={columns}  key={index} scope={scope} />)
        }else {
            return ( <RenderDropDownButton pageButton={pageButton}  key={index}/>)
        }
    })
    
    return buttonList;
}


interface RenderPageButtonSmallScreenProps {
    pageButtons: PageButtonType[];
    columns?:ColumnType[];
    scope:ScopeType;
    
}

const RenderPageButtonSmallScreen: React.FC<RenderPageButtonSmallScreenProps> = ({ pageButtons  }) => {

    const buttonList = pageButtons.map(function(pageButton, index) {

        if(pageButton.type === 'button') {
            return (
                <Dropdown.Item key={index} onClick={pageButton.action}>{pageButton.title}</Dropdown.Item>
            );
        }else {
            return null;
        }

    })


    const dropDownButtonList = pageButtons.map(function(pageButton, index) {

        if(pageButton.type !== 'button') {
            return (
                <div  key={index} style={{margin :  '4px'}}>
                    <RenderDropDownButton pageButton={pageButton} />
                </div>
            );
        }else {
            return null;
        }

    })

    return (
            <div style={{display:'flex', flexDirection: 'row'}}>
                <div style={{margin:'4px'}}>
                    <Dropdown>
                        <Dropdown.Toggle  size='sm' variant="outline-dark" id="dropdown-basic">
                            More
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {buttonList}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div>
                    {dropDownButtonList}
                </div>
            </div>
            )
}


interface RenderColumnsButtonsProps {
    columns:  ColumnType[];
    scope:ScopeType;
}

const RenderColumnsButtons: React.FC<RenderColumnsButtonsProps> = ({ columns, scope }) => {
    
    //const [fields, setFields] = useState([]);
    let DataTableContextObj;
    if(scope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }

    let visibleColumns = DataTableContextObj.searchState.visibleColumns

    const toggleHideShow = ( columnKey ) => {

        
        let pos = visibleColumns.indexOf(columnKey);
        
        if(pos > -1) {
            if(visibleColumns.length > 1) {
                
                visibleColumns.splice(pos, 1);

                DataTableContextObj.setSearchState((prevSearchState:any ) => { 
                    return  {...prevSearchState, visibleColumns:[...visibleColumns]};
                })
                
            }
        }else {

            DataTableContextObj.setSearchState((prevSearchState:any ) => { 
                return  {...prevSearchState, visibleColumns:[...visibleColumns, columnKey ]};
            })

        }
    }
    if(!columns) {
        return null;
    }

    useEffect(() => {
        DataTableContextObj.setSearchState((prevSearchState:any ) => { 
            let isUpdate = undefined;
            let prevDataTableOptions = prevSearchState.dataTableOption;
            if(DataTableContextObj.searchState.visibleColumns.includes("mac_msg")){
                isUpdate = true;
            }
            return  {...prevSearchState, dataTableOption:{...prevDataTableOptions, query_param:{...prevDataTableOptions.query_param, mac_msg:isUpdate}}};
        })
    },[DataTableContextObj.searchState.visibleColumns]);

    const filteredColumn = columns.filter((item) => {
        return (item.key !== 'bulk_action_checkbox' && item.key !== 'action_button' && item.hasOwnProperty('enabledInColumn'))
    })
    return (
        <div style={{ float:'left', margin:'4px'}}>
            <Dropdown>
                <Dropdown.Toggle size='sm' variant="outline-dark" id="dropdown-basic">
                    {strings.COLUMNS}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {filteredColumn.map(function(item, index) {
                        
                        let checked = (visibleColumns.indexOf(item.key) > -1) ? true : false;

                        return (<div style={{fontWeight: 'bold', fontSize: '12px'}}  key={index} >
                                <div style={{display: 'flex',  flexDirection: 'row'}}>
                                    <div style={{margin: 5}}>
                                        <input type="checkbox" name={item.key} value={item.key} checked={checked} onChange={() => { toggleHideShow(item.key)}} />
                                    </div>
                                    <div style={{margin: 5}}>
                                        {item.title}
                                    </div>
                                </div>
                        </div>);
                        
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

interface RenderDropDownButtonProps {
    pageButton: PageButtonType;
}


const RenderDropDownButton: React.FC<RenderDropDownButtonProps> = ({ pageButton }) => {

    if(!pageButton.dropDownItems) return null;

    return (
        <div  style={{ float:'left', margin:'4px'}}>
            <Dropdown>
                <Dropdown.Toggle size='sm' variant="outline-dark" id="dropdown-basic" title={pageButton.title}>
                    {pageButton.title}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {pageButton.dropDownItems.map(function(item, index) {
                        return (<Dropdown.Item href="#/action-1" key={index}>{item.title}</Dropdown.Item>);
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

