import React from 'react';

import { createContext } from 'react';

const DataTableContext = createContext(null);

export { DataTableContext }

const DataTableChildContext = createContext(null);

export { DataTableChildContext }


const tableSettings = {
    visibleColumnsIndexArr:[],
    hiddenColumnsIndexArr:[]
};

export { tableSettings  }


const DataTableState  =  ( props ) => {

return  (
    <DataTableContext.Provider value={props.values}>
        {props.children}
    </DataTableContext.Provider>
)

}


export { DataTableState };

