// tslint:disable:max-line-length
import React, { ReactNode } from "react";
import { ButtonToolbar, Col, Form, Nav, Card, Row, ToggleButton, ButtonGroup, OverlayTrigger, Tooltip, Button, Tab, Container, Table, Accordion, Popover } from "react-bootstrap";

import CollapseCard from "./CollapseCard"

import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Dropzone from "react-dropzone";

import SchemaController, { IColumnElem, IEmbedObjectOptions, ISchemaModalProps, IUiSchemaElemArgs, IUiSchemaSetValueResult, registeredExtensionComponents, registeredExtensionHandlers } from "./SchemaController";
import { IJsonSchemaObject, IUiSchema, IUiSchemaPanelOptions } from "./UiJsonSchemaTypes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCircleCheck, faCircleXmark, faCircleExclamation, faCog, faCopy, faDownload, faTrashAlt, faBell, faRectangleTerminal,
		 faFileArrowDown, faTowerBroadcast, faAdd, faPaperPlane, faUnlock, faFileImport, faStop, faPlay, faLock } from '@fortawesome/pro-regular-svg-icons';

import { evalExpr, evalString, getPathAndKey, IExprObjects, proxyClone, updateConditionalSchema, verificationDescription } from "./SchemaTools";
// Controllers

import LazyLocaleDatePicker from "./LazyLocaleDatePicker";
import SchemaForm from "./SchemaForm";
import SchemaFormAceEdit from "./SchemaFormAceEdit";
import SchemaLayout, { ISchemaLayoutRef } from "./SchemaLayout";
import { HoverOverlay } from "./HoverOverlay";
import { isMobile } from "react-device-detect";







// safeSplit() breaks down a comma separated string, where each element can be a string with ', " or `.
function safeSplit(orgStr: string) {

	const args = [];
	let str = orgStr.trim();

	while (str) {
		const firstLetter = str.substring(0, 1);

		if (firstLetter === "'" || firstLetter === '"' || firstLetter === "`") {
			const end = str.indexOf(firstLetter, 1);
			if (end >= 0) {
				args.push(str.substring(1, end));
			} else {
				throw new Error("Expected closing " + firstLetter);
			}
			str = str.substring(end + 1).trim();
			if (str.startsWith(",")) {
				str = str.substring(1).trim();
			} else if (str !== "") {
				throw new Error("Expected , or end of string");
			}
		} else {
			const next = str.indexOf(",");
			args.push(next >= 0 ? str.substring(0, next) : str);
			str = next >= 0 ? str.substring(next + 1).trim() : "";
		}
	}
	return args;
}


export default class SchemaPanel extends SchemaController {

	loadedPanels: {
		[key: string]: boolean;
	} = {};
	formInnerState: {
		[key: string]: any;
	} = {};

	// parameters for handling the highlighting of the unlock button
	unlockDivRef = React.createRef<HTMLDivElement>();
	overlayRef = React.createRef<HTMLDivElement>();
	highlightValue = 0;
	highlightTarget = 0;
	highlightTimer: NodeJS.Timeout | undefined;

	layoutRef = React.createRef<ISchemaLayoutRef>();
	updateLayoutTimer: NodeJS.Timer | undefined = undefined;


    constructor(props: ISchemaModalProps) {
		super(props);

		this.state = {

			...this.state,
			componentHandlers: {
				// register our type handlers.
				boolean: this.addBoolean,
				number: this.addInput,
				integer: this.addInput,
				string: this.addInput,
				"old-password": this.addInput,
				"new-password": this.addInput,
				hex: this.addInput,
				radio: this.addRadioButton,
				checkbox: this.addCheckbox,
				select: this.addDropdown,
				"creatable-select": this.addDropdown,
				html: this.addShowHtml,
				text: this.addShowText,
				date: this.addDate,
				image: this.addShowImage,
				separator: this.addSeparator,
				"object-json": this.addInput,
				button: this.addButton,
				"button-small": this.addButton,
				table: this.addTable,
				iframe: this.addIFrame,
				aceedit: this.addInput,
				"aceedit-object-json": this.addInput,

				...registeredExtensionHandlers,
				...this.props.extensions,
			},

			textMarkersHandlers: {
				ok:       (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faCircleCheck} style={style} />],
				warning:  (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faCircleExclamation} style={style} />],
				error:    (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faCircleXmark} style={style} />],
				cog:      (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faCog} style={style} />],
				copy:     (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faCopy} style={style} />],
				download: (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faDownload} style={style} />],
				trash:    (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faTrashAlt} style={style} />],
				bell:     (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faBell} style={style} />],
				terminal: (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faRectangleTerminal} style={style} />],
				plane:    (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faPaperPlane} style={style} />],
				add:      (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faAdd} style={style} />],
				import:   (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faFileImport} style={style} />],
				"file-arrow-down": (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faFileArrowDown} style={style} />],
				"tower-broadcast": (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faTowerBroadcast} style={style} />],
				play:    (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faPlay} style={style} />],
				stop:    (key, style) => [<FontAwesomeIcon key={key} fixedWidth={true} icon={faStop} style={style} />],

				...this.props.textMarkerExtensions,
			},
        };

	}




	public highlightUnlock(target = 1) {

		if (target === this.highlightTarget) { return; }
		this.highlightTarget = target;
		
		const update = () => {

			clearTimeout(this.highlightTimer);
			this.highlightTimer = undefined;

			if (this.highlightTarget !== this.highlightValue && this.overlayRef?.current && this.unlockDivRef?.current) {

				this.highlightValue += (this.highlightValue > this.highlightTarget ? -1 : 1) * 0.2;
				this.highlightValue  = Math.max(0, Math.min(1, this.highlightValue));
	
				this.overlayRef.current.style.display      = this.highlightValue > 0 ? "block" : "none";
				this.overlayRef.current.style.background   = `rgba(0,0,0,${this.highlightValue / 2})`;
				this.unlockDivRef.current.style.zIndex     = this.highlightValue > 0 ? "99999" : "1";
				this.unlockDivRef.current.style.background = `rgba(255,255,255,${this.highlightValue})`;
	
				if (this.highlightValue !== this.highlightTarget) {
					this.highlightTimer = setTimeout(update, 20);
				} else {
					// After finishing the animation, if the unlock is not highlighted, we schedule the
					// return to normal immediately
					if (this.highlightTarget) {
						this.highlightTimer = setTimeout(() => this.highlightUnlock(0), 1000);
					}
				}
			}		
		}

		update();
	}



	public updateLayout = (delay?: number) => {
		delay = delay || 50;
		clearTimeout(this.updateLayoutTimer as any);
		this.updateLayoutTimer = setTimeout(() => {
			if (this.layoutRef.current) {
				(this.layoutRef.current as any).reLayout();
			}
		}, delay || 50);
	}



	// convertTextMarkers receive the content of a [[]] block. It parse the content, which is a
	// comma separated list.
	public convertTextMarkers(marker: string,  args: IUiSchemaElemArgs | null, key: string): ReactNode[] {

		const attr = safeSplit(marker);
		let type: string = attr[0];
		let objects: IExprObjects;
		const lib = this.state.lib;

		if (args?.objects) {
			objects = args?.objects;
		} else {
			const {values, jsonSchema, oldValues, objectErrors } = this.state;
			objects = { values, errors: objectErrors, jsonSchema, oldValues, uiSchema: jsonSchema.$uiSchema || {} }
		}

		if (type === "@cond") {
			if (attr.length < 3) {
				this.log("@cond length too short ", attr);
				return [];
			}

			try {
				const { fullkey, value, error, elem, readOnly } = args || {};
				if (evalExpr(attr[1], lib, objects, { fullkey, value, error, readOnly, schema: elem })) {
					attr.splice(0, 2);
					type = attr[0];
				} else {
					return [];
				}
			} catch (e: any) {
				this.log("@cond fail", e.message);
				return [];
			}
		}

		if (type === "@br") { return [<br />]; }


		function getstyle(arg: string) {
			const style: any = {};
			if (arg && arg.trim()) {
				const styleList = arg.trim().split(" ");
				for (const styleElem of styleList) {
					if (styleElem.trim()) {
						const styleComp = styleElem.trim().split(":");
						if (styleComp.length > 1) {
							style[styleComp[0].trim()] = styleComp[1].trim();
						} else {
							style["color"] = styleElem.trim();
						}
					}
				}
			}
			return style;
		}


		// extract 
		const tag = type.endsWith("-expr") ? type.substring(0, type.length - 5) : type;
		const { fullkey, value, error, elem, readOnly } = args || {};
		const scope = { fullkey, value, error, readOnly, schema: elem };


		// Here we process block like this:
		// [[@md, text to show including potential commas]]
		if (["@md"].includes(tag)) {

			const rawbody = attr[attr.length - 1];
			const body = !type.endsWith("-expr")
						? evalString(rawbody, lib, objects, scope)
						: evalExpr(rawbody, lib, objects, scope);

			switch (tag) {
			case "@md": {
					const Markdown = registeredExtensionComponents["Markdown"];
					return Markdown ? [<Markdown key={key} markdown={body+""}/>] : [<p key={key}>{body+""}</p>];
				}
			}
		}

		// Here we process block like this:
		// [[@div, classnames, styles, 'text to show']]
		//
		//   The class names are directly the space separated class name from e.g. bootstrap or any custom classes that may be defined, e.g.:
		//		badge fw-light
		//
		//   The styles are optional. The format for the styles is like this:
		// 		color:red width:10px
		//   note, if the style is not a key/value pair separated by colon it is assumed the style is a color.
		//
		// Examples:
		//    [[@div, badge, color:red, this will be in a box]]
		//    [[@div-expr, badge, color:red, objects.values.showme]]
		//    [[@div, badge, color:red, '[[bell]']]
		//    [[@div, badge, color:red, '{{objects.values.showme}}']]
		//    [[@img, badge, color:red, 'https://image.com/image.png']]
		//    [[@img, badge, color:red, '({ src: https://image.com/image.png', width: "10px"})' ]]
		//

		const tags = ["@div", "@p", "@small", "@span", "@img"];
		if (tags.includes(tag)) {

			if (attr.length < 3 || attr.length > 4) {
				this.log(type + " length wrong ", attr);
				return [];
			}
			const className = attr[1];
			const rawbody = attr[attr.length - 1];
			const style = getstyle(attr.length === 4 ? attr[2] : "");

			const body = !type.endsWith("-expr")
						? evalString(rawbody, lib, objects, scope)
						: evalExpr(rawbody, lib, objects, scope);

			switch (tag) {
			case "@div":   return [<div   key={key} style={style} className={className}>{this.formatText(body+"", args, key + ".sub")}</div>];
			case "@p":     return [<p     key={key} style={style} className={className}>{this.formatText(body+"", args, key + ".sub")}</p>];
			case "@small": return [<small key={key} style={style} className={className}>{this.formatText(body+"", args, key + ".sub")}</small>];
			case "@span":  return [<span  key={key} style={style} className={className}>{this.formatText(body+"", args, key + ".sub")}</span>];
			case "@img": {
					const imgargs = Object.assign({}, typeof body === "object" ? body : { src: body });
					return [<img key={key} style={style} className={className} {...imgargs} />]
				}
			}
		}


		// [[@action, text, tooltip, action in javascript ]]
		// The @action block is used to insert a link style action button anywhere in the text.
		//   The first argument is the text shown. This can contain sub block for symbols, etc.
		//   The second argument is the tooltip shown when hovering over the link. Can contain subblocks
		//   Finally the last argument is the javscript expression to evaluate when clocking on the link. The returned
		//   values are used similarely to any setValue functions.

		if (type === "@action") {

			if (attr.length !== 4) {
				this.log("@action length wrong ", attr);
				return [];
			}

			const text = evalString(attr[1], lib, objects, scope) + ""
			const textElems = this.formatText(text, args, key + ".sub");
			const tooltip = evalString(attr[2], lib, objects, scope) + ""
			const expr = attr[3];

			return [
				<a href="#" key={key} title={tooltip} onClick={(ev) => {
					this.log("ok click action");
					ev.stopPropagation();
					try {
						const targetObj: IUiSchemaSetValueResult = evalExpr(expr, lib, objects, scope);
						this.updateValues(targetObj, "", args?.fullkey || key)
					} catch (e) {
						this.log("@action failed", e);
					}
					ev.preventDefault();		// Avoid that href propagation
				}}>{textElems}</a>
			];
		}

		return (this.state.textMarkersHandlers[type] && 
		        this.state.textMarkersHandlers[type](key, getstyle(attr[1])) as ReactNode[]) || [];
	}



	private addCheckbox = (args: IUiSchemaElemArgs) => {

		const { objects, value, readOnly, uiElem, elem, fullkey } = args;
		const { lib } = this.state;

		const multi = uiElem?.multiSelect;

		// Render ENUM as a RADIO button set
		const vals: JSX.Element[] = [];
		if (args.enums) {
			for (const en of args.enums) {

				let label = (args.enumLabels && args.enumLabels[en + ""]) || (en + "") ;
				if (uiElem?.enumFormatString) {
					const formatstr = evalString(uiElem.enumFormatString,
													lib, objects, { fullkey, value: { value: en, label }, readOnly, schema: elem }) + "";
					label = formatstr ? this.formatText(formatstr, {...args, value: { value: en, label }}) : null;
				}

				const type = multi ? "checkbox" : "radio";
				vals.push(
					<Form.Check type={type} key={fullkey + en}>
						<Form.Check.Input
							type={type}
							checked={multi ? value?.includes(en) || false : value === en}
							disabled={readOnly}
							onChange={() => {
								const res = multi ? [] : en;
								if (multi) {
									for (const e of args.enums) {
										if ((e === en && !value.includes(e)) ||
											(e !== en && value.includes(e))) { res.push(e); }
									}
								}
								!readOnly && args.update({ value: res });
							}}
						/>
						<Form.Check.Label>{label}</Form.Check.Label>
					</Form.Check>
				);
			}
		} else {
			vals.push(
				<Form.Check type="checkbox" key={fullkey} >
					<Form.Check.Input
						type="checkbox"
						checked={!!value}
						disabled={readOnly}
						onChange={() => { !readOnly && args.update({ value: !value }); }}
					/>
				</Form.Check>
			);
		}

		return this.embedObject(args,
			<div id={fullkey} className="mb-2" onClick={() => console.log("clicking")}>
					{vals}
			</div>, { useFlex: true }
		);
	};



	private addRadioButton = (args: IUiSchemaElemArgs) => {

		const { objects, error, value, readOnly, key, uiElem, elem, fullkey } = args;
		const { lib } = this.state;
		const multi = uiElem?.multiSelect;

		// Render ENUM as a RADIO button set
		const vals: JSX.Element[] = [];
		for (const en of args.enums) {

			let label = (args.enumLabels && args.enumLabels[en + ""]) || (en + "") ;
			if (uiElem?.enumFormatString) {
				const formatstr = evalString(uiElem.enumFormatString,
												lib, objects, { fullkey, value: { value: en, label }, readOnly, schema: elem }) + "";
				label = formatstr ? this.formatText(formatstr, {...args, value: { value: en, label }}) : null;
			}

			vals.push(
				<ToggleButton key={en}
					type="radio"
					disabled={readOnly}
					value={en}
					checked={multi ? value?.includes(en) || false : value === en}
					variant={error ? "outline-danger" : "outline-secondary"}
					onClick={() => {
						const res = multi ? [] : en;
						if (multi) {
							for (const e of args.enums) {
								if ((e === en && !value.includes(e)) ||
								    (e !== en && value.includes(e))) { res.push(e); }
							}
						}
						!readOnly && args.update({ value: res });
					}}
				>
					{label}
				</ToggleButton>
			);
		}

		return this.embedObject(args,
			<ButtonToolbar >
				<ButtonGroup id={key} className="mb-2">
					{vals}
				</ButtonGroup>
			</ButtonToolbar>, { useFlex: true }
		);
	};


	private addSeparator = (args: IUiSchemaElemArgs) => {

		const { title, fullkey } = args;
		return <div key={fullkey}>
			{title && <h2 className="schema-modal-separator-title">{this.formatText(title, args)}</h2>}
			<hr className="schema-modal-separator-hr" />
		</div>;
	};



	private addBoolean = (args: IUiSchemaElemArgs) => {

		const { value, readOnly, key } = args;

		return this.embedObject(args,
			<Form.Switch
				id={"switch_" + key}
				checked={value || false}
				disabled={readOnly}
				onChange={(e) =>  {!readOnly && args.update({ value: !value }); }}
			/>, { useFlex: true }
		);
	};


	private addButton = (args: IUiSchemaElemArgs) => {

		const { value, readOnly, key, values, uiElem } = args;
		const size = uiElem.type === "button-small" ? "sm" : "";

		return this.embedObject(args, (
			<Button variant="outline-dark" key={key} disabled={readOnly} size={size as any}
				id={"switch_" + key}
				onClick={() =>  {!readOnly && args.update({ value: !values[key] }); }}
			>
				{this.formatText(value, args)}
			</Button>),
			{ useFlex: true}
		);
	};

	private addIFrame = (args: IUiSchemaElemArgs) => {

		const { value, fullkey, uiElem } = args;
		const src = value.src;
		const width = value.width != null ? value.width : "100%";
		const height = value.height != null ? value.height :
				uiElem.multiLine != null ? uiElem.multiLine * 12 + "px" : undefined;

		return this.embedObject(args,
			(<iframe title={fullkey} key={"iframe_" + fullkey} src={src} width={width} height={height}/>),
			{ useFlex: true, isContainer: true });
	};


	private addTable = (args: IUiSchemaElemArgs) => {

		const { value } = args;

		const header: string[] = value.header;
		const body: string[][] = value.body;

		this.log("header", header)
		this.log("body", body)


		const tableJsx = (
			<Table>
				<thead className="thead-light">
					<tr>{header.map((a, i) => <th key={"idx" + i}>{a}</th>)}</tr>
				</thead>
				<tbody>
					{body.map((row, i) => (<tr key={"row" + i}>{row.map((col, i) => <td key={"col" + i}>{col}</td>)}</tr>))}
				</tbody>
			</Table>
		)

		return this.embedObject(args, tableJsx, { useFlex: true});
	};







	private addDropdown = (args: IUiSchemaElemArgs) => {

		const { objects, value, readOnly, uiElem, error, elem, fullkey, type } = args;
		const { lib } = this.state;

		const multi = uiElem && uiElem.multiSelect ? true : false;
		const clearable = uiElem && uiElem.clearable ? true : false;


		// Use DROPDOWN
		const selectOptions: any[] = [];
		let entryValue: any = multi ? [] : null;
		for (const en of args.enums || []) {
			const entry = { value: en, label: args.enumLabels[en + ""] != null ? args.enumLabels[en + ""] : en + "" };
			selectOptions.push(entry);

			if (multi && Array.isArray(value) && value.includes(en)) { entryValue.push(entry); }
			if (!multi && en === value) { entryValue = entry; }
		}

		const selectStyles = { menu: (styles: any) => ({ ...styles, zIndex: 999 }) };

		const formatOptionLabel: ((data: any) => ReactNode) | undefined = uiElem?.enumFormatString ? (data: any) => {
			const formatstr = evalString(uiElem?.enumFormatString,
											lib, objects, { fullkey, value: data, readOnly, schema: elem }) + "";
			return formatstr ? this.formatText(formatstr, {...args, value: data}) : null;
		} : undefined;

		return this.embedObject(args,
			type === "select" ?
			<Select
				className={error ? "has-error" : ""}
				isDisabled={readOnly}
				options={selectOptions}
				isClearable={clearable}
				placeholder={uiElem.placeholder}
				styles={selectStyles}
				isMulti={multi}
				formatOptionLabel={formatOptionLabel}
				backspaceRemovesValue={true}
				onChange={(event) => args.update({ value: multi ? event && event.map((a: any) => a.value) : event && event.value })}
				value={entryValue}
			/> :
			<CreatableSelect
				className={error ? "has-error" : ""}
				isDisabled={readOnly}
				options={selectOptions}
				isClearable={clearable}
				placeholder={uiElem.placeholder}
				isMulti={multi}
				styles={selectStyles}
				formatOptionLabel={formatOptionLabel}
				backspaceRemovesValue={true}
				onChange={(event) => args.update({ value: multi ? event && event.map((a: any) => a.value) : event && event.value })}
				value={entryValue}
			/>
		);
	};


	private addShowImage = (args: IUiSchemaElemArgs) => {

		const { value, key, uiElem } = args;

		if (!value) { return null; }

		const src    = typeof value === "string" ? value : value.src;
		const width  = typeof value === "object" && value.width != null ? value.width : undefined;
		const height = typeof value === "object" && value.height != null ? value.height :
						uiElem.multiLine != null ? uiElem.multiLine * 12 + "px" : undefined;

		return this.embedObject(args,
			<div><img className="img-thumbnail ow-device-profile-image" key={key} src={src} width={width} height={height} /></div>
		);
	};




	private addShowHtml = (args: IUiSchemaElemArgs) => {

		const { value, uiElem, key } = args;
		if (uiElem && uiElem.colBehaviour === "fullwidth") {
			return <div className="mb-2" key={key} dangerouslySetInnerHTML={{__html: value}} />
		} else {
			return this.embedObject(args, <div dangerouslySetInnerHTML={{__html: value}} />, { isContainer: true });
		}
	};


	private addShowText = (args: IUiSchemaElemArgs) => {

		const { value } = args;
		return this.embedObject(args,
			<div className="schema-modal-textview" >{this.formatText(value, args)}</div>, { isContainer: true }
		);
	};



	private addDate = (args: IUiSchemaElemArgs) => {

		const { value, readOnly, key } = args;
		const date = (value && new Date(value)) || null;

		return args.embedObject(
			<LazyLocaleDatePicker
				key={key}
				disabled={readOnly}
				showTimeSelect
				selected={date}
				timeFormat="p"
				dateFormat="Pp"
				timeIntervals={15}
				onChange={(a: any) => args.update({ value: new Date(a).toJSON() })}
			/>,
			{ useFlex: true }
		);
	};


private addInput = (args: IUiSchemaElemArgs) => {

	const debugKey = "";
	const { objects, value, elem, uiElem, fullkey, readOnly, error } = args;
	const { lib } = this.state;
	const type = uiElem.type || (typeof elem.type === "string" ? elem.type  : "");



	// The form handling is pretty complex due to performance issues.
	//
	// The issue is that the "normal" react model using a onChange function paired with the value input to the component
	// doesn't work well for form input as this will cause a complete rerender at schema level for each keypress.
	// To avoid that, the SchemaForm component maintain its own value state, but callback to here for each input. The
	// input can be filtered and error checked, etc. and the value is passed back to the component. The global state of
	// the schema however is not updated on each keypress. Instead a 500ms timer is maintained to aggregate fast key types
	// and update the state only after finishing typing.
	//
	// The following out-of-state variables are maintained:
	// this.formInnerState[fullkey + "$text"]   current content of input form
	// this.formInnerState[fullkey + "$value"]  current value (i.e. integer etc) of content of input, ready to be set to state
	// this.formInnerState[fullkey + "$lastSetValue"]   last value that was set to state, meaning what should be the current state
	// this.formInnerState[fullkey + "$context"]        counter updated on every update. Allow the SchemaForm to detect update
	// this.formInnerState[fullkey + "$time"]           time used to update every 500ms.
	//
	this.formInnerState[fullkey + "$context"] = (this.formInnerState[fullkey + "$context"] || 0) + 1;

	const debugInnerState = (where: string) => {
		if (debugKey && fullkey === debugKey) {
			console.log(where, fullkey, value, this.formInnerState[fullkey + "$text"],
						this.formInnerState[fullkey + "$value"], this.formInnerState[fullkey + "$lastSetValue"],
						this.formInnerState[fullkey + "$context"]);
		}
	}
	const safeIsEq = (a: any, b: any) => {
		if (typeof a !== typeof b) { return false; }
		if (typeof a === "number" && isNaN(a) && isNaN(b)) { return true; }	// this is the weird case
		return a === b;
	}
	const safeParseNumber = (str: string, radix?: number) => {
		let res = radix ? parseInt(str, radix) : parseFloat(str);
		return isNaN(res) ? 0 : res;
	}
	const safeToString = (value: any, radix?: number) => {
		if (typeof value === "number") { return isNaN(value) ? "" : (radix ? value.toString(radix) : value.toString()) }
		return (value || "").toString();
	}


	debugInnerState("entry");
	if (this.formInnerState[fullkey + "$text"] != null && (
			this.formInnerState[fullkey + "$lastSetValue"] == null ||
			!safeIsEq(this.formInnerState[fullkey + "$lastSetValue"], value))) {
		clearTimeout(this.formInnerState[fullkey + "$timer"]);
		this.formInnerState[fullkey + "$timer"] = null;
		this.formInnerState[fullkey + "$text"] = null;
		this.formInnerState[fullkey + "$lastSetValue"] = null;

		debugInnerState("clear");
	}

	let cnvVal: string = value == null ? "" 
						: type === "hex" ? safeToString(value, 16)
							: type.endsWith("object-json") ? JSON.stringify(value, null, 4)
								: safeToString(value);
	let strVal: string = this.formInnerState[fullkey + "$text"] != null
						? this.formInnerState[fullkey + "$text"]
						: cnvVal;

	const nullValue = () => {
		return uiElem.treatEmptyAs ? evalExpr(uiElem.treatEmptyAs, lib, objects, { fullkey, value, error, readOnly, schema: elem }) : null;
	}

	const onTextChange = (text: string) => {

		if (readOnly) { return null; }

		const type = uiElem.type || (typeof elem.type === "string" ? elem.type  : "");
		const stringVal: string = text != null ? text + "" : "";
		let   targetVal: string | number | null;

		if (uiElem.inputFilterPattern && !stringVal.match(new RegExp(uiElem.inputFilterPattern))) {
			return null;
		}
		if (typeof elem.maxLength === "number" && stringVal.length > elem.maxLength) { return null; }

		if (type === "integer" && stringVal !== "" && !stringVal.match(/^[-+]?[0-9]*$/)) { return null; }
		if (type === "number"  && stringVal !== "" && !stringVal.match(/^[-+]?[0-9]*[.]?[0-9]*[eE]?[-+]?[0-9]*$/)) { return null; }
		if (type === "hex"     && stringVal !== "" && !stringVal.match(/^[0-9a-fA-F]*$/)) { return null; }

		const fKey = fullkey.replace(/[.]/g, "/");
		this.innerStates[fKey] = {...this.innerStates[fKey], modified: true };
		
		delete (this.innerStates[fKey] as any)?.error;	// FIXME: type hack

		if (type === "hex") {
			targetVal = stringVal === "" ? nullValue() : safeParseNumber(stringVal, 16);
		} else if (type === "integer" || type === "number") {
			targetVal = stringVal === "" ? nullValue() : safeParseNumber(stringVal);
		} else if (type.endsWith("object-json")) {
			try {
				targetVal = JSON.parse(stringVal);
			} catch (e) {
				targetVal = {} as any;
				this.innerStates[fKey] = {...this.innerStates[fKey], error: "Invalid JSON" };
			}
		} else {
			targetVal = (stringVal === "" && uiElem.treatEmptyAs) ? nullValue() : stringVal;
		}

		// when the user is typing 
		if (typeof targetVal === "number" && isNaN(targetVal)) { targetVal = 0;}


		// Doing delayed update
		this.formInnerState[fullkey + "$text"]  = text;
		this.formInnerState[fullkey + "$value"] = targetVal;


		debugInnerState("update");
		clearTimeout(this.formInnerState[fullkey + "$timer"]);
		this.formInnerState[fullkey + "$timer"] = setTimeout(() => {
			this.formInnerState[fullkey + "$timer"] = null;
			this.formInnerState[fullkey + "$lastSetValue"] = this.formInnerState[fullkey + "$value"];
			debugInnerState("flush");
			args.update({ value: this.formInnerState[fullkey + "$value"] });
		}, 500)

		return text;
	};
	const onBlur = () => {
		if (this.formInnerState[fullkey + "$timer"]) {
			clearTimeout(this.formInnerState[fullkey + "$timer"]);
			this.formInnerState[fullkey + "$timer"] = null;
			this.formInnerState[fullkey + "$text"] = null;			// FIXME: why clear this??

			this.formInnerState[fullkey + "$lastSetValue"] = this.formInnerState[fullkey + "$value"];
			debugInnerState("blur");

			args.update({ value: this.formInnerState[fullkey + "$value"] });
		}
	}

	return this.embedObject(args,
		type.startsWith("aceedit") ?
			<SchemaFormAceEdit
				key={"input-" + fullkey}
				args={args}
				onBlur={onBlur}
				value={strVal}
				onTextChange={onTextChange}
				context={this.formInnerState[fullkey + "$context"]}
			/>
			:
			<SchemaForm
				key={"input-" + fullkey}
				args={args}
				onBlur={onBlur}
				value={strVal}
				onTextChange={onTextChange}
				context={this.formInnerState[fullkey + "$context"]}
			/>
	);
};


	// When Dropfile is used, this function is called whenever the dropfile action is used.
	public onDropFile = (files: File[], args: IUiSchemaElemArgs, obj: JSX.Element) => {
		if (!files || files.length < 1) { return; }
		if (files.length > (args.dropFile?.maxNumFiles || 1)) {
			// Toast error
			return;
		}

		const loadedFiles: Array<{ name: string; content: string; }> = [];
		const readers: FileReader[] = [];

		for (const file of files) {

			const name = file.name;
			const maxSize = args.dropFile?.maxFileSize || 300000;
			if (file.size > maxSize) {
				// toast
				this.props.showMessage("error", "File is too big (max size is " + maxSize + " bytes)")
				return;
			}
			// Now let's read the file

			const reader = new FileReader();
			readers.push(reader);

			reader.onabort = () => console.log('file reading was aborted')
			reader.onerror = () => console.log('file reading has failed')
			reader.onload = () => {

				loadedFiles.push({ name, content: reader.result as string });
				this.log("Finished reading file", name);

				if (loadedFiles.length === readers.length) {

					try {
						const { lib } = this.state;
						const { key, keypath } = getPathAndKey(args.fullkey)
						const { objects, dropFile, readOnly } = args;

						const targetObj = evalExpr(dropFile?.onDropFile || "", lib, objects, { fullkey: args.fullkey, value: loadedFiles, readOnly });
						this.updateValues(targetObj, keypath, key + "");
					} catch (e) {
						this.log(e);
					}
				}
			}
			reader.readAsDataURL(file);
		}
	}





	public embedObject(args: IUiSchemaElemArgs, obj: JSX.Element, options?: IEmbedObjectOptions) {

		const { elemReadOnly, readOnly, key, fullkey, required, uiElem, elem, title, description, helpLink, error } = args;

		const titleLayout = uiElem?.titleLayout       || args.layoutOptions?.titleLayout;
		const descLayout  = uiElem?.descriptionLayout || args.layoutOptions?.descriptionLayout;
		let marginClass = "mb-3";

		if (titleLayout === "none" && descLayout?.startsWith("popup")) { marginClass = ""; }

		const titleElem = title ? this.formatText(title, args) : null;
		const descriptionElem  = description  ? this.formatText(description, args) : null;
		const moreInfoText = "More Info...";
		const descElem = !helpLink ? descriptionElem :
				<>
					{descriptionElem}
					{helpLink ? 
						this.props.helpLinkCallback 
						    ? (<><br /><a href={helpLink} onClick={(e) => { e.preventDefault(); this.props.helpLinkCallback(helpLink) }} >{moreInfoText}</a></>)
							: (<><br /><a href={helpLink} target="_blank" rel="noopener noreferrer">{moreInfoText}</a></>) : null}
				</>;
		const helpTip = descLayout?.startsWith("popup") && descElem ? 
			(<>
				<Popover.Header>Help</Popover.Header>
				<Popover.Body>
					{descElem}
				</Popover.Body>
			 </>) : null;

		const errFeedbackObj = error && error.err && (
			typeof error.err === "string" && error.err.trim()
				? <small className="schema-modal-error-text"><FontAwesomeIcon icon={faCircleExclamation} /> {this.formatText(error.err.trim(), args)}</small>
				: <Form.Control.Feedback type="invalid" />
		);

		const inputInfo = this.props.debug && <div><Form.Text>{key + ": " + verificationDescription(elem)}</Form.Text></div>;

		// If the onDropFile handler is provided, we encapsulate the object in a DropFile 
		if (args.dropFile && !args.readOnly) {
			const innerObj = obj;
			obj = (<Dropzone noClick={args.dropFile.openFileDialogOnClick !== true} onDrop={files => this.onDropFile(files, args, obj)}>
				{({getRootProps, getInputProps}) => (
					<section>
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							{innerObj}
						</div>
					</section>
				)}
			</Dropzone>);
		}


		// If this object is a readonly and the whole schema is in readonly mode, we put an outer div with a
		// onClick() function that intercept mouse clicks and popup the highlight of the unlock button.
		if (readOnly && !elemReadOnly && this.props.readOnly && !options?.isContainer) {
			obj = <div key={fullkey} onClick={() => this.highlightUnlock()}>{obj}</div>;
		}


		// null; //error && error.err && <Form.Control.Feedback type="invalid">{error.err}</Form.Control.Feedback>;

		if (titleLayout === "left") {

			const titleWidth = Math.round((uiElem.titleWidthPercent != null 
										? uiElem.titleWidthPercent
										: args.layoutOptions?.titleWidthPercent || 40) / 100 * 12);
			const bodyWidth = 12 - titleWidth;

			// Inline layout put title and component on same line
			return (
				<Form.Group key={fullkey} controlId={"label" + fullkey} as={Row}  className={"mb-2"}>
					<Form.Label column sm={titleWidth} className="text-right col-10"  >
						{titleElem}
						{required && <span style={{color:"red"}}>*</span>}
						{helpTip && <HoverOverlay
							big={descLayout==="popup-big"}
							overlay={helpTip}
						>
							<span> <FontAwesomeIcon icon={faInfoCircle} /></span>
  						</HoverOverlay>}
					</Form.Label>

					<Col sm={bodyWidth} className={options?.useFlex ? "d-flex justify-content-end col-2" : "col-sm-" + bodyWidth}>
						{descElem && descLayout === "top" && <Form.Text>
							<small className="new_style_help_block_color" >{descElem}</small>
						</Form.Text>}

						{obj}
						{inputInfo}
						{errFeedbackObj}

						{descElem && descLayout === "bottom" && <Form.Text>
							<small className="new_style_help_block_color" >{descElem}</small>
						</Form.Text>}
					</Col>

				</Form.Group>
			);

		} else {

			return (
				// stack form layout put title above component on separate line
				<Form.Group key={fullkey} controlId={"label" + fullkey} className={marginClass} /* validationState={error ? "error" : null}*/ >

					{titleElem && titleLayout === "top" && <Form.Label className="text-right">
						{titleElem}
						{required && <span style={{color:"red"}}>*</span>}
						{helpTip && <HoverOverlay
							big={descLayout==="popup-big"}
							overlay={helpTip}
						>
							<span> <FontAwesomeIcon icon={faInfoCircle} /></span>
  						</HoverOverlay>}
					</Form.Label>}

					{descElem && descLayout === "top" && <Form.Text>
							<small className="new_style_help_block_color" >{descElem}</small>
					</Form.Text>}

					{obj}
					{inputInfo}
					{errFeedbackObj}

					{descElem && descLayout === "bottom" && <Form.Text>
						<small className="new_style_help_block_color" >{descElem}</small>
					</Form.Text>}

				</Form.Group>
			);
		}
	}



	public addTabElem(tabKey: string, isActive: boolean, hasError: boolean, title: Array<JSX.Element|string>, onClick: () => void) {
		return (
			<Nav.Item key={tabKey}>
				<Nav.Link
					className={(isActive ? "active" : "") + (hasError ? " has-error" : "")}
					eventKey={tabKey}
					onClick={onClick}
				>
					{title}
					{hasError ? " " : null}
					{hasError ? <FontAwesomeIcon style={{color:"red"}} icon={faCircleExclamation} /> : null}
				</Nav.Link>
			</Nav.Item>
		);
	}



	public embedArrayElement(key: string, element: JSX.Element, rem: () => void, add: () => void) {
		// Override in extended class
		return (<tr className="schema-modal-array-element-container">
			<td>{element}</td>
			{(add || rem) && (<td>
				{add && <Button variant="outline-secondary" onClick={add} size="sm">
							<FontAwesomeIcon icon={faAdd} />
				</Button>}
				{rem && <Button variant="outline-secondary" onClick={rem} size="sm">
							<FontAwesomeIcon icon={faTrashAlt} />
				</Button>}
			</td>)}
		</tr>);
	}

	public embedNonObjectArrayElements(elements: JSX.Element[]) {
		<Table>{elements}</Table>
	}


	public embedArrayElementObject(args: IUiSchemaElemArgs, elements: JSX.Element[], type: "embox" | "table" | "accordion",
								   rem: () => void, add: () => void) {

		const {key, fullkey} = args;
		const addBut = add ? (<Button variant="outline-secondary" className="mr-1" onClick={add} size="sm">
								<FontAwesomeIcon icon={faAdd} />
							  </Button>) : null;
		const remBut = rem ? (<Button variant="outline-secondary" onClick={rem} size="sm">
								<FontAwesomeIcon icon={faTrashAlt} />
	  						  </Button>) : null;

		if (type === "embox") {

			return (<Card key={fullkey} className="schema-modal-array-element-object">
				<Card.Header>
					{args.title || "#" + args.key}
					{addBut}
					{remBut}
				</Card.Header>
				<Card.Body>
					{elements}
				</Card.Body>
			</Card>);

		} else if (type === "accordion") {

			return (<Accordion.Item
					key={fullkey}
					eventKey={key}
					className="schema-modal-array-accordion-element-object">
				<Accordion.Header onClick={() => this.updateLayout(200)}>
				<div className="d-flex justify-content-between" style={{width : ' inherit'}}>
					<div>
						{args.title || "#" + args.key}
					</div>
					<div className="mx-2">
						{addBut}
						{remBut}
					</div>
				</div>
				</Accordion.Header>
				<Accordion.Body>
					{elements}
				</Accordion.Body>
			</Accordion.Item>);

		} else {
			const cols = elements.map((elem, idx) => <td key={"col" + idx}>{elem}</td>);
			if (addBut || remBut) { cols.push(<td key="addrow">{addBut}{remBut}</td>); }
			return (<tr key={fullkey}>{cols}</tr>);
		}
	}



	public embedArrayContainer(title: string, fullkey: string, schema: IJsonSchemaObject,
								elements: JSX.Element[], add: (() => void) | null, boxType: "embox" | "table" | "accordion" | "card") {

		const elems = schema.type === "object" 
			? elements
			: <div className="px-2"><Table bordered={false} className="schema-modal-array-container-table"><tbody>{elements}</tbody></Table></div>

		if (boxType === "embox") {

			return (
				<Card key={fullkey} className="schema-modal-array-container">
					{title && <Card.Title>
						{title}
					</Card.Title>}
					<Card.Body>
						{elems}
						{add && <Button variant="outline-secondary" onClick={add} size="sm">
							<FontAwesomeIcon icon={faAdd} />
						</Button>}
					</Card.Body>
				</Card>
			);

		} else if (boxType === "accordion") {

			const acckey = "__acc_" + fullkey.replace(/[/]/g, ".");

			return (
				<div key={fullkey}>
					<Accordion
						className={"schema-modal-array-accordian-container " + (add ? "mb-2" : "mb-3")}
						activeKey={this.state.values[acckey]}
						onSelect={k => this.updateValues({ values: { [acckey]: k }}, "", "" )}
					>
						{elems}
					</Accordion>
					{add && <Button className="mb-3" variant="outline-secondary" onClick={add} size="sm">
						<FontAwesomeIcon icon={faAdd} />
					</Button>}
				</div>
			);

		} else if (boxType === "table") {

			const header: JSX.Element[] = [];

			const { lang } = this.props;
			const { values, jsonSchema, oldValues, objectErrors, lib } = this.state;
			const readOnly = !!(this.props.readOnly || this.state.jsonSchema?.$uiSchema?.modal?.readOnly);
			const objects = { values, errors: objectErrors, jsonSchema, oldValues, uiSchema: jsonSchema.$uiSchema || {} }
	
			for (const eKey of Object.keys(schema?.properties || {})) {

				if (!schema.properties || !schema.properties[eKey]) { continue; }

				const efullkey = eKey;	// FIXME: add path

				const elem = schema.properties[eKey];
				// const uiElem = (elem || {}).$uiSchemaObject || {};

				const descLayout = "popup";
				const description = evalString((lang && (elem as any)["description[" + lang + "]"]) || elem.description || "",
												lib, objects, { fullkey: efullkey, readOnly, schema: elem }) + "";
				const title       = evalString((lang && (elem as any)["title[" + lang + "]"]) || elem.title || eKey,
												lib, objects, { fullkey: efullkey, readOnly, schema: elem }) + "";

				const titleElem = title ? this.formatText(title, null) : null;
				const descElem  = description  ? this.formatText(description, null)  : null;
				const helpTip   = descLayout === "popup" && descElem ? <Tooltip>{descElem}</Tooltip> : null;
				const required = schema.required?.includes(eKey);

				header.push(<th key={eKey}>
					<Form.Label>
						{titleElem}
						{required && <span style={{color:"red"}}>*</span>}
						{helpTip && <OverlayTrigger
							placement="auto"
							delay={{ show: 250, hide: 400 }}
							trigger={["hover", "focus"]}
							overlay={helpTip}
						>
							<span> <FontAwesomeIcon icon={faInfoCircle} /></span>
  						</OverlayTrigger>}
					</Form.Label>
				</th>);
			}


			return (
				<div>
					<Table key={fullkey} bordered={ false } className="schema-modal-array-container-table">
						<thead><tr>{header}</tr></thead>
						<tbody>{elements}</tbody>
					</Table>

					{add && <Button variant="outline-secondary" onClick={add} size="sm">
							<FontAwesomeIcon icon={faAdd} />
					</Button>}
				</div>
			);

		} else {

			return <div/>

		}
	}



	public addRowWithColumns(numColumns: number, columnsElems: IColumnElem[]) {

		const cols: JSX.Element[] = [];
		const size = 12 / numColumns;
		let   key = "";

		for (const colElem of columnsElems) {
			key = key || (colElem.elem as JSX.Element).key + "_row";
			cols.push(
				<Col key={(colElem.elem as JSX.Element).key + "_col"} sm={size * (colElem.options.width || 1)}>
					{colElem.elem}
				</Col>
			)
		}
		key = key || "keyrow";

		return <Row key={key}>{cols}</Row>;
	}





	public getControlButtons(schema: IJsonSchemaObject, readOnly: boolean, values: any, errors: any, readOnlyState: boolean, 
							 allowDebug: boolean, debug: boolean) {

		const { oldValues, lib } = this.state;
		const valuesProxy = proxyClone(this.state.values, schema);
		const errorsProxy = proxyClone(this.state.objectErrors || {}, schema);
		const objects = { values: valuesProxy, errors: errorsProxy, jsonSchema: schema, oldValues, uiSchema: schema.$uiSchema || {} }
	
		const jsonSchema: IJsonSchemaObject = updateConditionalSchema(schema, valuesProxy || {}, this.state.schemaOptions, lib, objects);

		const uiSchema: IUiSchema = jsonSchema?.$uiSchema || {};
		const hasErrors = Object.keys(errors || {}).length > 0;
		const controlButtons = [];

		let modalReadOnly: boolean | undefined;

		if (uiSchema.modal?.readOnly) {
			if (typeof uiSchema.modal?.readOnly === "boolean") {
				modalReadOnly = uiSchema.modal?.readOnly;
			} else if (typeof uiSchema.modal?.readOnly === "string") {
				try {
					const readOnly = !!this.props.readOnly;
					modalReadOnly = evalExpr(uiSchema.modal.readOnly, lib, objects, { schema, readOnly });
				} catch (e) {}
			}
		}


		if (allowDebug) {
			controlButtons.push(<div className="m-2 float-start" key="debugcheckbox">
				<Form.Group controlId="formBasicCheckbox">
				<Form.Check type="checkbox"
					checked={debug}
					onChange={(v) => this.updateValues({ debug: !debug }, "", "")}
					label="Debug" />
				</Form.Group>
			</div>);
		}



		if (!readOnly && !modalReadOnly && readOnlyState) {
			controlButtons.push(<div ref={this.overlayRef} key="readonlyoverlay" className="schema-modal-overlay"
								   onClick={() => this.highlightUnlock(0)} />);
			controlButtons.push(<div ref={this.unlockDivRef} className="m-2 schema-modal-highlight-button" style={{display : "block ruby"}} key="readonlycontrol">
				{isMobile ? 
				<button
					onClick={() => this.updateValues({ readOnly: false }, "", "")}
					className="btn btn-default">
					<FontAwesomeIcon icon={faLock} size="xs"></FontAwesomeIcon>
				</button> :
				<button
					onClick={() => this.updateValues({ readOnly: false }, "", "")}
					className="btn btn-default">
					<FontAwesomeIcon icon={faUnlock} className="mr-2" size="xs"></FontAwesomeIcon>
					{!isMobile && <small className="horizontal_space">{this.props.localeDictionary.click_to_unlock}</small>}
				</button>
		}
			</div>);
		}

		if (readOnly) {
			controlButtons.push(<div className="m-2" key="readonlycontrol">
				<FontAwesomeIcon icon={faLock} className="mr-2" size="xs"></FontAwesomeIcon>
			</div>);
		}

		  
		if (!(uiSchema.modal?.closeButton === "")) { 
			controlButtons.push(<Button
				key="closebutton"
				onClick={() => this.updateValues({ close: true }, "", "")}
				variant="outline-dark m-2">
					{uiSchema.modal?.closeButton ? (this.parseAndFormatText(uiSchema.modal.closeButton, "cancel")) || "" : this.props.localeDictionary.cancel}
			</Button>);
		}

		if (uiSchema?.modal?.applyButton && !readOnly && !modalReadOnly) {
			controlButtons.push(<Button
				key="applybutton"
				onClick={() => this.updateValues({ apply: true }, "", "")}
				variant="dark "
				disabled={hasErrors || readOnlyState}
			>
				{this.parseAndFormatText(uiSchema.modal.applyButton, "apply")}
			</Button>);
			
		}  

		return controlButtons;
	}



    public render() {

		this.extractState();		// get all proxies
		this.updateLayout(300);		// we trigger an post layout update of the masonry after 300ms

		const ts0 = Date.now();
		const sizeClass = {
			"col-3": "schema-modal-card-width-column3",
			"col-2": "schema-modal-card-width-column2",
			"col-1": "schema-modal-card-width-column1",
		};

		const { lib } = this.state;
		const objects = this.objects;
		const jsonSchema = objects.jsonSchema;
		const uiSchema: IUiSchema = this.state.jsonSchema?.$uiSchema || {};

		const layoutOptions = {...this.props.defaultLayoutOptions, ...uiSchema.layoutOptions };
		const activeTabKey = this.state.activeTab || this.getFirstTabKey() || "";
		const uiTabItems = this.getTabItems(activeTabKey, jsonSchema);
		const readOnly = !!this.props.readOnly;

		// const uiHeader = this.renderSchema("$header", jsonSchema, layoutOptions);
		// const uiBody = this.renderSchema("$body");


		const defPanels: {
			[key: string]: IUiSchemaPanelOptions;
		} = {
			defpanel: {
				title: "",
				cards: Object.keys(uiSchema.cards || {}),
			},
		};

		const tabElems: JSX.Element[] = [];
		const tabview = uiTabItems.length > 0;
		const panels = (tabview ? uiSchema.panels : defPanels) || {};
		let tabContent: JSX.Element | undefined;

		for (const tabkey of Object.keys(panels)) {
			const tab = panels[tabkey];
			const cards: JSX.Element[] = [];
			const tabLayoutOptions = {...layoutOptions, ...tab.layoutOptions};

			if (tabview) {
				if (activeTabKey !== tabkey && !this.loadedPanels[tabkey]) {
					tabElems.push(<Tab.Pane key={tabkey} eventKey={tabkey}><div></div></Tab.Pane>);
					continue;
				}
				this.loadedPanels[tabkey] = true;
			}

			for (const cardkey of tab.cards) {
				if (!uiSchema.cards || !uiSchema.cards[cardkey]) { continue; }

				const card = uiSchema.cards[cardkey];
				if (card.hidden && evalExpr(card.hidden, lib, objects, { readOnly })) { continue; }
				const cardLayoutOptions = {...tabLayoutOptions, ...card.layoutOptions}
				const cardData = this.renderSchema(cardkey, jsonSchema, cardLayoutOptions);

				for (const subCard of cardData.cards) {
					if (subCard.jsxElements.length > 0) {

						if (cardLayoutOptions.cardStyle === "noframe") {
							cards.push(<div key={cardkey} className={"schema-modal-card " + sizeClass[cardLayoutOptions.cardSize]}>
								{subCard.jsxElements}
							</div>);

						} else {

							const collapsible = cardLayoutOptions.cardStyle === "uncollapsed" || cardLayoutOptions.cardStyle === "collapsed";
							const cardState   = objects.values["__cardstate_" + cardkey] ?? cardLayoutOptions.cardStyle === "uncollapsed";
							const showError   = collapsible && !cardState && cardData.hasError;
							const titleText   = card.title && evalString(card.title, lib, objects, { readOnly }) + "";
							const titleObj    = <div>
								{titleText && this.formatText(titleText, null, cardkey)}
								{showError ? " " : ""}
								{showError ? <FontAwesomeIcon style={{color:"red"}} icon={faCircleExclamation} /> : null}
							</div>;
	
							cards.push(
								<CollapseCard id={cardkey} key={cardkey} header={titleObj} body={subCard.jsxElements}
										className={"schema-modal-card schema-item " + sizeClass[cardLayoutOptions.cardSize]}
										state={cardState}
										collapsible={collapsible}
										onFinishAnimation={this.updateLayout}
										onChangeState={() => this.updateValues({ values: { ["__cardstate_" + cardkey]: !cardState }}, "", "")}
								/>
							);
						}
					}
				}
			}

			if (tabLayoutOptions.panelLayout === "masonry") {
				tabContent = (
					<SchemaLayout ref={this.layoutRef} >
						{cards}
					</SchemaLayout>
				);
			} else if (tabLayoutOptions.panelLayout === "stack") {
				tabContent = (<div>{cards}</div>);
			} else {
				tabContent = undefined;
			}

			tabElems.push(
				<Tab.Pane key={tabkey} eventKey={tabkey}>{tabContent}</Tab.Pane>
			);
		}

		this.logTime("render", Date.now() - ts0);

		if (tabview) {

			
			const scrollToTop = () => {
				const scrollElement:any =document.getElementById('schema-tab-modal')
				if(scrollElement){
					scrollElement.scrollTo({
						top: 0,
						behavior: "smooth",
					});
				}				
			}

			// FIXME: The uiTabItems below is of incompatible type

			return (
				<Container fluid>
				<Tab.Container defaultActiveKey={this.getFirstTabKey()} onSelect={(e) => {scrollToTop()}}>
					<Row className="schema-modal-tabs mx-0">
						<Col sm={2} className="px-0">
							<Nav variant="pills" className="flex-sm-column flex-xs-row schema-tab-list">
								{uiTabItems as any}
							</Nav>
						</Col>
						<Col sm={10} className="px-0">
							<Tab.Content className="schema-modal-tabs-content" id="schema-tab-modal">
								{tabElems}
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
				</Container>
			);

		} else {
			return tabContent;
		}

	}	// render()
}