import React, { forwardRef, useCallback, useContext, useImperativeHandle, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDropzone } from 'react-dropzone';
import { Button  } from 'react-bootstrap';
import { strings } from "../../services/Localization";
import PageContent from "../PageContent";
import { ActionType, BulkActionType, ColumnType, OptionType } from '../../components/Common/DataTable/DataTypes';
import { faForward } from "@fortawesome/free-solid-svg-icons";
import { DEFAULT_RECORD_LIMIT } from "../../components/Common/DataTable/DataTableConsts";
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import { IUser } from "../../dassTypes";
import { getAppBase } from "../../utils/consts";
import { DataTableContext } from "../../components/Common/DataTable/DataTableState";
import { toast } from "./../../utils/Toaster";
import {
	faCloudArrowUp
} from '@fortawesome/pro-regular-svg-icons'

declare const constants;
interface IMyFirmwareStates {
    loggedUser: IUser | null;
    editCampaignId: string;
    addCampaign: boolean;
    showAlertModal: boolean;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    camapignid: string;
    selectedAction: string;
    showLoadingDialog: boolean;
    showConfirmDialog: boolean;
    confirmDialogeSettings: {
        title: string;
        description: string;
        actionLabel: string;
        confirmAction: () => void;
    },
    refresh: boolean;
    exportInit: number;

};

const MyFirmwares: React.FC<any> = forwardRef((props,ref) => {
   
    const navigate = useNavigate();
    let { campaignid, tabname } = useParams();
    const [selectedFiles, setSelectedFile] = React.useState([]);
    

    const stateInit = {
        loggedUser: null,
        editCampaignId: "",
        addCampaign: false,
        pageTitle: strings.MY_APPLICATIONS_TITLE,     // should be translated
        showAlertModal: false,
        breadCrumbArr: [{ label: "Select Firmwares", url: '' }],
        camapignid: '',
        selectedAction: '',
        showLoadingDialog: false,
        showConfirmDialog: false,
        confirmDialogeSettings: {
            title: '',
            description: '',
            actionLabel: '',
            confirmAction: () => { }
        },
        refresh: false,
        exportInit: 0,
    };
    
    const [deviceState, setDeviceState] = useState<IMyFirmwareStates>(stateInit);

    const refreshTable = () => {

        setDeviceState(prevState => {
            return { ...prevState, refresh: !prevState.refresh }
        })
    }

    const { state } = useLocation();

    const getActions = useCallback(() => {
        let actions: ActionType[] = [
        ];

        let bulkActions: BulkActionType[] = [];


        return {
            actions: actions,
            bulkActions: bulkActions
        }
    }, []);

    useImperativeHandle(ref, () => ({

        goToDevices(){
            const selectDevices = (state?.hasOwnProperty("selectedDevices")) ? state['selectedDevices'] : null;
            
            if(DataTableContextObj.searchState.selectedRowIds && DataTableContextObj.searchState.selectedRowIds.length > 0) {
                if(selectDevices && selectDevices.length){
                    navigate(`${getAppBase()}/my-campaigns/create`, { state: { selectedDevices:selectDevices,selectedFirmware:DataTableContextObj.searchState.selectedRowIds, row: {}, prevPageUrl: `${getAppBase()}/my-campaigns` } })
                    return true;
                } else {
                    
                    navigate(`${getAppBase()}/my-campaigns/create`, { state: { selectedDevices:selectDevices,selectedFirmware:DataTableContextObj.searchState.selectedRowIds, row: {}, prevPageUrl: `${getAppBase()}/my-campaigns` } })
                    return true;
                }
            } else {
                toast.warning("Select Firmware !!");
                return false;
            }     
        }    
      }));

    
    let DataTableContextObj = useContext(DataTableContext);

    const initDataTable = () => {

        let actions: any = [];
        let pageButtons: PageButtonType[] = [];

        actions = getActions();


        let columns: ColumnType[] = [
            {
                key: "firmwareName",
                type: "text",
                title: strings.CAMPAIGN_NAME,
                sortable: true,
                sortKey: "sort_by_deveui",
                render: (x) => x.firmwareName,
                cellWidth: 20,
                detailLink: true,
                copyLink: true,
                detailPageNav: (row) => { },
                customClass: 'font-monospace nowarp'
            },
            {
                key: "type",
                title: strings.CAMPAIGN_TYPE,
                type: "text",
                sortable: true,
                sortKey: "sort_by_comment",
                filterParams: {},
                cellWidth: 50,
                cellWidthType: '%',
            },
            {
                key: "releaseDate",
                title: strings.CAMPAIGN_UPLOAD_DATE,
                type: "text",
                sortable: true,
                sortKey: "sort_by_comment",
                filterParams: {},
                cellWidth: 50,
                cellWidthType: '%',
            },
            {
                key: "fileSize",
                title: strings.FIRMWARE_FILE_SIZE,
                type: "text",
                sortable: true,
                sortKey: "sort_by_fileSize",
                filterParams: {},
                cellWidth: 50,
                cellWidthType: '%',
            }
        ];

        let bulkActions: BulkActionType[] = [
            {
                type: "action",
                text: 'NEXT',
                icon: faForward,
                action: () => {
                    console.log(DataTableContextObj.searchState.selectedRowIds)
                },
            },
        ];


        let options: OptionType = {
            url: '/uiapi/rest/fuota/firmware_images',
            query_param: { all: true, get_pages: true, limit: DEFAULT_RECORD_LIMIT, stream: 'progress' },
            serial_number: false,
            id_field: 'firmwareName',
            oboe_path: 'pages.*',
            available_key: 'firmwareName',
            allowBulkActions: true,
            defaultSortField: 'sort_by_deveui',
            defaultSortOrder: 'asc',
            emptyDataMsg: '<b>Sorry!</b> No Firmware Available',
            modal: true,
            hideBulkActions: true
        }

        return {
            ...actions,
            columns: columns,
            ...options,
            pageButons: pageButtons,
            bulkActions: bulkActions
        };
    }

    let refresh = deviceState.refresh;

    const onDrop = React.useCallback((acceptedFiles) => {

		setSelectedFile(acceptedFiles.map(async(file) => {

        var url = '/uiapi/rest/fuota/firmware_images'; // File upload web service path

        var formData = new FormData();
        formData.append("datafile", file);

        var xhr = new XMLHttpRequest();
        xhr.open('POST', url);

        xhr.setRequestHeader("x-csrf-token", constants._csrf);
        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                var complete = (event.loaded / event.total * 100 | 0);
                console.log(complete)
            }
        };

        xhr.onload = function () {
            if(xhr.status == 200) {
                toast.success("Firmware file uploaded successfully");
                refreshTable()
            } else {
                toast.error(xhr.response);
            }
        };

        xhr.send(formData);
			return Object.assign(file, {
				preview:URL.createObjectURL(file)
			})
		}))
	}, [])

    const {getRootProps, getInputProps} = useDropzone({onDrop})

	const selected_files = selectedFiles?.map( (file, index) => {
		return (<div key={index}>{file.path}</div>)
	})

    return (
        <div className="col-md-12 col-sm-12 col-lg-12 row">
            <div className="col-md-7 col-sm-7 col-lg-7 fuota-stepper">
            <PageContent
                name="my-firmwares"
                id={campaignid}
                tabname={tabname}
                actions={getActions()}
                breadCrumbArr={[]}
                pageButtons={[]}
                countLabel={`Firmwares`}
                dataTableOption={initDataTable()}
                exportInit={deviceState.exportInit}
                refresh={refresh} />

            </div>
            <div className="col-md-5 col-sm-5 col-lg-5">
            <div className="d-flex flex-1 w-100 h-100">
			<div className="d-flex align-items-center justify-content-center w-100" style={{border:'2px dashed'}}>
				<div {...getRootProps()} className="d-flex flex-column align-items-center justify-content-center w-100 py-3" >
					<input {...getInputProps()} className="w-100 h-100"></input>
					<div style={{paddingBottom:5}} > 
						<FontAwesomeIcon size="4x" icon={faCloudArrowUp} />
					</div>
					<div style={{paddingBottom:5}}>
						<Button variant="outline-dark">{`Choose files to upload`}</Button><br/>
					</div>
					<div style={{paddingBottom:5}}>
						{selected_files.length > 0 && selected_files}
						{selected_files.length === 0 && `or drag and drop theme here`}
					</div>
					
				</div>
			</div>
			
		</div> 
            </div>
        </div>
    )
})

export default MyFirmwares;