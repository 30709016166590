import React, { useContext, useEffect, useState }  from 'react';
import AppContext from '../../../context/AppContext'
import { GenericDassQuery } from "../../../services/BasicDassQueries";
import { useNavigate } from 'react-router-dom';
import { 
        ColumnType, 
        BulkActionType,
        DataTableOption
     } from '../../../components/Common/DataTable/DataTypes';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import{ getAppBase }  from "../../../utils/consts";
import { TAB_GATEWAY_CONFIGURATION } from '../../../datatypes/tabsconstants';
import { DataTable, DataTableWrapper } from '../../../components/Common';
import MapContext from '../../../context/MapContext';
import{ ID_INPUT_VALIDATION, DEFAULT_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../Common/DataTable/DataTableConsts";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { strings } from "../../../services/Localization";


const MapGatewaysTable = ({ refresh} ) => {
    
    const navigate  = useNavigate();
    const MapContextObj = useContext(MapContext);
    const AppContextObj = useContext(AppContext);
    const data = [...MapContextObj.networkState.Gateways];

    // Creating Alarm ID Map table to access while rendering alarm description on NST for tooltip
    let alarmDescpMap = {}
    async function init ()  {
        const alarm_types = await GenericDassQuery("/rest/omc/alarm_types", { method: "GET"})
        const data = alarm_types.status == 200 ? alarm_types.data : [];
        for (const alarm of data) {
            alarmDescpMap[alarm.alarmid] = { description: alarm.description, severity: alarm.severity }
        }
    }

    useEffect(() => {
        init()
    },[])

    const [popoverOpen, setPopoverOpen] = useState(false);

    

    const flyToMarker = ( obj ) => {
        MapContextObj.FlyToTheMarker( obj, 'nst',  navigate );
    }
    
    const navigateToGatewayInfo = ( navigate, tab, row ) => {
        navigate(`${getAppBase()}/omc-gateways/${row.uuid}/${tab}/map`, {state: {row: row, prevPageUrl: `${getAppBase()}/network_map`}})

    }
    const navigateToGatewayAlarms = ( navigate, row ) => {  
        if(AppContextObj.user.omc_see_gtw_alarm_timeline || AppContextObj.user.omc_mng_gtw_alarms) {
            navigate(`${getAppBase()}/gateway-alarms?search_neid=${row.uuid}`, {state: { row: row, prevPageUrl: `${getAppBase()}/network_map`}})
        }
        return false
    }


    const DisplayOverlay = ({color, togglePopover, x}) => {

        const [show, setShow] = useState(false);
        const [show2, setShow2] = useState(false);

        const user = AppContextObj.user
        const overalayvisible = (user.omc_see_gtw_alarm_timeline || user.omc_mng_gtw_alarms) ? true : false;
        
        const alarmCount = user.omc_see_gtw_alarm_timeline || user.omc_mng_gtw_alarms ? x.alarmCounters?.numAlarms : null;
        const alarmsList = (x.alarmCounters?.activeAlarmIds || []).map((v,i) => {

            const style = alarmDescpMap[v]?.severity == 3 ? "badge ow-nst-server-status-info-critical d-block" : "badge ow-nst-server-status-info-warning d-block"

            return <div 
                    key={i}  
                    onClick={() => navigateToGatewayAlarms(navigate, x)} 
                    className={style}>{alarmDescpMap[v]?.description} <br />
            </div>

        })
        const popover = (

            <Popover  
            onMouseEnter={() => setShow2(true)}
            onMouseLeave={() => setShow2(false)} 
            className={"ow-schema-help-popover"} style={{maxWidth: '450px'}}>
                <Popover.Header>
                    Active Alarms
                </Popover.Header>
                <Popover.Body>
                    <div>
                        {alarmsList}
                    </div>
                </Popover.Body>
            </Popover>
        );

        return (<div 
                className='d-flex'>
                {overalayvisible ? <OverlayTrigger show={show || show2}
                onToggle={(nextShow) => setShow(nextShow)} trigger={["hover", "hover"]} placement="right" overlay={popover} rootClose>
                <div
                onClick={() => navigateToGatewayAlarms(navigate, x)}
                className="badge-map cursor-pointer mr-2"
                style={{ background: color }}
                >{alarmCount}
                </div>
        </OverlayTrigger> : <div
                onClick={() => navigateToGatewayAlarms(navigate, x)}
                className="badge-map cursor-pointer mr-2"
                style={{ background: color }}
                >{alarmCount}
                </div>}
        <FontAwesomeIcon onClick={(e) => {e.stopPropagation(); navigateToGatewayInfo(navigate, TAB_GATEWAY_CONFIGURATION, x)}} className="mr-2" icon={faPencil} />
        </div>);
    }
    
    const renderAlarms = (x) =>{
        
        const togglePopover = () => {
            setPopoverOpen(!popoverOpen);
        };

        if (x.alarmCounters?.worstSeverity == 3 ) {
            return <DisplayOverlay color='red' togglePopover={togglePopover} x={x} />
        } else if (x.alarmCounters?.worstSeverity == 0) {
            return <DisplayOverlay color='rgba(110,204,57,0.6)' togglePopover={togglePopover} x={x} />
        } else {
            return  <DisplayOverlay color='rgba(240,194,12,0.6' togglePopover={togglePopover} x={x} />
        }
    }

    const  initDataTable = () => {

        // let actions = getActions();
        
        let bulkActions:BulkActionType[] = [];

        let columns:ColumnType[] = [
            {
                key: "name",
                type: "text",
                title: strings.MAP_GATEWAY_TABLE_NAME,
                filterable: true,
                filterField: "name",
                filterType: 'text',
                sortable: true,
                sortKey: "name",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                detailLink:true,
                detailPageNav: (row) =>  flyToMarker( row ),
                cellWidth: 21
            }, 
            {
                key: "uuid",
                type: "text",
                title: strings.MAP_GATEWAY_TABLE_ID,
                filterable: true,
                filterField: "uuid",
                filterType: 'text',
                sortable: true,
                inputValidation: ID_INPUT_VALIDATION,
                sortKey: "uuid",
                filterParams: {
                    mapper: x => (x || "").replace(new RegExp("-|:", 'g'), "")
                },
                detailPageNav: (row) =>  flyToMarker( row ),
                cellWidth: 15,
                customClass: 'font-monospace nowarp uppercase'
                
            },     
            {
                key: "uuid",
                type: "icon_with_tooltip",
                title: strings.MAP_GATEWAY_TABLE_STATUS,
                newCellWidth: "90px",
                filterable: false,
                filterType: 'text',
                cellWidth: "nowrap",
                sortable: true,
                sortKey: "alarmCounters.numAlarms",
                dataAlign:'center',
                render: x => { return renderAlarms(x)},
                
            }          
                                 
        ];


        let dataTableOption: DataTableOption =  {
            url: 'default-url',
            query_param: { all:true, get_pages:true, limit: DEFAULT_RECORD_LIMIT, stream: 'memory'} as any,
            serial_number: false,
            id_field: 'uuid',
            oboe_path: 'pages.*',
            pagesInPagination: 1,
            paginationWrapper: 'map-pagination-wrapper',
            enableDebug: false,
            dataType: 'memory',
            data,
            bulkActions,
            columns, 
            extraSearchProp: { key:'search_tags', label:'', filterType: 'tags', populateUrl:'/uiapi/rest/omc/tags'},
        };
        
        return dataTableOption;
    }

    let display = 'block';
        
    if(data) {
        return (
            <DataTableWrapper display={display} wrapperClass={`table-wrapper-map`}>
                <DataTable 
                    name="map-gateway-table"
                    dataTableOption={initDataTable()} 
                    display={display}  refresh={refresh} countLabel={`Devices`} />
            </DataTableWrapper>
        );
    }else {
        return null;
    }
}

export default MapGatewaysTable;