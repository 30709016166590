import React, { useContext } from "react";
import { strings } from "../../services/Localization";
import AppContext from '../../context/AppContext'
import { useNavigate } from 'react-router-dom';
import { DataTable } from '../../components/Common';
import { PageButtonType } from '../../datatypes/datatypes';
import { ActionType, ColumnType,BulkActionType, DataTableOption } from "src/components/Common/DataTable/DataTypes";
import { DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";
import { getAppBase } from '../../utils/consts';
import { IUser } from "../../dassTypes";
import { faChevronLeft, faPause, faPlay, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "./../../utils/Toaster";
import { GenericDassQuery } from "../../services/BasicDassQueries";

declare const constants;

interface IRowType {

    itemId: string;
    batchId: string;
    newFirmware: string;
    sessionId: string;
    progress: number;
	error?: string;
    totalProgress: number;
    currentStatus: string;
    lastStatusChange: string;
    handlingServerUuid: string;
    host: string;
    additionalStatus: string;
    startTime: string;
    gatewayUuid: string;
    gatewayName: string;
    gatewayType: string;
    tenantId: string;

}

interface JobsDataState {
	device: any;
	exportInit:number;
	user:IUser;
	groups:any;
	apps:any;
	refresh: boolean;
}

const JobsDataTable:React.FC<any> = ( props ) =>  {

	const batch_id: string = props.id;
	const AppContextObj = useContext(AppContext);
	const navigate = useNavigate();

	const initState = {
		exportInit:0,
		device:batch_id,
		user:props.user,
		groups:[],
		apps:[],
		refresh: false
	}

	const [state, setState] = React.useState<JobsDataState>(initState);

    const  getActions = () => {

        getBreadCrumbArr: (breadCrumbArgs) => {
            const { id, row, prevPageUrl } = breadCrumbArgs;
    
            
            return [
                { label: 'Jobs', url: prevPageUrl },
                { label: `${id}`, url: '', comment: row.comment }
            ]
        }

		const actions: ActionType[] = [];

		const bulkActions: BulkActionType[] = [];

		return {actions , bulkActions };
    }

	const  getColumns = () => {
		const columns: ColumnType<IRowType>[] = [
			{
				key: "gatewayUuid",
				dbKey: "itemId",
				title: strings.GATEWAY_ID,
				type: "text",
				cellWidth: 10,

				dataAlign: 'left',
				newCellWidth: "180px",
			},

			{
				key: "gatewayName",
				title: strings.GATEWAY_NAME,
				type: "text",
				filterable: false,
				filterType: 'text',
				filterField: 'fcnt',
				dataAlign: 'center',
				newCellWidth: "150px",
			},
			{
				key: "gatewayType",
				title: strings.GATEWAY_TYPE,
				type: "text",
				dataAlign: 'center',
				newCellWidth: "150px",
			},
			{
				key: "itemId",
				dbKey: "itemId",
				title: "Item ID", //Fixme should be like string.xxxxx
				type: "icon_with_tooltip",
				dataAlign: 'center',
				newCellWidth: "70px",
			},
			{
				key: "error",
				title: strings.ERROR,
				type: "text",
				dataAlign: 'center',
				cellWidth: 30,
				cellWidthType: "%",				
				newCellWidth: "70px",
			},
			{
				key: "additionalStatus",
				title: strings.STATUS,
				type: "text",
				filterable: false,
				dataAlign: 'center',				
				newCellWidth: "100px",
			}
        ];

		return columns;
	}

    const  getDataTableOptions = () => {

		//getDeviceDetails();

        const actions = getActions();
		const columns = getColumns();

        let options: DataTableOption = {
			url: `/uiapi/rest/omc/gtw_firmware_update`,
            query_param: { batch_id:batch_id,all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress'},
            serial_number: false,
            id_field: 'batchId',
            oboe_path: 'pages.*',
            available_key: 'itemId',
			allowBulkActions: false,
			columns,
			actions: actions.actions,
			countLabel:'Jobs',
			isDisplayMobile:true
        }

        return options;
	}

    const  refreshTable = () => {

		setState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })
		
		
	}

    const  getPageButtons = () => {

		const pageButtons:PageButtonType[] = [
            {
                title: "Back to Job List", // Fixme should be like string.xxxxx
                action: () => detailPageNav(navigate, "job-details", null ),
                type: 'button_with_icon',
                icon: faChevronLeft,
				
            },
            {
                title: "Schedule Jobs",
                action: () => scheduleJobs(),
                type: 'button_with_icon',
				icon: faPlay,
				visible: () => AppContextObj.user.omc_mng_gtw_firmware
            },
			{
                title: "Pause/Restart Jobs",
                action: () => startstopJobs(), // Fixme
                type: 'button_with_icon',
				icon: faPause,
				visible: () => AppContextObj.user.omc_mng_gtw_firmware
            },
			{
                title: "Delete Jobs",
                action: () => deleteJobs(),
                type: 'button_with_icon',
				icon: faTrashAlt,
				visible: () => AppContextObj.user.omc_mng_gtw_firmware
            }
           
        ]

        return pageButtons;
    }


	const detailPageNav = ( navigate, tab, row) => {

        navigate(`${getAppBase()}/gateway-jobs`, {state: {row: row, prevPageUrl: `${getAppBase()}/gateway-jobs`}})
		refreshTable()
    }
	

	const startstopJobs = async () => {
		
        try {
			const jobState = await GenericDassQuery(`/rest/omc/batches/${batch_id}`, { method: "GET"});

			if(jobState.status === 200 && jobState.data.batchJobStatus === "STARTED" ) {
            	await GenericDassQuery(`/rest/omc/batches/pause/${batch_id}`, { method: "PUT"});
            	toast.success("Job Paused !!")
			}
			else if(jobState.status === 200 && jobState.data.batchJobStatus === "PAUSED" ) {
				await GenericDassQuery(`/rest/omc/batches/resume/${batch_id}`, { method: "PUT"});
				toast.success("Job Resumed !!")
			} else {
				await GenericDassQuery(`/rest/omc/batches/pause/${batch_id}`, { method: "PUT"});
				toast.success("Job Paused !!")
			}
        } catch (e) {
            toast.error(JSON.parse(e.message).message);
        }
        refreshTable();
    }


	 const scheduleJobs = async () => {

        try {
            await GenericDassQuery(`/rest/omc/batches/schedule/${batch_id}`, { method: "PUT"});
            toast.success("Job Scheduled!!")
        } catch (e) {
            toast.error(JSON.parse(e.message).message);
        }
        refreshTable();
    }	

	const deleteJobs = async () => {

        try {
            await GenericDassQuery(`/rest/omc/batches/${batch_id}`, { method: "DELETE"});
            toast.success("Job Deleted !!")
			detailPageNav(navigate, "job-details", null)
        } catch (e) {
            toast.error(JSON.parse(e.message).message);
        }
        refreshTable();
    }

	return (<DataTable  exportInit={state.exportInit} name={`job-details`} refresh={state.refresh} countLabel={'Messages'}  dataTableOption={getDataTableOptions()} pageButtons={getPageButtons()} scope="child" /> )
}


export default JobsDataTable;