
import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';

import { faAdd, faEdit } from '@fortawesome/free-solid-svg-icons'

import { /*IRowType,*/ pageUrlPart, pageTitle } from "./GatewayParameters";

import { getAppBase } from '../../utils/consts';
import { strings } from "../../services/Localization";
import { TAB_EDIT_PARAM, TAB_ADD_PARAM } from '../../datatypes/tabsconstants'

const detailPageNav = ( navigate, tab, id, row = null,prevPageUrl) => {
    if(id) {
        
        navigate(`${getAppBase()}/${pageUrlPart}/${id}/${tab}`, {state: {row: row, prevPageUrl}})
    }
}


const GatewayParametersProps: DetailPagePropsType =  {
    
    getBreadCrumbArr: (args) => {
        
        let prevPageUrl = args.hasOwnProperty('prevPageUrl') ? args['prevPageUrl'] : `${getAppBase()}/${pageUrlPart}`;

        const breadCrumb:BreadCrumbType[] = [
            {label:strings.NAV_GATEWAYS_ITEM, url:`${getAppBase()}/omc-gateways`},
            { label: pageTitle, url: prevPageUrl },
            { label:  "Add Parameter", url:''}
        ]
        return breadCrumb;
    },
    getActions : (args, navigate) => {
        
        let prevPageUrl = args.hasOwnProperty('prevPageUrl') ? args['prevPageUrl'] : `${getAppBase()}/${pageUrlPart}`;

        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: "Add Parameter",
                icon: faAdd,
                key: TAB_ADD_PARAM,
                action: (id) => detailPageNav(navigate, TAB_ADD_PARAM, '', {}, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/defaultgatewayparameters?get_schema=dassui,post`,
                    backUrl: `${prevPageUrl}`,
                },
            }
        ];
        return actions;
    }
}

export { GatewayParametersProps };


const GatewayParametersRowProps: DetailPagePropsType =  {
    getBreadCrumbArr: (args) => {
        const { row } = args;
        let prevPageUrl = args.hasOwnProperty('prevPageUrl') ? args['prevPageUrl'] : `${getAppBase()}/${pageUrlPart}`;
        const breadCrumb:BreadCrumbType[] = [
            {label:strings.NAV_GATEWAYS_ITEM, url:`${getAppBase()}/omc-gateways`},
            {label: pageTitle, url: `${prevPageUrl}`},
            {label: "Edit Parameter", url:'', comment: row.key}
        ]
        return breadCrumb;
    },
    getActions: (args, navigate) => {
        
        let prevPageUrl = args.hasOwnProperty('prevPageUrl') ? args['prevPageUrl'] : `${getAppBase()}/${pageUrlPart}`;

        const { row } = args;

        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: "Edit Parameter",
                icon: faEdit,
                key: TAB_EDIT_PARAM,
                action: (row) => detailPageNav(navigate, TAB_EDIT_PARAM, row.id, row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/defaultgatewayparameters?get_schema=dassui,put`,
                    backUrl: `${prevPageUrl}`
                },
                EditObject: row.id ? { id: row.id } : null
            }
        ];

        return actions;
    }
}


export { GatewayParametersRowProps }
