import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';

import { faAdd, faEdit } from '@fortawesome/free-solid-svg-icons'

import { IRowType, pageUrlPart, pageTitle } from "./JoinServers";
import { getAppBase } from '../../utils/consts';

import { TAB_ADD_JOINSERVER, TAB_EDIT_JOINSERVER } from '../../datatypes/tabsconstants'


const detailPageNav = (navigate, tab: string, registry: string, row: IRowType, prevPageUrl) => {

    const id = row.joineui_range;
    const part = (registry === "global" ? "sys-" : "") + pageUrlPart;

    if (id) {
        navigate(`${getAppBase()}/${part}/${id}/${tab}`, {state: {row: row, prevPageUrl}, replace: false})
    }
}


const getJoinServersProps = (registry: string): DetailPagePropsType => {

    const part = (registry === "global" ? "sys-" : "") + pageUrlPart;
    return {
        getBreadCrumbArr: (breadCrumbArgs) => {
            let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/${part}`;
            
            const breadCrumb:BreadCrumbType[] = [
                { label: pageTitle, url: prevPageUrl },
                { label:  "Add Parameter", url:''}
            ]

            return breadCrumb;
        },
        getActions : (actionArgs, navigate) => {
            
            let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/${part}`;

            const tabs: DetailPageActionType[] = [
                {
                    type: "tab",
                    text: "Add join server",
                    icon: faAdd,
                    key: TAB_ADD_JOINSERVER,
                    action: (id) => detailPageNav(navigate, TAB_ADD_JOINSERVER, registry, {} as IRowType, prevPageUrl),
                    content: {
                        type: 'SchemaModal',
                        url: `/rest/joinservers?get_schema=dassui,post`,
                        backUrl: prevPageUrl,
                    },
                    EditObject: { registry, __readonly: false }
                }
            ];
            return tabs;
        }
    }
}


export const JoinServersProps = getJoinServersProps("customer") ;
export const GlobalJoinServersProps = getJoinServersProps("global") ;




const getJoinServersRowProps = (registry: string): DetailPagePropsType => {

    const part = (registry === "global" ? "sys-" : "") + pageUrlPart;
    return {

        getBreadCrumbArr: (breadCrumbArgs) => {
            let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/${part}`;
            const { row } = breadCrumbArgs;
            const breadCrumb:BreadCrumbType[] = [
                {label: pageTitle, url: prevPageUrl},
                {label: "Edit Parameter", url:'', comment: row.uuid}
            ]

            return breadCrumb;
        },
        getActions: (actionArgs, navigate) => {

            let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/${part}`;
            const row: IRowType = actionArgs.row

            const tabs: DetailPageActionType[] = [
                {
                    type: "tab",
                    text: "Accept Gateway",
                    icon: faEdit,
                    key: TAB_EDIT_JOINSERVER,
                    action: (row: IRowType) => detailPageNav(navigate, TAB_EDIT_JOINSERVER, registry, row, prevPageUrl),
                    content: {
                        type: 'SchemaModal',
                        url: `/rest/joinservers?get_schema=dassui,put`,
                        backUrl: prevPageUrl
                    },
                    EditObject: { joineui_range: row.joineui_range, registry },
                }
            ];

            return tabs;    
        }
    }
}

export const JoinServersRowProps =  getJoinServersRowProps("customer");
export const GlobalJoinServersRowProps =  getJoinServersRowProps("global");
