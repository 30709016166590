import React, { ReactNode , useContext } from 'react';
import AppContext from "../../context/AppContext";

interface DataTableWrapperProps {
    wrapperClass?:string;
    display?: "none" | "block" | "grid" | string;
    children: ReactNode;
}

const DataTableWrapper: React.FC<DataTableWrapperProps> = ( props ) => {

    const appContextObj = useContext(AppContext);
    const user = appContextObj?.user || null; 
    const mar_imp = (appContextObj.isSignedIn(user) && appContextObj.isForwardedSignIn() && appContextObj.navBarState.forwardedSignIn )? 'impersonated' : ''

    let  wrapperClass = (props.wrapperClass) ? props.wrapperClass : `table-wrapper ${mar_imp} `;
    return (
        <div className={wrapperClass} style={{display: props.display}} >
            {props.children}
        </div>
    );
}

export {  DataTableWrapper };