import React from "react";

// Imports for Chart extension
// import Chart from 'chart.js/auto';

import { Bar } from 'react-chartjs-2'
import { IUiSchemaElemArgs, registerComponentHandler } from './SchemaController';

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);


//ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


const chartjsExtension = (args: IUiSchemaElemArgs) => {

	const { key, value } = args;
	let   { dataset, x, y } = value;

	if (dataset && typeof x === "string" && typeof y === "string") {
		x = dataset.map(p => p[x]);
		y = dataset.map(p => p[y]);
	}


	const data = {
		labels: x || [],
		datasets: [
		  {
			type: "line",
			label: "Dataset 1",
			borderColor: "rgb(54, 162, 235)",
			borderWidth: 2,
			fill: false,
			data: y || [],
		  },
		],
	};

	return (
		<Bar key={key} data={data as any} />
	);
};


registerComponentHandler("chartjs", chartjsExtension);
