import React,  { useContext, useLayoutEffect } from "react";

import {  Dropdown, Nav, NavDropdown } from "react-bootstrap";

import { NavLink, useLocation  } from "react-router-dom";

import { INavbarState } from "src/context/AppContext";

import AppContext from "../../context/AppContext";

let key = 0;

function mkey() { key = Math.floor(key) + 1; return key; }

function skey() { key += 1; return key; }



export interface ISubmenuType {
    href: string | ((currentState:any) => string);
    hreftype?: "navlink" | "extlink";
    title: string;
    visible?: (currentState: INavbarState, usernav?:any) => boolean;
    onClick?: () => void;

};

export interface INavArrayType {
    title: string;
    type: "link" | "dropdown";
    href?: string;
    visible: (currentState: INavbarState) => boolean;
    submenu?: ISubmenuType[];
}


interface BuildNavArrayProps {
    navArray:INavArrayType[];
}

const BuildNavArray:React.FC<BuildNavArrayProps> = ({ navArray }) => {
    const nav = navArray.map(function(navItem, index) {
        if(navItem.type === 'dropdown') {
            return <BuildNavDropDown navItem={navItem} key={index} />
        }else if(navItem.type === 'link') {
            return <BuildNavLink navItem={navItem} key={index} />
        }else {
            return null;
        }
    })

    return (
        <React.Fragment>
          { nav }
        </React.Fragment>
      )

}

export { BuildNavArray }

interface BuildNavLinkProps {
    navItem:INavArrayType;
}

const BuildNavLink:React.FC<BuildNavLinkProps> = ( { navItem } ) => {
    let currentLocation = useLocation();
    const AppContextObj = useContext(AppContext);


    let href = navItem.href;
    
    let title = navItem.title;

    let visible = (navItem.visible) ? navItem.visible : () => { return true };

    //let checkUser = AppContextObj.navBarState.user !== AppContextObj.user;

     useLayoutEffect(() => {
         AppContextObj.setNavBarState(prevState => { return {...prevState, user: AppContextObj.user}})
     },[])

    if(AppContextObj.navBarState.user === null) {
        return null;
    }
    else {
        if(visible) {
            if(!visible(AppContextObj.navBarState)) {
                return null;
            }
        }
    
        return (<div className="hover_ nav-item dropdown">
                <Nav.Link
                    as="div"
                            eventKey={mkey()}
                            href={href}
                            className={
                                currentLocation.pathname === href ?
                                    `active_menu_${AppContextObj.navBarState.language}_ active_menu_button` : "hover_item"
                            }
                        >
                        <NavLink to={href}  className=" text-light text-decoration-none">
                            {title}
                        </NavLink>
                </Nav.Link>
            </div>
        )
    }
    
}

interface BuildNavDropDownProps {
    navItem:INavArrayType;
}

const BuildNavDropDown:React.FC<BuildNavDropDownProps> = ({ navItem  }) => {
    const AppContextObj = useContext(AppContext);
    const title = navItem.title;
    const visible = (navItem.visible) ? navItem.visible : null;
    const currentLocation = useLocation();


    useLayoutEffect(() => {
        AppContextObj.setNavBarState(prevState => { return {...prevState, user: AppContextObj.user}})
    },[])

    if (AppContextObj.navBarState == null || AppContextObj.navBarState.user === null || (visible && !visible(AppContextObj.navBarState))) {
        return null;
    }
    if (!navItem.submenu) {
        return null;
    }
    const submenu = navItem.submenu.filter(m => !m.visible || m.visible(AppContextObj.navBarState));

    if (submenu.length === 0) {
        return null;
    }

    const subMenuArrray = submenu.map((item, index) => item.href);
    const subMenu = submenu.map((subMenuItem, index) => (<BuildNavDropDownItem subMenuItem={subMenuItem}  key={index} />));

    

    return (
        <NavDropdown
            title={title}
            id="basic-nav-dropdown"
            className={subMenuArrray.includes(currentLocation.pathname) ? `active_menu_${AppContextObj.navBarState.language}_ active_menu_dropdown` : "hover_"}
        >
            {subMenu}
        </NavDropdown>
    );
}

interface BuildNavDropDownItemProps {
    subMenuItem:ISubmenuType;
}

const BuildNavDropDownItem:React.FC<BuildNavDropDownItemProps> = ({ subMenuItem  }) => {

    const AppContextObj = useContext(AppContext);
    const {title, href, hreftype } = subMenuItem;
    
    
    const x = ( state ) => {
        return true
    }
    
    const visible = (subMenuItem.visible) ? subMenuItem.visible : x;
    const onClick = (subMenuItem.onClick) ? subMenuItem.onClick : null;

    useLayoutEffect(() => {
        AppContextObj.setNavBarState(prevState => { return {...prevState, user: AppContextObj.user}})
    },[])

   if(AppContextObj.navBarState.user === null) {
       return null;
   }
   else {

        if(visible) {
            if(!visible(AppContextObj.navBarState)) {
                return null;
            }
        }
        
        if(onClick) {
            return (<Dropdown.Item
                as='div'
                eventKey={skey()}
                href=''
                onClick={onClick}
                className={AppContextObj.navBarState.menu === href ? `active_menu_${AppContextObj.navBarState.language}_ active_menu_item` : "hover_menu"}
            >
            {title}
            </Dropdown.Item>)

        }else if(typeof href === 'function')  {
            return (<Dropdown.Item
                    as='div'
                    eventKey={skey()}
                    href={href(AppContextObj.navBarState)}
                    className={"hover_menu"
                        /*
                            FIXME: this was the old code. See the href that is a function here can never match a string. Should there be another condition?
                            AppContextObj.navBarState.menu === href ? `active_menu_${AppContextObj.navBarState.language}_ active_menu_item` : "hover_menu"
                            */
                        }
            >
                <NavLink to="/" onClick={href} className="hover_menu dropdown-item">
                {title}
                </NavLink>
            </Dropdown.Item>)
        }else {
            return (<Dropdown.Item
                as='div'
                eventKey={skey()}
                className={AppContextObj.navBarState.menu === href ? `active_menu_${AppContextObj.navBarState.language}_ active_menu_item` : "hover_menu"}
            >
                {hreftype === "extlink" ?
                    <a href={href} target="_blank" className="hover_menu dropdown-item">
                        {title}
                    </a>
                :
                    <NavLink to={href} className="hover_menu dropdown-item">
                        {title}
                    </NavLink>
                }


            </Dropdown.Item>)
        }
    }
}