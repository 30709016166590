import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';

import { faEdit } from '@fortawesome/free-solid-svg-icons'

import { getAppBase } from '../../utils/consts';

import { TAB_SERVER_GRAPH } from '../../datatypes/tabsconstants'

const detailPageNav = ( navigate, tab, id, row = null, prevPageUrl) => {
    if(id) {
        navigate(`${getAppBase()}/servers-status/${id}/${tab}`, {state: {row: row, prevPageUrl}})
    }
}

export const ServerStatusProps: DetailPagePropsType =  {
    getBreadCrumbArr: (breadCrumbArgs) => {
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/servers-status`;
        const { row } = breadCrumbArgs;

        const breadCrumb:BreadCrumbType[] = [
            {label: "Server Graphs", url: `${prevPageUrl}`},
            {label: row.server_id, url:'', comment: ""}
        ]
        return breadCrumb;
    },
    getActions: (actionArgs, navigate) => {

        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/servers-status`;
        const { row } = actionArgs;

        const tabs: DetailPageActionType[] = [
            {
                type: "tab",
                text: "Server Graph",
                icon: faEdit,
                key: TAB_SERVER_GRAPH,
                action: (row) => detailPageNav(navigate, TAB_SERVER_GRAPH, row.server_id, row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/servers?get_health=true&get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: row.server_id ? { server_id: row.server_id, data: row, __readonly: false} : null
            }
        ];

        return tabs;
    }
}

