

declare const headerContentTypeJson;
declare const headerNoContentType;
declare const constants: IConstants;

import { IConstants } from 'src/types';
import { appBaseUrl } from '../utils/consts';
import { toast } from "../utils/Toaster";
import { strings } from "../services/Localization";

export interface IProfileFilterColumn {
    colName: string;
    colValue: string;
}

export interface IProfileSortColumn {
    sortName: string;
    sortOrder: string;
}

export type IRequestMethod = "PUT" | "GET" | "POST" | "DELETE";
interface IGetDataOptions {
	query?: any;
	method?: IRequestMethod;
	data?: object | string;
	headers?: any;
	prefix?: string;		// for debug
}

export function objToQueryString(queryObj: {[index: string]: string }) {
    let query = "";
    for (const key of Object.keys(queryObj || {})) {
        if (queryObj[key] != null) {
            query += (query ? "&" : "?") + key + "=" + encodeURIComponent(queryObj[key] + "");
        }
    }
    return query;
}

export const getDassInfo = async () => {
	
	await constants.wait;
	const response = await fetch("/dass_info", {
		credentials: "same-origin",
		headers: headerNoContentType,
		method: "GET",
	});

	if (response.status > 299) {
		throw { status: response.status, message: await response.text() };
	}
	return { status: response.status, data: await response.json() };
};

export const GenericDassQuery = async (dassPath: string, options?: IGetDataOptions) => {
	
	const url = (options?.prefix ? options?.prefix : "/uiapi") +
				 dassPath + objToQueryString(options && options.query);
	const headers = { ...(options && options.headers),  ...headerNoContentType };
	const body = options && options.data && (typeof options.data === "object" ? JSON.stringify(options.data) : options.data);

	if (!headers["Content-Type"] && options && options.data) {
		headers["Content-Type"] = typeof options.data === "object" ? "application/json" : "text/plain";
	}

	await constants.wait;
	const response = await fetch(url, {
		credentials: "same-origin",
		headers,
		method: options && options.method || "GET",
		body,
	});

	if (response.status == 401) { // the status should be changed!!!
		window.location.href = appBaseUrl + "/signout?resignin";
	}

	const status = response.status;
	const contentType = response.headers.get("content-type");
	const json = contentType && contentType.indexOf("application/json") >= 0;
	const data = json ? await response.json() : await response.text();

	if (response.status === 429) {
		const startTimestamp = new Date().getTime();
		const reqTimestamp = Date.parse(response.headers.get("Retry-After"));
		const retryAfter = Math.ceil((reqTimestamp - startTimestamp) / 1000) ;	
		console.log(retryAfter)
		return { status, data, retryAfter };
	}

	if (status > 299 && status != 429) {
		throw { status, message: status >= 500 ? "System unreachable" : data };
	}
	
	return { status, data };
};

export const handle429Req = async (dassPath: string, options:IGetDataOptions, retryAfter: number, successCnt: number, apiType: string ) => {

	const method = {
		DELETE: ["DELETING", "DELETE"],
		POST: ["REGISTERING", "REGISTER"],
		PUT: ["UPDATING", "UPDATE"],
		GET: ["GOT", "GET"]
	}

	const action = method[options.method]
	const delay = (s) => new Promise(resolve => setTimeout(resolve, (s * 1010)));
	
	if (retryAfter < 10) {
		console.log(`Waiting Started ${retryAfter} sec`);
		await delay(retryAfter);
		console.log("Waiting Completed")
		const retryResponse = await GenericDassQuery(dassPath, options);
		if (retryResponse.status === 429) {
			const status = retryResponse.status
			toast.error(`${strings.BULK_OPERATION_STOPPED}, ${strings.CERTAIN_SERIES_OF_BULK_ACTIONS_ARE_LIMITED_TIME}. ${strings.THE_SUCCESSIVE} ${strings[action[0]].toLowerCase()} ${successCnt} ${strings[apiType.toUpperCase()].toLowerCase()} ${(strings.WORKED).toLowerCase()}, ${(strings.BUT_YOU_WILL_NOT_BE_ABLE_TO)} ${strings[action[1]].toLowerCase()}, ${strings.THEM_AGAIN_UNTIL_AFTER} ${Math.ceil(retryResponse.retryAfter/60)} ${strings.MINS} ${strings.OF_WAITING} `)
            throw { status, data: retryResponse.data };
		}
	} else {
		toast.error(`${strings.BULK_OPERATION_STOPPED}, ${strings.CERTAIN_SERIES_OF_BULK_ACTIONS_ARE_LIMITED_TIME}. ${strings.THE_SUCCESSIVE} ${strings[action[0]].toLowerCase()} ${successCnt} ${strings[apiType.toUpperCase()].toLowerCase()} ${(strings.WORKED).toLowerCase()}, ${(strings.BUT_YOU_WILL_NOT_BE_ABLE_TO)} ${strings[action[1]].toLowerCase()}, ${strings.THEM_AGAIN_UNTIL_AFTER} ${Math.ceil(retryAfter/60)} ${strings.MINS} ${strings.OF_WAITING} `)
		throw { status: 429, data: "" };
	}
}
