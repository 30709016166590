// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .img_padding_bottom {
    padding-bottom: 10px;
} */
.form-resetpassword{
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}

.form-resetpassword-message {
    max-width: 330px;
    /*padding: 15px; */
    margin: 0 auto 10px;
}

.form-resetpassword .form-resetpassword-heading, .form-resetpassword .checkbox{
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./app_react/src/pages/ResetPassword/ResetPassword.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;IACI,gBAAgB;IAChB,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["/* .img_padding_bottom {\n    padding-bottom: 10px;\n} */\n.form-resetpassword{\n    max-width: 330px;\n    padding: 15px;\n    margin: 0 auto;\n}\n\n.form-resetpassword-message {\n    max-width: 330px;\n    /*padding: 15px; */\n    margin: 0 auto 10px;\n}\n\n.form-resetpassword .form-resetpassword-heading, .form-resetpassword .checkbox{\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
