
export const TAB_DEVICE_DETAIL = 'device-detail';

export const TAB_ADD_DEVICE = 'add-device';

export const TAB_SEND_TO_DEVICE = 'send-to-device';

export const TAB_VIEW_DATA = 'view-data';

export const TAB_EXPORT_PAYLOADS = 'export-payloads';

export const TAB_GROUP_INFO = 'group-info';

export const TAB_SEND_MULTICAST = 'send-multicast';

export const TAB_GROUP_ACTIVITY = 'group-activity';

export const TAB_ADD_GROUP = 'add-group';

export const TAB_EDIT_APP = 'edit-app';

export const TAB_ADD_APP = 'add-application';

export const TAB_EDIT_DEFAULT_PUSH_CONFIG = 'edit-default-push-config';

export const TAB_GATEWAY_CONFIGURATION = 'gateway-info';

export const TAB_ADD_GATEWAY = 'add-gateway';

export const TAB_GATEWAY_LOCATION = 'gateway-location';

export const TAB_PROFILE_EDIT_ACTION = 'edit-profile';

export const TAB_CREATE_LINKED_PROFILE_ACTION = 'create-linked-profile';

export const TAB_IMPORT_DEVICE_PROFILE = 'import-device-profile';

export const TAB_ADD_DEVICE_PROFILE = 'add-device-profile';

export const TAB_ADD_SERVICE_PROFILE = 'add-service-profile';

export const TAB_ADD_CONNECTIVITY_PROFILE = 'add-connectivity-profile';

export const TAB_ADD_ROAMING_PROFILE = 'add-roaming-profile';

export const TAB_ADD_QOS_PROFILE = 'add-qos-profile';

export const TAB_ADD_CHANNEL_PROFILE = 'add-channel-profile';

export const TAB_ADD_LINKED_PROFILE = 'add-linked-profile';

export const TAB_VIEW_PROFILE = 'view-profile';

export const TAB_ADD_USER = 'add-user';

export const TAB_EDIT_USER = 'edit-user';

export const TAB_ADD_LOGIN = 'add-login';

export const TAB_EDIT_LOGIN = 'edit-login';

export const TAB_ADD_CUSTOMER = 'add-customer';

export const TAB_EDIT_CUSTOMER = 'edit-customer';

export const TAB_ORGANIZATION_LIST_ADD_ORGANIZATION = 'add-organisation';

export const TAB_EDIT_ORGANIZATION = 'edit-organisation';

export const TAB_VIEW_GATEWAY = 'view-gateway';

export const TAB_DEVICE_INFO = 'device-info';

export const TAB_GATEWAY_ALARM = 'gateway-alarms';

export const TAB_ALARM_INFO = 'alarm-info';

export const TAB_TENANT_CERT = 'tenant-cert';

export const TAB_ADD_TENANT = 'add-tenant';

export const TAB_TENANT_INFO = 'tenant-info';

export const TAB_ACCEPT_GATEWAY = 'accept-gateway';

export const TAB_ADD_PARAM = 'add-param';

export const TAB_EDIT_PARAM = 'edit-param';

export const TAB_TAG_INFO = 'tag-info';

export const TAB_TAG_ADD = 'add-tag';
export const TAB_TAG_CATEGORY_ADD = 'add-tag-category';
export const TAB_TAG_CATEGORY_DELETE = 'delete-tag-category';

export const TAB_APP_ASSIGNMENT = 'app-assignment';

export const TAB_ADD_FUOTA_CAMPAIGN = "add-fuota-campaigne"
export const TAB_GROUP_ASSIGNMENT = 'group-assignment';

export const TAB_BULK_CHANGE_OWNER = 'change-bulk-device-owner'

export const TAB_SERVER_GRAPH = "server-graph";
export const TAB_GATEWAY_BULK_OTA = "bulk-ota-gateway"

export const TAB_EDIT_BULK_DEVICE = "edit-bulk-device"

export const TAB_VIEW_JOBS = "job-details"

export const TAB_ADD_JOINSERVER = 'add-joinserver';
export const TAB_EDIT_JOINSERVER = 'edit-joinserver';
export const TAB_AUTHORIZE_GATEWAY = 'authorize-gateway';


const tabArr = [
TAB_TAG_CATEGORY_ADD
,TAB_TAG_CATEGORY_DELETE
,TAB_BULK_CHANGE_OWNER
,TAB_AUTHORIZE_GATEWAY
,TAB_EDIT_BULK_DEVICE
,TAB_VIEW_JOBS
,TAB_GATEWAY_BULK_OTA
,TAB_GROUP_ASSIGNMENT
,TAB_SERVER_GRAPH
,TAB_ADD_FUOTA_CAMPAIGN
,TAB_DEVICE_DETAIL
,TAB_ADD_DEVICE
,TAB_SEND_TO_DEVICE
,TAB_VIEW_DATA
,TAB_EXPORT_PAYLOADS
,TAB_GROUP_INFO
,TAB_SEND_MULTICAST
,TAB_GROUP_ACTIVITY
,TAB_ADD_GROUP
,TAB_EDIT_APP
,TAB_ADD_APP
,TAB_EDIT_DEFAULT_PUSH_CONFIG
,TAB_GATEWAY_CONFIGURATION
,TAB_ADD_GATEWAY
,TAB_GATEWAY_LOCATION
,TAB_PROFILE_EDIT_ACTION
,TAB_CREATE_LINKED_PROFILE_ACTION
,TAB_IMPORT_DEVICE_PROFILE
,TAB_ADD_DEVICE_PROFILE
,TAB_ADD_SERVICE_PROFILE
,TAB_ADD_CONNECTIVITY_PROFILE
,TAB_ADD_ROAMING_PROFILE
,TAB_ADD_QOS_PROFILE
,TAB_ADD_CHANNEL_PROFILE
,TAB_ADD_LINKED_PROFILE
,TAB_VIEW_PROFILE
,TAB_ADD_LOGIN
,TAB_EDIT_LOGIN
,TAB_ADD_USER
,TAB_EDIT_USER
,TAB_ADD_CUSTOMER
,TAB_EDIT_CUSTOMER
,TAB_ORGANIZATION_LIST_ADD_ORGANIZATION
,TAB_EDIT_ORGANIZATION
,TAB_VIEW_GATEWAY
,TAB_DEVICE_INFO
,TAB_GATEWAY_ALARM
,TAB_ALARM_INFO
,TAB_TENANT_CERT
,TAB_ADD_TENANT
,TAB_TENANT_INFO
,TAB_ACCEPT_GATEWAY
,TAB_ADD_PARAM
,TAB_EDIT_PARAM
,TAB_TAG_INFO
,TAB_TAG_ADD
,TAB_APP_ASSIGNMENT
,TAB_ADD_JOINSERVER
,TAB_EDIT_JOINSERVER
];


const getPageDisplayStatus = (id, tabname) => {
    if (tabArr.includes(tabname) || id) {
        return 'none';
    } else {
        return 'block';
    }
}

export { getPageDisplayStatus }

const tableTabArr = [
    TAB_VIEW_DATA,
    TAB_GROUP_ACTIVITY
];

const isTableViewTab = (tabname) => {
    if (tableTabArr.includes(tabname)) {
        return true;
    } else {
        return false;
    }
}

export { isTableViewTab }