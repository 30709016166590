import React from 'react';

const DetailPageContainer = ( props ) => {
    
    return (<div  style={{width: "100%"}}>
                {props.children}
            </div>
            )
    
}

export { DetailPageContainer };