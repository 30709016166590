// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .img_padding_bottom {
    padding-bottom: 10px;
} */
.form-verify-email{
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}

.form-verify-email-message {
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}

.form-verify-email .form-verify-email-heading, .form-verify-email .checkbox{
    margin-bottom: 10px;
}


.verify-email-status-panel {
   
    left: 50%;
    width: 30em;
   
    transform: translate(-50%, -50%);
   /* background-color: white; 
    top: 50%;
     height: 18em;
    -webkit-box-shadow: -11px 13px 20px -6px rgba(0,0,0,0.15);
    -moz-box-shadow: -11px 13px 20px -6px rgba(0,0,0,0.15);
    box-shadow: -11px 13px 20px -6px rgba(0,0,0,0.15);*/
    position: fixed;
    opacity: 95%;

}

.verify-email-message {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.verify-email-return-to-login {
    position: absolute;
    bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./app_react/src/pages/VerifyEmail/VerifyEmail.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;IACI,gBAAgB;IAChB,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;;AAGA;;IAEI,SAAS;IACT,WAAW;;IAEX,gCAAgC;GACjC;;;;;uDAKoD;IACnD,eAAe;IACf,YAAY;;AAEhB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf","sourcesContent":["/* .img_padding_bottom {\n    padding-bottom: 10px;\n} */\n.form-verify-email{\n    max-width: 330px;\n    padding: 15px;\n    margin: 0 auto;\n}\n\n.form-verify-email-message {\n    max-width: 330px;\n    padding: 15px;\n    margin: 0 auto;\n}\n\n.form-verify-email .form-verify-email-heading, .form-verify-email .checkbox{\n    margin-bottom: 10px;\n}\n\n\n.verify-email-status-panel {\n   \n    left: 50%;\n    width: 30em;\n   \n    transform: translate(-50%, -50%);\n   /* background-color: white; \n    top: 50%;\n     height: 18em;\n    -webkit-box-shadow: -11px 13px 20px -6px rgba(0,0,0,0.15);\n    -moz-box-shadow: -11px 13px 20px -6px rgba(0,0,0,0.15);\n    box-shadow: -11px 13px 20px -6px rgba(0,0,0,0.15);*/\n    position: fixed;\n    opacity: 95%;\n\n}\n\n.verify-email-message {\n    position: absolute;\n    top: 50%;\n    transform: translate(0, -50%);\n}\n\n.verify-email-return-to-login {\n    position: absolute;\n    bottom: 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
