import React, {
        useState, 
        useLayoutEffect,
        //useEffect,
        
    } from 'react';

import Select, { CSSObjectWithLabel, StylesConfig } from 'react-select';
import { IConstants } from 'src/types';

declare const constants: IConstants;

interface ColourOption {
    readonly value: string;
    readonly label: string;
    readonly color: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
}

const DataTableSearchComponent = ( props ) => {
    
    let DataTableContextObj = props.DataTableContextObj;
    
    let populateUrl = props.extraSearchProp.populateUrl;

    
    const inputFields  = DataTableContextObj.searchState.inputFields;

    const [state, setState] = useState({data: [],   filtered: [],   select2: undefined});

    //const [tags, setTags] = useState([]);

    // const inputHandler = ( e ) => {
    //     setInputValue(oldValue => e.target.value);
    // }
    
    const handleChange = ( selectedOption ) => {
    
        let searchArr = selectedOption.map((row, index) => {
            return row.value;
        })
        
        if(selectedOption) {
            setState(prevState => {
                return {...prevState,filtered:[...selectedOption] }
            })
        }

        let searchStr = searchArr.join(",");

        DataTableContextObj.updateSearchFields(props.extraSearchProp.key, searchStr, props.extraSearchProp)
         
     }

    // const onFilteredChangeCustom = (value, accessor) => {
    //     let filtered = state.filtered;
    //     let insertNewFilter = 1;
    
    //     if (filtered.length) {
    //       filtered.forEach((filter, i) => {
    //         if (filter["id"] === accessor) {
    //           if (value === "" || !value.length) filtered.splice(i, 1);
    //           else filter["value"] = value;
    
    //           insertNewFilter = 0;
    //         }
    //       });
    //     }
    
    //     if (insertNewFilter) {
    //       filtered.push({ id: accessor, value: value });
    //     }
    
    //     setState(prevState => { return {...prevState, filtered: filtered }});
    //   };

    useLayoutEffect(() =>{
        async function f() {

            let filtered:any[] = [];

            let selectedTags  = [];

            if(inputFields && inputFields.hasOwnProperty("search_tags")) {
                selectedTags = inputFields.search_tags.split(",");
                //DataTableContextObj.updateSearchFields(props.extraSearchProp.key, queryField.search_tags, props.extraSearchProp)
            }
        
            const NselectedTags = selectedTags.map((item) => parseInt(item));
        
            await constants.wait;
            let response = await fetch(populateUrl);
            let results = await response.json();

            let data = results.map((r, i) => {
                return {value: r.tagid, label: r.tagName , color: r?.color}
            })

            filtered = data.filter(option =>  {  return NselectedTags.includes(option.value) });

            setState(prevState => { return {...prevState, data: data, filtered:[...prevState.filtered, ...filtered] }});
        }
        
        f();
        
    }, []);
    // const options = [
    //     { value: 'blues', label: 'Blues' },
    //     { value: 'rock', label: 'Rock' },
    //     { value: 'jazz', label: 'Jazz' },
    //     { value: 'orchestra', label: 'Orchestra' } 
    //   ];



    const colourStyles: StylesConfig<ColourOption, true> = {
        control: (styles) => ({ ...styles, backgroundColor: "white" } as CSSObjectWithLabel),
        valueContainer: (provided, state) => ({
          ...provided,
          height: '30px',
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden"
        } as CSSObjectWithLabel),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return { ...styles, backgroundColor:  '#FFFFFF', color: '#000' } as CSSObjectWithLabel;
        },
        multiValue: (styles, { data }) => {
          const color = data.color;
          return { ...styles,  backgroundColor: color ? color : '#c1c1c1' } as CSSObjectWithLabel;
        },
        multiValueLabel: (styles, { data }) => ({ ...styles, color: '#000' } as CSSObjectWithLabel),
        multiValueRemove: (styles, { data }) => ({ ...styles, color: '#000' } as CSSObjectWithLabel),
        menu: (styles) => ({ ...styles, zIndex: 1100 } as CSSObjectWithLabel),
      };
    
    return (<div style={{display: 'flex', flexDirection: 'row', border: '1px'}}>
            <Select
                value={state.filtered}
                className="col-xl-12"
                isMulti
                options={state.data}
                styles={colourStyles}
                onChange={handleChange}
        />
        </div>)
}

export default DataTableSearchComponent;