

import React from "react";
import Carousel from "react-multi-carousel";
import { Card } from "react-bootstrap";

import "react-multi-carousel/lib/styles.css";
import { IUiSchemaElemArgs, registerComponentHandler } from "./SchemaController";



// Auto register this extension

const carouselExtension = (args: IUiSchemaElemArgs) => {
	const { key, value, update } = args;
	return <ExtCarousel key={key} list={value.list} selectedId={value.selectedId} update={update}/>
};

registerComponentHandler("carousel", carouselExtension);





// Standard settings taken directly from the library homepage.
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2,
    },
};
interface ICarouselElem {
	title: string;
	imageUrl: string;
	footerHtml?: string;
	id: number | string;
}


interface IExtCarouselProps {

	list: ICarouselElem[];
	selectedId: number | string;
	update: (obj: { value: string | number }) => void;

}

interface IExtCarouselState {

	list: ICarouselElem[];
	selectedId: number | string;

}


export class ExtCarousel extends React.Component<IExtCarouselProps, IExtCarouselState> {

	constructor(props: IExtCarouselProps) {
		super(props);
		this.state = {
			list: props.list,
			selectedId: "",
		};
	}

	componentDidUpdate(prevProps: Readonly<IExtCarouselProps>, prevState: Readonly<IExtCarouselState>, snapshot?: any): void {
		if (this.props.list !== this.state.list) {
			this.setState({ list: this.props.list });
		}
		if (this.props.selectedId !== this.state.selectedId) {
			this.setState({ selectedId: this.props.selectedId });
		}
	}

	render () {

		const list = this.state.list.map(elem => {
			return (
				<Card key={elem.id}   className={"m-2" + (this.state.selectedId === elem.id ? " bg-secondary text-white" : "")}
						onClick={() => this.props.update({ value: elem.id })}>
					<Card.Header>
						<Card.Title>{elem.title}</Card.Title>
					</Card.Header>
					<Card.Body style={{ textAlign: "center" }}>
						{elem.imageUrl && <img className="img-fluid" src={elem.imageUrl}/>}
					</Card.Body>
					<Card.Footer style={{ height: "100px" }}>
						{elem.footerHtml && <div dangerouslySetInnerHTML={{ __html: elem.footerHtml }} />}
					</Card.Footer>
				</Card>
			);

		});

		const CarouselAny: any = Carousel;	// FIXME:
		return (<div className="mb-2 ow-carousel">
				<CarouselAny
					swipeable={true}
					draggable={false}
					showDots={true}
					autoPlay={false}
					autoPlaySpeed={1000000}
					responsive={responsive}
					ssr={false}
					infinite={true}
					keyBoardControl={false}
					containerClass="carousel-container"
					removeArrowOnDeviceType={["tablet", "mobile"]}
					dotListClass="custom-dot-list-style"
				>
					{list}
				</CarouselAny>
			</div>
		);
	}
}
