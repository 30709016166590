import { faAdd } from '@fortawesome/free-solid-svg-icons'

import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';

import LocStr$ from "../../localization/LocaleString";

import { getAppBase } from '../../utils/consts';

import { pageUrlPart, pageTitle, /*IRowType*/ } from "./OmcDevices";

import { TAB_DEVICE_INFO } from '../../datatypes/tabsconstants'

import AppContext from '../../context/AppContext'

import { useContext } from "react";

const omcDeviceRights = [
    "omc_mng_end_devices",
]

const detailPageNav = ( navigate, tab, deveui, row, prevPageUrl) => {
    if(deveui) {
        
        navigate(`${getAppBase()}/${pageUrlPart}/${deveui}/${tab}`, {state: {row: row, prevPageUrl}})
    }else {
        
        navigate(`${getAppBase()}/${pageUrlPart}/${tab}`, {state: {tab: tab, prevPageUrl}})
    }

}

export const OmcDeviceProps: DetailPagePropsType =  {
    getBreadCrumbArr: ( breadCrumbArgs  ) => {
        
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '';

        const breadCrumb:BreadCrumbType[] = [
            {label: pageTitle, url: prevPageUrl}
        ]
        return breadCrumb;
    },
    getActions : (id, navigate) => {

        const actions:DetailPageActionType[] = [];
        return actions;
    }
}


export const OmcDeviceRowProps: DetailPagePropsType =  {
    getBreadCrumbArr: (breadCrumbArgs) => {
        const {id, row} = breadCrumbArgs;
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '';

        const breadCrumb:BreadCrumbType[] = [
            {label: pageTitle, url: prevPageUrl},
            {label: `${id}`, url:'', comment: row.name}
        ]
        return breadCrumb;
    },
    getActions : (actionArgs, navigate) => {
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/${pageUrlPart}`;
        /* We are here checking of specific rights related to gateway in user and 
        if anyone rights is present then Click to Unlock button is shown on UI
        */
        const AppContextObj = useContext(AppContext);
        const user = AppContextObj.user;
        const __readonly = omcDeviceRights.filter(x => Object.keys(user).includes(x)).length > 0

        const { row }  = actionArgs;

        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: LocStr$("Device Info"),
                icon: faAdd,
                key: TAB_DEVICE_INFO,
                action: (row) => detailPageNav(navigate, TAB_DEVICE_INFO, row.id, row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/device_info/jsonnodelistview?get_schema=dassui,get`,
                    backUrl: prevPageUrl
                },
                EditObject: { deveui: row.deveui ? row.deveui : row.id, __readonly: __readonly }
            },
        ]
        return actions;
    }
}
