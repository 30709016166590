// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
textarea:focus, input:focus{
    outline: none;
}


.input-height {
    height:50px;
}`, "",{"version":3,"sources":["webpack://./app_react/src/components/Common/InputControl.css"],"names":[],"mappings":";AACA;IACI,aAAa;AACjB;;;AAGA;IACI,WAAW;AACf","sourcesContent":["\ntextarea:focus, input:focus{\n    outline: none;\n}\n\n\n.input-height {\n    height:50px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
