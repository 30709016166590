// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schema-modal-stock-chart {
    width: 100%;
    height: 500px;
}
  `, "",{"version":3,"sources":["webpack://./app_react/src/components/SchemaModal/ExtAmChart.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;AACjB","sourcesContent":[".schema-modal-stock-chart {\n    width: 100%;\n    height: 500px;\n}\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
