import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { strings } from "../../services/Localization";
import PageContent from "../PageContent";
import { Modal, Button } from 'react-bootstrap';
import { ActionType, BulkActionType, ColumnType, OptionType } from '../../components/Common/DataTable/DataTypes';
import { faTrashCan, faRefresh, faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import { faAdd } from "@fortawesome/pro-solid-svg-icons";
import { DEFAULT_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT, ID_INPUT_VALIDATION } from "../../components/Common/DataTable/DataTableConsts";
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import _ from 'lodash';
import { IUser } from "../../dassTypes";
import { getAppBase } from "../../utils/consts";
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "./../../utils/Toaster";
import { dateTimeString } from '../../utils/filters';

interface IMyCampaignsStates {
    loggedUser: IUser | null;
    editCampaignId: string;
    addCampaign: boolean;
    showAlertModal: boolean;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    camapignid: string;
    selectedAction: string;
    showLoadingDialog: boolean;
    showConfirmDialog: boolean;
    confirmDialogeSettings: {
        title: string;
        description: string;
        actionLabel: string;
        confirmAction: () => void;
    },
    refresh: boolean;
    exportInit: number;

};

const MyCampaigns: React.FC<{}> = () => {
    const navigate = useNavigate();
    let { campaignid, tabname } = useParams();

    const [showDeleteModal,  setShowDeleteModal] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    
    const stateInit = {
        loggedUser: null,
        editCampaignId: "",
        addCampaign: false,
        pageTitle: strings.MY_APPLICATIONS_TITLE,     // should be translated
        showAlertModal: false,
        breadCrumbArr: [{label: strings.NAV_DEVICES_ITEM, url:`${getAppBase()}/my-devices`},{label: strings.NAV_FUOTA_CAMPAIGNS_ITEM, url:''}],
        camapignid: '',
        selectedAction: '',
        showLoadingDialog: false,
        showConfirmDialog: false,
        confirmDialogeSettings: {
            title: '',
            description: '',
            actionLabel: '',
            confirmAction: () => { }
        },
        refresh: false,
        exportInit: 0,
    };

    const startCampaign = async ( row ) => {

        try {
            await GenericDassQuery(`/rest/fuota/campaigns/${row.campaignId}/action/start`, {
                method: "POST",
            });
            toast.success("Campaign Started !!");
        } catch (e) {
            toast.error(e.message);
        }
        refreshTable();
    }

    const campaignSession = async (row) => {
        let prevPageUrl = `${getAppBase()}/my-campaigns`;
        navigate(`${getAppBase()}/my-campaigns/${row.campaignId}`, {state: {row: row, campaignId:row.campaignId, prevPageUrl}, replace:false})
    }

    const stopCampaign = async ( row ) => {
        try {
            await GenericDassQuery(`/rest/fuota/campaigns/${row.campaignId}/action/stop`, {
                method: "POST",
            });
            toast.success("Campaign Stopped !!");
        } catch (e) {
            toast.error(e.message);
        }
        refreshTable();
    }

    const deleteSelectedCampaign = async() => {
        try {
            await GenericDassQuery(`/rest/fuota/campaigns/${selectedCampaign.campaignId}`, {
                method: "DELETE",
            }).then((success:any) => {
                if(success.status == 200) {
                    toast.success(`Campaign deleted successfully`);
                } else if (success.status == 202) {
                    toast.success(`Campaign has been marked as cancelled`);
                }
            }).catch((e) => {
                toast.error(e.message);
            })
        } catch (e) {
            toast.error(e.message);
        }
        handleClose()
        refreshTable();
    }

    const handleClose = () => {
        setShowDeleteModal(false);
        setSelectedCampaign(null);
    }

    const deleteCampaign = async ( row ) => {
        setShowDeleteModal(true);
        setSelectedCampaign(row);
    }

    const [deviceState, setDeviceState] = useState<IMyCampaignsStates>(stateInit);


    const refreshTable = () => {

        setDeviceState(prevState => {
            return { ...prevState, refresh: !prevState.refresh }
        })
    }

    const getVisible = (row) => {
        if(row.status == "In progress"){
            return true;
        }
        return false;
    }

    const getActions = useCallback(() => {
        let actions: ActionType[] = [
            {
                type: "action",
                text: strings.MY_CAMPAIGNS_ACTION_START,
                icon: faPlay,
                visible: () => true,
                action: (row) => startCampaign(row)
            },
            {
                type: "action",
                text: strings.MY_CAMPAIGNS_ACTION_STOP,
                icon: faPause,
                visible: (row) => getVisible(row),
                action: (row) => {stopCampaign(row) }
            }, {
                type: "action",
                text: strings.MY_DEVICES_ACTION_DELETE,
                icon: faTrashCan,
                visible: () => true,
                action: (row) => { deleteCampaign(row) }
            },
        ];

        let bulkActions: BulkActionType[] = [];


        return {
            actions: actions,
            bulkActions: bulkActions
        }
    }, []);
    const getPageButtons = useCallback(() => {

        const pageButtons: PageButtonType[] = [
            {
                title: strings.ADD_FUOTA_CAMPAIGN_BTN,
                action: () => {
                    navigate(`${getAppBase()}/my-campaigns/create`);
                },
                type: 'button_with_icon',
                icon: faAdd
            },
            {
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            },
        ]

        return pageButtons;
    }, []);

    const initDataTable = () => {

        let actions: any = [];
        let pageButtons: PageButtonType[] = [];

        actions = getActions();
        // const renderDeviceIds = (row) => {
        //     const list = row.devices.map(device => {
        //         return <div>{device}</div>
        //     })
        //     return list;
        // }

        let columns: ColumnType[] = [
            {
                key: "campaignName",
                type: "text",
                title: strings.CAMPAIGN_NAME,
                maxInputLength: 40,
                filterable: true,
                filterField: "search_campaign_name",
                filterType: "text",
                inputValidation: ID_INPUT_VALIDATION,
                // filterParams: {
                //     mapper: x => (x || "").replace(new RegExp("-|:", 'g'), "")
                // },
                sortable: true,
                sortKey: "sort_by_campaign_name",
                cellWidth: 20,
                detailLink: true,
                copyLink: false,
                detailPageNav: (row) =>  campaignSession(row),
            },
            {
                key: "description",
                type: "text",
                title: strings.CAMPAIGN_DESCRIPTION,
                maxInputLength: 40,
                filterable: true,
                filterField: "search_description",
                filterType: "text",
                inputValidation: ID_INPUT_VALIDATION,
                // filterParams: {
                //     mapper: x => (x || "").replace(new RegExp("-|:", 'g'), "")
                // },
                sortable: true,
                sortKey: "sort_by_description",
                cellWidth: 20,
                copyLink: false,
            },
            {
                key: "firmwareName",
                title: strings.CAMPAIGN_FIRMWARE,
                type: "text",
                filterable: true,
                filterField: "search_firmware",
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: true,
                sortKey: "sort_by_firmware",
                filterParams: {},
                cellWidth: 10,
            },
            {
                key: "devices",
                title: strings.CAMPAIGN_DEVICES,
                type: "text",
                filterable: true,
                filterField: "num_devices",
                filterType: "text",
                dataAlign: 'center',
                render: (row) => { return row?.devices?.length || 0 },
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: true,
                sortKey: "sort_by_num_devices",
                filterParams: {},
                cellWidth: 5,
            },
            {
                key: "sessions",
                title: strings.CAMPAIGN_GROUPS,
                type: "text",
                render: (row) => {return row?.sessions?.length || 0},
                filterable: false,
                filterField: "search_comment",
                filterType: "text",
                dataAlign: 'center',
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: false,
                sortKey: "sort_by_comment",
                filterParams: {},
                cellWidth: 'nowrap'
                
            },
            {
                key: "status",
                title: strings.CAMPAIGN_TYPE,
                type: "text",
                filterable: true,
                filterField: "search_status",
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: true,
                sortKey: "sort_by_status",
                filterParams: {},
                
            },
            {
                key: "stage",
                title: strings.CAMPAIGN_STAGE,
                type: "text",
                filterable: true,
                filterField: "search_stage",
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: true,
                sortKey: "sort_by_stage",
                filterParams: {},
                
            },
            {
                key: "startDate",
                title: strings.CAMPAIGN_UPLOAD_DATE,
                type: "text",
                filterable: false,
                render: (row) => { return dateTimeString(row?.startDate)},
                filterField: "search_comment",
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: true,
                sortKey: "sort_by_start_time",
                filterParams: {},
                
            },
            {
                key: "endDate",
                title: strings.CAMPAIGN_END_TIME,
                type: "text",
                render: (row) => { return dateTimeString(row?.endDate)},
                filterable: false,
                filterField: "search_comment",
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: true,
                sortKey: "sort_by_end_time",
                filterParams: {},
                
            },
            {
                key: 'action_button',
                type: "action_button",
                title: 'Actions',
                filterable: false,
                cellWidth: 3,
            },
        ];

        let options: OptionType = {
            url: '/uiapi/rest/fuota/campaigns',
            query_param: { all: true, get_pages: true, limit: DEFAULT_RECORD_LIMIT, stream: 'progress' },
            serial_number: false,
            id_field: 'deveui',
            oboe_path: 'pages.*',
            available_key: 'deveui',
            allowBulkActions: true,
            // defaultSortField: 'sort_by_deveui',
            // defaultSortOrder: 'asc',
            emptyDataMsg: '<b>Sorry!</b> No FUOTA Campaign Available',
            emptyDataButton: {
                title: strings.FUOTA_BTN,
                action: () => { navigate(`${getAppBase()}/my-campaigns/create`)},
                type: 'button_with_icon',
                icon: faAdd
            },

            
        }

        return {
            ...actions,
            columns: columns,
            ...options,
            pageButons: pageButtons
        };
    }

    let refresh = deviceState.refresh;

    return (
        <>
        <PageContent
            name="my-campaigns"
            id={campaignid}
            tabname={tabname}
            actions={getActions()}
            breadCrumbArr={deviceState.breadCrumbArr}
            pageButtons={getPageButtons()}
            countLabel={`FUOTA Campaigns`}
            dataTableOption={initDataTable()}
            exportInit={deviceState.exportInit}
            refresh={refresh}></PageContent>
            <Modal
            show={showDeleteModal}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header className="border-bottom-0" closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                                Fuota Campaign
                            </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                Are you sure you want to delete this FUOTA campaign?
            </Modal.Body>
            <Modal.Footer className="border-top-0">
                <Button variant="outline-dark" size="sm" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="dark" size="sm" onClick={deleteSelectedCampaign}>
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default MyCampaigns;