
import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';

import { faAdd, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { getAppBase } from '../../utils/consts';
import { strings } from "../../services/Localization";
import { TAB_TAG_INFO, TAB_TAG_ADD, TAB_TAG_CATEGORY_ADD, TAB_TAG_CATEGORY_DELETE } from '../../datatypes/tabsconstants'

const detailPageNav = ( navigate, tab, id, row = null, prevPageUrl) => {
    if(id) {
        
        navigate(`${getAppBase()}/gateway-tags/${id}/${tab}`, {state: {row: row, prevPageUrl:prevPageUrl}})
    }
}




const GatewayTagsProps: DetailPagePropsType =  {
    getBreadCrumbArr: (args) => {
        let prevPageUrl = args.hasOwnProperty('prevPageUrl') ? args['prevPageUrl'] : `${getAppBase()}/gateway-tags`;

        const breadCrumb:BreadCrumbType[] = [
            {label:strings.NAV_GATEWAYS_ITEM, url:`${getAppBase()}/omc-gateways`},
            { label: "Gateway Tags", url:prevPageUrl },
            { label:  "Add Tag", url:''}
        ]
        return breadCrumb;
    },
    getActions : (args, navigate) => {
        
        let prevPageUrl = args.hasOwnProperty('prevPageUrl') ? args['prevPageUrl'] : `${getAppBase()}/gateway-tags`

        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: "Add tag",
                icon: faAdd,
                key: TAB_TAG_ADD,
                action: (id) => detailPageNav(navigate, TAB_TAG_ADD, '', {}, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/tags?get_schema=dassui,post`,
                    backUrl: prevPageUrl
                },
            }
        ];
        return actions;
    }
}

export { GatewayTagsProps };



const GatewayTagsRowProps: DetailPagePropsType =  {
    getBreadCrumbArr: (args) => {
        
        let prevPageUrl = args.hasOwnProperty('prevPageUrl') ? args['prevPageUrl'] : `${getAppBase()}/gateway-tags`
        const { row } = args;
        const breadCrumb:BreadCrumbType[] = [
            {label:strings.NAV_GATEWAYS_ITEM, url:`${getAppBase()}/omc-gateways`},
            {label: "Gateway Tags", url:prevPageUrl},
            {label: `Tag Info`, url:'', comment: row.name}
        ]
        return breadCrumb;
    },
    getActions: (args, navigate) => {
        
        let prevPageUrl = args.hasOwnProperty('prevPageUrl') ? args['prevPageUrl'] : `${getAppBase()}/gateway-tags`
        
        const { row } = args;

        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: "Edit Tag",
                icon: faEdit,
                key: TAB_TAG_INFO,
                action: (row) => detailPageNav(navigate, TAB_TAG_INFO, row.id, row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/tags?get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: row.tagid ? { tagid: row.tagid } : {tagid:row.id}
            }
        ];

        return actions;
    }
}


export { GatewayTagsRowProps }


const GatewayTagCategorysProps: DetailPagePropsType =  {
    getBreadCrumbArr: (args) => {
        
        let prevPageUrl = args.hasOwnProperty('prevPageUrl') ? args['prevPageUrl'] : `${getAppBase()}/gateway-tags`
        const { row } = args;
        const breadCrumb:BreadCrumbType[] = [
            {label:strings.NAV_GATEWAYS_ITEM, url:`${getAppBase()}/omc-gateways`},
            {label: "Gateway Tags", url:prevPageUrl},
            {label: `Tag Category`, url:'', comment: row.name}
        ]
        return breadCrumb;
    },
    getActions: (args, navigate) => {
        
        let prevPageUrl = args.hasOwnProperty('prevPageUrl') ? args['prevPageUrl'] : `${getAppBase()}/gateway-tags`

        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: "",
                icon: faAdd,
                key: TAB_TAG_CATEGORY_ADD,
                action: (id) => detailPageNav(navigate, TAB_TAG_CATEGORY_ADD, '', {}, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/tags?get_schema=dassui,post_tagcategory`,
                    backUrl: prevPageUrl
                },
            }
           
        ];

        return actions;
    }
}

export { GatewayTagCategorysProps }

const GatewayTagCategorysDeleteProps: DetailPagePropsType =  {
    getBreadCrumbArr: (args) => {
        
        let prevPageUrl = args.hasOwnProperty('prevPageUrl') ? args['prevPageUrl'] : `${getAppBase()}/gateway-tags`
        const { row } = args;
        const breadCrumb:BreadCrumbType[] = [
            {label:strings.NAV_GATEWAYS_ITEM, url:`${getAppBase()}/omc-gateways`},
            {label: "Gateway Tags", url:prevPageUrl},
            {label: `Tag Category`, url:'', comment: row.name}
        ]
        return breadCrumb;
    },
    getActions: (args, navigate) => {
        
        let prevPageUrl = args.hasOwnProperty('prevPageUrl') ? args['prevPageUrl'] : `${getAppBase()}/gateway-tags`

        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: "",
                icon: faTrashAlt,
                key: TAB_TAG_CATEGORY_DELETE,
                action: (id) => detailPageNav(navigate, TAB_TAG_CATEGORY_DELETE, '', {}, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/omc/tags?get_schema=dassui,delete_tagcategory`,
                    backUrl: prevPageUrl
                },
            }
           
        ];

        return actions;
    }
}

export { GatewayTagCategorysDeleteProps }