import React, {useState, useEffect, useContext} from "react";

import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
//import { DataTable, PageHeader , DetailPageContainer, DataTableWrapper} from '../../components/Common';
import {  DataTableOption, ColumnType } from '../../components/Common/DataTable/DataTypes';
declare const constants;
import { /*Outlet,*/ useParams  } from 'react-router-dom';

import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { dateTimeString, } from '../../utils/filters';
import { IUser } from "src/dassTypes";

import { appBaseUrl, getAppBase } from '../../utils/consts';

import { BreadCrumbType } from '../../datatypes/datatypes';
import AppContext from '../../context/AppContext'

import PageContent from "../PageContent";
import { DEFAULT_RECORD_LIMIT } from "../../components/Common/DataTable/DataTableConsts";
import RenderTagsOverlay from "../../components/Common/RenderTagsOverlay";
interface IActiveConnectionsStates {
    loggedUser: IUser | null;
    showDeviceList: boolean;
    showAlertModal: boolean;
    pageTitle: string;
    editUserId: string | null;
    devUiId: string;
    url: string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
}

interface IActiveConnection {
    appid: string;
    connection_time?: string;
    remote_address?: string;
    applications?: string; 
    status: string;
    subscription: string;
    type:  string;
    url?: string;
}
type IRowType = IActiveConnection;


const ActiveConnections: React.FC<{}> = () => {

    
    const AppContextObj = useContext(AppContext);

    let { id, tabname } = useParams();
    
    const stateInit = {
        loggedUser: null,
        showDeviceList: false,
        showAlertModal: false,
        pageTitle: strings.ACTIVE_CONNECTIONS_PAGE_TITLE,
        url: '',
        editUserId:'',
        devUiId: '',
        breadCrumbArr: [{label: strings.NAV_APPLICATIONS_ITEM, url:`${getAppBase()}/my-apps`}, {label: strings.ACTIVE_CONNECTIONS_NAV_TITLE, url:''}],
        refresh:false
    };
    
    const [state, setState] = useState<IActiveConnectionsStates>(stateInit);

    useEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {
            //LoggedIn();
            if(checkLogin(AppContextObj.user)) {
                setState(prevState => { return {...prevState, loggedUser: AppContextObj.user}})
            }else {
                window.location.href = appBaseUrl + "/signin";
            }
        }
    },[])
    

    const getActions = () => {
        
        return {
            actions: [],
            bulkActions:[]
        }
    }

    const mapUrlToRemoteAddress = (row: IRowType) => {
        if(row.url) {
            row.remote_address = row.url
        }
        return row.remote_address;
    }

    const initDataTable = () => {
		
        const actions = getActions();
        
        const columns: ColumnType<IRowType>[] = [{
            key: 'bulk_action_checkbox',
            type: "bulk_action_checkbox",
            title: 'Bulk Action',
            filterable: false,
            cellWidth: 2,
            newCellWidth: "2%"
        },{
			key: "type",
			title: strings.ACTIVE_CONNECTIONS_TABLE_COL_TYPE,
			type: "text",
            inputValidation:"^([a-z0-9\\s\\-\\.])*$",
			filterable: true,
			filterField: "type",
			filterType: 'text',
            newCellWidth: "250px"
			
		}, {
			key: "status",
			title: strings.ACTIVE_CONNECTIONS_TABLE_COL_STATUS,
			type: "text",
			filterable: false,
            filterType: 'text',
            filterField: 'status',
            newCellWidth: "100px"
          
		}, 
        {
			key: "connection_time",
			title: strings.ACTIVE_CONNECTIONS_TABLE_COL_CONN_TIME,
			type: "text",
            inputValidation:"^([a-z0-9\\s\\-\\.\\:])*$",
			filterable: false,
            filterType: 'daterange',
            filterParams: {
                startField: "from_date",
                endField: "to_date",
                mapper: (x) => x && x.format()
            },
            render: x => dateTimeString(x.connection_time),
			filterField: 'connection_time',
            newCellWidth: "180px",
		},
        {
			key: "appid",
			title: strings.ACTIVE_CONNECTIONS_TABLE_COL_APPLICATION,
			type: "text",
            // render_tooltip: x => x.applications?.replace(/,/g, ', '),
            render: x => { return RenderTagsOverlay(x.appid, 'blue', `${getAppBase()}/my-apps/~tag~/edit-app`,{prevPageUrl:`${getAppBase()}/active_connections`,row: { accountid:0 }},"Applications")},
			filterable: true,
            filterField: 'appid',
			filterType: 'text',
            newCellWidth: "200px",
		
		},        
		{
			key: "remote_address",
			title: strings.ACTIVE_CONNECTIONS_TABLE_COL_REMOTE_ADDRESS,
			type: "text",
            render: x =>  mapUrlToRemoteAddress(x),
            newCellWidth: "200px",
		},
		{
			key: "subscription",
			title: strings.ACTIVE_CONNECTIONS_TABLE_COL_SUBSCRIPTIONS,
			type: "text",
			filterable: false,
            filterType: 'text',
            render: x => x.subscription.replace(/,/g, ', '),
			filterField: 'subscription',
            newCellWidth: "200px",
		},
        {
			key: "error",
			title: strings.ACTIVE_CONNECTIONS_TABLE_COL_ERRORS,
			type: "text",
			filterable: false,
            filterType: 'text',
			filterField: 'error',
            newCellWidth: "150px"
		}
        ];

        
        const options: DataTableOption<IRowType> = {
            url: '/rest/pushmode/connections',
            serial_number: false,
            query_param:{all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'simple', sort_by_timestamp: 'desc',mac_msg: false},
            oboe_path:'pages.*',
            ...actions,
            columns,
            allowBulkActions: false,
        }

        return options ;
	}


    const refreshTable = () => {
        
        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })
    }
    
    const getPageButtons = () => {
        
        var  pageButtons = [
            {
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            },
        ]

        return pageButtons;
    }

    // deletePayload = (packat) => {}
    
    // rePushPayloadUL = (obj) => {}
    
    // deletePayloads = () => {}

    if (constants.enable_profiles !== true) {
        return <div>Access Denied</div>;
    } else  {
        return (<PageContent
                    name={`active-connection`} 
                    id={id} 
                    tabname={tabname} 
                    actions={getActions()} 
                    breadCrumbArr={state.breadCrumbArr} 
                    pageButtons={getPageButtons()} 
                    countLabel={`Active Connections`} 
                    dataTableOption={initDataTable()} 
                    refresh={state.refresh}>
            </PageContent>)

       
    }
    
}


export default ActiveConnections;