import React from 'react';

interface MapMarkerProps {
    colorCode: string;
}

const DeviceMapMarker:React.FC<MapMarkerProps> = ({ colorCode }) => {
    return (
      <svg width="34" height="46.065" viewBox="0 0 34 46.065">
      <defs>
        <filter id="Path_94" x="0" y="0" width="34" height="46.065" filterUnits="userSpaceOnUse">
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.5" result="blur"/>
          <feFlood floodOpacity="0.278"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <clipPath id="clipPath">
          <rect id="Rectangle_141" data-name="Rectangle 141" width="14.272" height="17.553" fill="#fff"/>
        </clipPath>
      </defs>
      <g id="Group_282" data-name="Group 282" transform="translate(4.5 1.5)">
        <g id="Group_271" data-name="Group 271" transform="translate(0 0)">
          <g id="Group_235" data-name="Group 235" transform="translate(0 0)">
            <g transform="matrix(1, 0, 0, 1, -4.5, -1.5)" filter="url(#Path_94)">
              <path id="Path_94-2" data-name="Path 94" d="M12.5,0A12.5,12.5,0,0,1,25,12.5c0,6.9-5.728,12.764-12.542,24.565C5.8,25.561,0,19.4,0,12.5A12.5,12.5,0,0,1,12.5,0Z" transform="translate(4.5 1.5)" fill={colorCode}/>
            </g>
          </g>
          <g id="Group_275" data-name="Group 275" transform="translate(5.5 5.581)">
            <g id="Group_274" data-name="Group 274" clipPath="url(#clip-path)">
              <path id="Path_661" data-name="Path 661" d="M10.8,312.249c-.327-.045-.656-.079-.98-.137a8.614,8.614,0,0,1-4.622-2.4.514.514,0,1,1,.716-.735,7.782,7.782,0,0,0,2.121,1.49A7.739,7.739,0,0,0,16.766,309a.538.538,0,0,1,.56-.161.482.482,0,0,1,.347.388.528.528,0,0,1-.184.506,8.377,8.377,0,0,1-3.683,2.16c-.58.159-1.186.221-1.781.328-.044.008-.088.019-.132.028Z" transform="translate(-4.792 -294.695)" fill="#fff"/>
              <path id="Path_662" data-name="Path 662" d="M11.811,0c.389.056.781.1,1.167.171a8.546,8.546,0,0,1,4.44,2.359.511.511,0,0,1,.042.749.521.521,0,0,1-.763-.019,7.418,7.418,0,0,0-3.589-2A7.6,7.6,0,0,0,5.966,3.145c-.059.054-.115.112-.176.163a.517.517,0,0,1-.712-.032A.5.5,0,0,1,5.1,2.553a15.207,15.207,0,0,1,1.367-1.11A8.34,8.34,0,0,1,10.582.028.969.969,0,0,0,10.714,0Z" transform="translate(-4.709)" fill="#fff"/>
              <path id="Path_663" data-name="Path 663" d="M92.359,138.328a2.571,2.571,0,1,1-2.564-2.575,2.571,2.571,0,0,1,2.564,2.575m-1.029-.008a1.542,1.542,0,1,0-1.544,1.546,1.542,1.542,0,0,0,1.544-1.546" transform="translate(-83.23 -129.547)" fill="#fff"/>
              <path id="Path_664" data-name="Path 664" d="M41.569,279.753A6.61,6.61,0,0,1,37,277.828a.515.515,0,0,1-.044-.749.505.505,0,0,1,.749,0,5.678,5.678,0,0,0,7.879.086.66.66,0,0,1,.5-.249.484.484,0,0,1,.45.341.515.515,0,0,1-.147.563,6.669,6.669,0,0,1-2.2,1.447,6.969,6.969,0,0,1-2.618.482" transform="translate(-35.133 -264.257)" fill="#fff"/>
              <path id="Path_665" data-name="Path 665" d="M41.774,45a6.614,6.614,0,0,1,4.591,1.913.526.526,0,0,1,.055.763.52.52,0,0,1-.776-.03,5.671,5.671,0,0,0-7.817-.1,1.385,1.385,0,0,1-.324.247.5.5,0,0,1-.615-.2.512.512,0,0,1,.086-.656,6.618,6.618,0,0,1,2.409-1.531A7.008,7.008,0,0,1,41.774,45" transform="translate(-35.124 -42.943)" fill="#fff"/>
              <path id="Path_666" data-name="Path 666" d="M72.021,89.957a4.633,4.633,0,0,1,3.233,1.305.518.518,0,1,1-.7.75,3.593,3.593,0,0,0-5.026,0,.523.523,0,0,1-.763.029.516.516,0,0,1,.055-.772,4.616,4.616,0,0,1,3.206-1.309" transform="translate(-65.475 -85.845)" fill="#fff"/>
              <path id="Path_667" data-name="Path 667" d="M72.09,247.432a4.667,4.667,0,0,1-3.269-1.31.517.517,0,0,1-.066-.76.523.523,0,0,1,.775.018,3.6,3.6,0,0,0,4.849.157c.07-.059.134-.124.2-.185a.52.52,0,0,1,.739,0,.512.512,0,0,1-.024.737,4.571,4.571,0,0,1-2.676,1.3c-.192.025-.387.031-.53.042" transform="translate(-65.481 -233.989)" fill="#fff"/>
              <path id="Path_668" data-name="Path 668" d="M240.692,136.756c-.221,0-.415,0-.609,0a.514.514,0,1,1,.006-1.027q1.122,0,2.245,0a.514.514,0,1,1-.007,1.027c-.193,0-.387,0-.607,0v.208q0,1.628,0,3.256a1.355,1.355,0,0,1-.01.2.513.513,0,0,1-.523.445.505.505,0,0,1-.491-.5c-.009-.262,0-.525,0-.788q0-1.311,0-2.622v-.207" transform="translate(-228.596 -129.522)" fill="#fff"/>
              <path id="Path_669" data-name="Path 669" d="M0,141.188q0-.959,0-1.919a.513.513,0,0,1,.518-.572.519.519,0,0,1,.509.569q0,1.936,0,3.872a.524.524,0,0,1-.514.566A.515.515,0,0,1,0,143.141c0-.651,0-1.3,0-1.953" transform="translate(0 -132.356)" fill="#fff"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
    
    
    
  )
}

export { DeviceMapMarker }

const GateWayMapMarker:React.FC<MapMarkerProps> = ({ colorCode }) => {

    return (
      <svg width="34" height="46.065" viewBox="0 0 34 46.065">
      <defs>
        <filter id="Path_94" x="0" y="0" width="34" height="46.065" filterUnits="userSpaceOnUse">
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.5" result="blur"/>
          <feFlood floodOpacity="0.278"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <clipPath id="clipPath">
          <rect id="Rectangle_140" data-name="Rectangle 140" width="14.203" height="18.525" fill="#fff"/>
        </clipPath>
      </defs>
      <g id="Group_286" data-name="Group 286" transform="translate(4.5 1.5)">
        <g id="Group_271" data-name="Group 271" transform="translate(0 0)">
          <g id="Group_235" data-name="Group 235">
            <g transform="matrix(1, 0, 0, 1, -4.5, -1.5)" filter="url(#Path_94)">
              <path id="Path_94-2" data-name="Path 94" d="M12.5,0A12.5,12.5,0,0,1,25,12.5c0,6.9-5.728,12.764-12.542,24.565C5.8,25.561,0,19.4,0,12.5A12.5,12.5,0,0,1,12.5,0Z" transform="translate(4.5 1.5)" fill={colorCode}/>
            </g>
          </g>
          <g id="Group_285" data-name="Group 285" transform="translate(5.399 5.69)">
            <g id="Group_267" data-name="Group 267" clipPath="url(#clip-path)">
              <path id="Path_656" data-name="Path 656" d="M42.482,84.526A.616.616,0,0,1,42.1,83.6q1.914-5.3,3.831-10.59a.206.206,0,0,0-.069-.271,2.046,2.046,0,0,1-.508-2.42,2.066,2.066,0,0,1,3.919.6,1.99,1.99,0,0,1-.664,1.807.222.222,0,0,0-.069.288q1.909,5.288,3.805,10.581a1.085,1.085,0,0,1,.064.49c-.033.243-.23.365-.449.442h-.217a.753.753,0,0,1-.5-.544c-.1-.3-.205-.591-.318-.883a.773.773,0,0,0-.159-.255q-1.7-1.741-3.4-3.475c-.04-.041-.083-.078-.137-.129-.058.064-.105.119-.156.171-.913.928-1.807,1.877-2.748,2.776a3.6,3.6,0,0,0-1.1,1.756.787.787,0,0,1-.485.584Zm4.761-7.036c.371-.355.773-.736,1.168-1.123a.2.2,0,0,0,.029-.172c-.333-.94-.673-1.878-1.008-2.818-.036-.1-.094-.118-.19-.113s-.165,0-.206.117c-.333.94-.676,1.877-1.011,2.817a.18.18,0,0,0,.02.156c.4.389.81.771,1.2,1.137m-.025-5.4a.892.892,0,1,0-.891-.9.895.895,0,0,0,.891.9m-1.7,5.488L44.6,80.143l1.818-1.732-.891-.835m3.42.011-.891.857,1.819,1.72-.927-2.576" transform="translate(-40.117 -66.001)" fill="#fff"/>
              <path id="Path_657" data-name="Path 657" d="M0,5.558A7.256,7.256,0,0,1,2.065.313a.584.584,0,1,1,.869.78,12.712,12.712,0,0,0-.911,1.186,5.7,5.7,0,0,0-.763,4.028A5.76,5.76,0,0,0,2.7,9.272c.085.094.174.185.253.283a.573.573,0,0,1-.061.811.564.564,0,0,1-.8-.033A12.2,12.2,0,0,1,1.168,9.2a6.321,6.321,0,0,1-1-2.4C.071,6.31.032,5.81,0,5.558" transform="translate(0 -0.109)" fill="#fff"/>
              <path id="Path_658" data-name="Path 658" d="M248.751,5.505a6.949,6.949,0,0,1-2.026,4.68.593.593,0,0,1-.654.185.555.555,0,0,1-.387-.48.666.666,0,0,1,.2-.522,10.585,10.585,0,0,0,.844-1.1,5.783,5.783,0,0,0,.665-4.556,5.668,5.668,0,0,0-1.306-2.445c-.085-.094-.171-.186-.253-.284a.58.58,0,0,1,.031-.815.559.559,0,0,1,.811.017,9.442,9.442,0,0,1,.891,1.089,7.267,7.267,0,0,1,1.186,4.233" transform="translate(-234.548 0)" fill="#fff"/>
              <path id="Path_659" data-name="Path 659" d="M206.117,45.229a4.377,4.377,0,0,1-1.259,3.081.585.585,0,0,1-.84.056.569.569,0,0,1-.024-.839,3.3,3.3,0,0,0,.892-1.683,3.188,3.188,0,0,0-.769-2.787c-.047-.055-.1-.106-.145-.162a.584.584,0,0,1,.87-.778,4.434,4.434,0,0,1,.944,1.421,4.334,4.334,0,0,1,.331,1.69" transform="translate(-194.588 -40.026)" fill="#fff"/>
              <path id="Path_660" data-name="Path 660" d="M59.266,45.408a4.575,4.575,0,0,1,1.271-3.283.585.585,0,0,1,.828-.05.576.576,0,0,1,.029.839,3.274,3.274,0,0,0-.943,2.113,3.194,3.194,0,0,0,.767,2.3c.062.074.13.142.192.216a.586.586,0,0,1-.036.815.575.575,0,0,1-.814-.019,4.22,4.22,0,0,1-1.134-1.981c-.091-.372-.128-.757-.16-.954" transform="translate(-56.581 -40.027)" fill="#fff"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
    )
}

export { GateWayMapMarker }

const GateWayMapMarkerV2:React.FC<MapMarkerProps> = ({ colorCode }) => {
  return (
    <svg version="1.0" 
          width="101.000000pt" height="149.000000pt" viewBox="0 0 101.000000 149.000000"
          preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,149.000000) scale(0.100000,-0.100000)"
          fill={colorCode} stroke="none">
          <path d="M405 1478 c-134 -25 -279 -132 -343 -254 -107 -205 -71 -406 135
              -734 60 -96 235 -373 280 -443 l24 -38 62 103 c35 57 117 186 182 288 193 298
              255 442 255 584 0 314 -287 553 -595 494z m-30 -249 c4 -5 -5 -27 -19 -48 -47
              -67 -47 -148 -1 -216 14 -20 23 -41 20 -46 -12 -19 -33 -8 -61 32 -50 73 -44
              191 13 259 26 32 38 36 48 19z m318 -41 c47 -76 38 -193 -21 -260 -18 -22 -42
              -23 -42 -3 0 8 12 31 26 52 23 33 25 46 22 103 -2 48 -9 74 -27 98 -23 33 -22
              67 2 60 6 -3 25 -25 40 -50z m-259 -17 c3 -4 -4 -25 -14 -47 -11 -21 -20 -43
              -20 -49 0 -6 9 -28 20 -49 20 -40 19 -56 -4 -56 -15 0 -39 33 -51 68 -18 58
              43 176 69 133z m187 -23 c36 -59 11 -178 -38 -178 -17 0 -16 30 2 54 20 26 19
              76 -1 104 -16 23 -13 52 6 52 6 0 19 -15 31 -32z m-74 -30 c26 -24 29 -45 12
              -82 -10 -21 -3 -49 51 -197 48 -133 59 -174 49 -180 -14 -9 -39 7 -39 25 -1 6
              -27 39 -59 73 l-58 63 -62 -64 c-33 -35 -61 -71 -61 -79 0 -21 -29 -30 -36
              -12 -4 9 21 92 55 186 57 159 60 172 46 193 -12 17 -13 30 -6 49 19 49 70 61
              108 25z"/>
          <path d="M487 1093 c-15 -14 -7 -43 12 -43 24 0 33 13 25 34 -6 16 -25 21 -37
          9z"/>
          <path d="M484 965 c-18 -44 -18 -55 4 -71 14 -10 20 -10 34 3 15 13 15 18 1
          59 -8 24 -17 44 -19 44 -3 0 -12 -16 -20 -35z"/>
          <path d="M542 857 c-9 -11 -6 -19 13 -37 l25 -24 -11 35 c-15 43 -14 42 -27
          26z"/>
          <path d="M440 851 c0 -5 -3 -18 -7 -28 -6 -17 -5 -17 15 1 26 22 28 36 7 36
          -8 0 -15 -4 -15 -9z"/>
          </g>
          </svg>
  )
}

export { GateWayMapMarkerV2 }