import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { strings } from "../../services/Localization";
import PageContent from "../PageContent";
import { ActionType, BulkActionType, ColumnType, OptionType } from '../../components/Common/DataTable/DataTypes';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import {
    faBoltAuto, faRefresh
 }  from '@fortawesome/pro-regular-svg-icons';
import { DEFAULT_INPUT_VALIDATION, ID_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT } from "../../components/Common/DataTable/DataTableConsts";

import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import _ from 'lodash';
import { IUser } from "../../dassTypes";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { getAppBase, autoRefreshInterval } from "../../utils/consts";
import { dateTimeString } from '../../utils/filters';


interface IMyCampaignsStates {
    loggedUser: IUser | null;
    editCampaignId: string;
    addCampaign: boolean;
    showAlertModal: boolean;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    camapignid: string;
    selectedAction: string;
    showLoadingDialog: boolean;
    showConfirmDialog: boolean;
    confirmDialogeSettings: {
        title: string;
        description: string;
        actionLabel: string;
        confirmAction: () => void;
    },
    refresh: boolean;
    exportInit: number;
    autoRefresh:boolean;
};

const MyCampaignsSessions: React.FC<{}> = () => {
    const navigate = useNavigate();

    let { campaignid, tabname } = useParams();

    const stateInit = {
        loggedUser: null,
        editCampaignId: "",
        addCampaign: false,
        pageTitle: strings.MY_APPLICATIONS_TITLE,     // should be translated
        showAlertModal: false,
        breadCrumbArr: [
            {label: strings.NAV_DEVICES_ITEM, url:`${getAppBase()}/my-devices`},
        {label: strings.NAV_FUOTA_CAMPAIGNS_ITEM, url:`${getAppBase()}/my-campaigns`},
        { label: "Campaign Sessions", url: '' }
    ],
        camapignid: '',
        selectedAction: '',
        showLoadingDialog: false,
        showConfirmDialog: false,
        confirmDialogeSettings: {
            title: '',
            description: '',
            actionLabel: '',
            confirmAction: () => { }
        },
        refresh: false,
        exportInit: 0,
        autoRefresh:false
    };

    const [deviceState, setDeviceState] = useState<IMyCampaignsStates>(stateInit)

    const refreshTable = () => {
        setDeviceState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })

    }

    const getActions = useCallback(() => {
        let actions: ActionType[] = [
            
        ];

        let bulkActions: BulkActionType[] = [];


        return {
            actions: actions,
            bulkActions: bulkActions
        }
    }, []);
    const getPageButtons = useCallback(() => {

        const pageButtons: PageButtonType[] = [
            {
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            },
            {
                title: (deviceState.autoRefresh) ?  strings.STOP_AUTO_REFRESH : strings.AUTO_REFRESH,
                action: () => {  toggleAutoRefresh() },
                type: 'button_with_icon',
                varient: 'primary',
                icon: faBoltAuto                
                
            },
        ]

        return pageButtons;
    }, [deviceState.autoRefresh]);

    const toggleAutoRefresh = () => {

        setDeviceState(prevState => { 
            return {...prevState, autoRefresh:!deviceState.autoRefresh}
        })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if(deviceState.autoRefresh) {
                refreshTable();
            }
        }, autoRefreshInterval);
        return () => clearInterval(interval);

    }, [deviceState.autoRefresh]);

   


    const renderProgressBar = ( row:any ) => {
      
        return <ProgressBarComp row={row} />

    }

    const sessionDevices = (row) => {
        let prevPageUrl = `${getAppBase()}/my-campaigns/${campaignid}`;
        navigate(`${getAppBase()}/my-campaigns/${campaignid}/${row.sessionId}`, {state: {row: row, sessionId:row.sessionId, campaignId:campaignid,prevPageUrl}, replace:false})
    }

    const renderFrames = (row) => {
        let noOfFrames = `${row?.fragmentConfig?.nbFrag} (+${row?.fragmentConfig?.nbRedunFrag})`;
        return noOfFrames;
    }
    const initDataTable = () => {

        let actions: any = [];
        let pageButtons: PageButtonType[] = [];

        actions = getActions();
        let columns: ColumnType[] = [
            {
                key: "fuotaGroup",
                type: "text",
                title: "FUOTA Group",
                maxInputLength: 40,
                render: (row) => { return row?.fuotaGroup?.groupName },
                filterable: true,
                detailLink: true,
                detailPageNav: (row) =>  sessionDevices(row),
                filterField: "search_group",
                filterType: "text",
                inputValidation: ID_INPUT_VALIDATION,
                // filterParams: {
                //     mapper: x => (x || "").replace(new RegExp("-|:", 'g'), "")
                // },
                sortable: true,
                sortKey: "sort_by_group",
                cellWidth: 10,
                customClass: 'font-monospace nowarp',
                newCellWidth: '150px'
            },
            {
                key: "fuotaGroup",
                title: 'Region',
                dataAlign: 'center',
                type: "text",
                filterable: true,
                filterField: "region",
                filterType: "text",
                render: (row) => { return row?.fuotaGroup?.region},
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: true,
                sortKey: "sort_by_region",
                filterParams: {},
                cellWidth: 'nowrap'
            },

            {
                key: "devices",
                type: "text",
                title: '#Devices',
                render: (row) => { return row?.devices?.length || 0 },
                maxInputLength: 40,
                dataAlign: 'center',
                filterable: true,
                filterField: "num_devices",
                filterType: "text",
                inputValidation: ID_INPUT_VALIDATION,
                // filterParams: {
                //     mapper: x => (x || "").replace(new RegExp("-|:", 'g'), "")
                // },
                sortable: true,
                sortKey: "sort_by_num_devices",
                cellWidth: 'nowrap',
                copyLink: false,
                customClass: 'font-monospace nowarp',
                // cellWidth: 'nowrap'
            },            
            {
                key: "status",
                title: 'Progress',
                type: "text",
                render: (row) => {
                    
                    return renderProgressBar(row)
                },
                filterable: false,
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: false,
                filterParams: {},
                cellWidth: 'nowrap'
            },
            {
                key: "status",
                title: 'Status',
                type: "text",
                // render: (row) => {
                    
                //     return renderProgressBar(row)
                // },
                filterable: false,
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: false,
                filterParams: {},
                cellWidth: 'nowrap'
            },
            {
                key: "stage",
                title: 'Stage',
                type: "text",
                // render: (row) => {
                    
                //     return renderProgressBar(row)
                // },
                filterable: false,
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: false,
                filterParams: {},
                cellWidth: 'nowrap'
            },
            {
                key: "fragmentConfig",
                title: 'Fragment Size',
                type: "text",
                dataAlign: 'center',
                filterable: true,
                filterField: "fragment_size",
                filterType: "text",
                render: (row) => { return row?.fragmentConfig?.fragSize},
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: true,
                sortKey: "sort_by_fragment_size",
                filterParams: {},
                cellWidth: 'nowrap',
            },
            {
                key: "fragmentConfig",
                title: '#Frames',
                dataAlign: 'center',
                type: "text",
                filterable: false,
                filterType: "text",
                render: (row) => { return renderFrames(row)},
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: false,
                filterParams: {},
                cellWidth: 'nowrap'
            },
            {
                key: "fuotaGroup",
                title: 'MC Group type',
                type: "text",
                dataAlign: 'center',
                filterable: true,
                filterField: "group_type",
                render: (row) => { return row?.fuotaGroup?.groupType},
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: true,
                sortKey: "sort_by_group_type",
                filterParams: {},
                cellWidth: 'nowrap',
            },
            {
                key: "fuotaGroup",
                title: 'Data Rate',
                type: "text",
                filterable: false,
                render: (row) => { return row?.fuotaGroup?.dataRate},
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: false,
                filterParams: {},
                
            },
            {
                key: "fuotaGroup",
                title: 'Frequency',
                dataAlign: 'center',
                type: "text",
                filterable: false,
                render: (row) => { return `${(row?.fuotaGroup?.updateFrequency/1000000)} [MHz]`},
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: false,
                filterParams: {},
                cellWidth: 'nowrap'
            },
            {
                key: "fuotaGroup",
                title: 'Unicast Timeout',
                type: "text",
                render: (row) => { return dateTimeString(row?.fuotaGroup?.startTime)},
                filterable: false,
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: false,
                filterParams: {},
                
            },
            {
                key: "fuotaGroup",
                title: 'Session Timeout',
                type: "text",
                filterable: false,
                render: (row) => { return dateTimeString(row?.fuotaGroup?.timeout)},
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: false,
                filterParams: {},
                
            }
        ];
        
        let options: OptionType = {
            url: '/uiapi/rest/fuota/sessions',
            query_param: {campaignid: campaignid, all: true, get_pages: true, limit: DEFAULT_RECORD_LIMIT, stream:'progress' },
            serial_number: false,
            id_field: 'sessionId',
            oboe_path: 'pages.*',
            available_key: 'sessionId',
            allowBulkActions: false,
            emptyDataMsg: '<b>Sorry!</b> No Session Available',
        }

        return {
            ...actions,
            columns: columns,
            ...options,
            pageButons: pageButtons
        };
    }

    let refresh = deviceState.refresh;

    return (
        <>
        <PageContent
            name="sessions"
            id={undefined}
            tabname={tabname}
            actions={getActions()}
            breadCrumbArr={deviceState.breadCrumbArr}
            pageButtons={getPageButtons()}
            countLabel={`FUOTA Campaigns Sessions`}
            dataTableOption={initDataTable()}
            exportInit={deviceState.exportInit}
            refresh={refresh}></PageContent>
        </>
    )
}


interface ProgressBarComProp {
    row:any;
}

const ProgressBarComp:React.FC<ProgressBarComProp> = React.memo(( props ) => {
    const [sessiondata, setSessionData] = React.useState(null);
    const getDeviceData = useCallback((row) => {
        // try {
            let response = GenericDassQuery(`/rest/fuota/sessions/${row?.sessionId}/devices`, {
                method: "GET",
            }).then((response) => {
                if(response && response.data) {
                    return response.data;
                }
            }).catch(()=> {
                return []
            })
        // } catch (e) {
        //     return []
        // }
        return response;
    },[])

    const calculateProgress = async (row) => {
        let  mainDeviceData:any = await getDeviceData(row)
        if(mainDeviceData && mainDeviceData.length) {
            const session = row;
            const report = session?.report;
            let total_device_progress = 0;
            let unicast = false;
            const unicast_type = session?.fuotaGroup['groupType'];
            const total_unicast_command = unicast_type === "C" ? 5 : unicast_type === 'A' ? 2 : 1;
            unicast = unicast_type === "C" ? false : unicast_type === 'A' ? true : false;
            session.progress = { devices: [], total: 0 };
            session.mcActivityLog = [];
            if (session.devices.length > 0) {
                for (const device of session.devices) {
                    let deviceData:any = [];
                    let activityLogs:any = []
                    
                    if(report){
                        deviceData = report ? report.find(i => i.deviceId === device) : null;
                        if (deviceData && deviceData.activityLog && !Array.isArray(deviceData?.activityLog)) {
                            deviceData['activityLog'] = deviceData.activityLog ? deviceData['activityLog'].split(',') : [];
                        }
                    } else {
                        deviceData = mainDeviceData ? mainDeviceData.find(i => i.deviceId === device) : null;
                    }
                    
                    if(deviceData) {
                        activityLogs = deviceData?.activityLog
                    } 
                    if (deviceData && deviceData !== null) {
                        if (!unicast) {
                            session.mcActivityLog.push({ device_id: device, deveui: deviceData.deveui, mcActivityLog: deviceData.activityLog.join(',') });
                        }
                        let fragIdx = 0, nbFrag = 1, nbRedunFrag = 1;
                        if(unicast){
                            fragIdx = deviceData.fragIdx ? deviceData.fragIdx : null;
                        } else {
                            fragIdx = session?.fragmentConfig?.fragIdx;
                        }
                        nbFrag = +session.fragmentConfig['nbFrag'];
                        nbRedunFrag = +session.fragmentConfig['nbRedunFrag'];
    
                        const progress_fragment_phase: number = fragIdx ? Number((Math.min(nbFrag, fragIdx) / nbFrag) * 100) : 0;
                        let progress_redun_fragment_phase: number = 0;
    
                        if (deviceData.activityLog.find(i => i.includes('fragDataBlockReceivedReq'))) {
                            progress_redun_fragment_phase = 100;
                        } else {
                            progress_redun_fragment_phase = fragIdx > nbFrag ? Number((fragIdx - nbFrag) / nbRedunFrag) * 100 : 0;
                        }
    
                        let sent_unicast_command = 0, sent_reboot_command = 0;
    
                        if (unicast_type === "C") {
                            const commands = ["mcPktMsgAck", "fmPktMsgAck", "mcSetupMsgAck", "mcTimedSessMsgAck", "fmSetupMsgAck"]
                            sent_unicast_command = commands.reduce((a, c) => a + activityLogs.includes(c), 0);
                        } else if (unicast_type === "A" && (activityLogs.includes("fmPktMsgAck") || activityLogs.includes("fmSetupMsgAck"))) {
                            sent_unicast_command++;
                        }
    
                        const rebootCommands = ['rebootMsgReq', 'rebootMsgAck', 'Device rebooted'];
                        sent_reboot_command = rebootCommands.reduce((a, c) => a + activityLogs.includes(c), 0);
                        if (activityLogs.find(i => i.includes('Finished(Upgrade success)' || i.includes('Finished(Upgrade Failure)')))) {
                            sent_reboot_command++;
                        }
                        const progress_unicast_phase: number = Number((sent_unicast_command / total_unicast_command) * 100);
                        const progress_reboot_phase: number = Number((sent_reboot_command / 4) * 100);
                        const activity_logs = sendActivityLogsPhasewise(activityLogs);
    
                        let deviceProgress = { device_id: device, deveui: deviceData.deveui, progress_fragment_phase: progress_fragment_phase, progress_redun_fragment_phase: progress_redun_fragment_phase, progress_unicast_phase: progress_unicast_phase, progress_reboot_phase: progress_reboot_phase, activity_logs: activity_logs };
                        if (session.status == "Running") {
                            deviceProgress['current_phase'] = getCurrentPhaseFromDeviceSessionProgress(deviceProgress);
                        } else {
                            deviceProgress['current_phase'] = { phase_name: session.status, progress: 0, in_progress: false }
                        }
                        session.progress['devices'].push(deviceProgress);
                        const progress = Number(((progress_unicast_phase + progress_fragment_phase + progress_redun_fragment_phase + progress_reboot_phase) / 4).toFixed(2));
                        total_device_progress += progress;
                        session.progress['total'] = Number((total_device_progress / session.devices.length).toFixed(2));
                    }
                }

                if(session && session?.progress && session?.progress?.devices) {
                    return session;
                    
                }

            }
        }
        return row;
    }

    
    const sendActivityLogsPhasewise = (activityLogs) => {
        activityLogs = activityLogs.includes('.') ? activityLogs.filter(i => i !== '.') : activityLogs;

        let unicast_activityLogs: string[] = [...activityLogs];
        let fragment_activityLogs: string[] = [...activityLogs];
        let redundancy_activityLogs: string[] = [...activityLogs];
        let reboot_activityLogs: string[] = [...activityLogs];

        let unicast_activity_index: number = unicast_activityLogs.indexOf('Setup Complete');
        if (unicast_activity_index < 0) {
            unicast_activity_index = unicast_activityLogs.findIndex(i => i.includes('Setup Failed'));
            unicast_activityLogs = unicast_activity_index > 0 ? unicast_activityLogs.slice(0, unicast_activityLogs.length) : unicast_activityLogs;
            unicast_activity_index = unicast_activityLogs.length - 1;
        } else {
            unicast_activityLogs = unicast_activity_index >= 0 ? unicast_activityLogs.slice(0, unicast_activity_index + 1) : unicast_activityLogs;
        }

        // convert fragment activity log string from array
        let fragment_activity_last_index: number = fragment_activityLogs.findIndex(i => i.includes('Sending Redundancy Fragments:'));
        if (fragment_activity_last_index < 0) {
            fragment_activity_last_index = fragment_activityLogs.findIndex(i => i.includes('Multicast error'))
        }
        if (fragment_activity_last_index < 0) {
            fragment_activity_last_index = fragment_activityLogs.findIndex(i => i.includes('Sending Fragments'))
            fragment_activity_last_index = fragment_activity_last_index > 0 ? fragment_activity_last_index + 1 : fragment_activity_last_index;
        }
        fragment_activityLogs = fragment_activity_last_index > 0 ? fragment_activityLogs.slice(unicast_activity_index + 1, fragment_activity_last_index) : fragment_activityLogs.slice(unicast_activity_index + 1, fragment_activityLogs.length);

        // convert redundancy activity log string from array
        let redundancy_activity_last_index: number = redundancy_activityLogs.indexOf('rebootMsgReq');
        if(redundancy_activity_last_index < 0){
            redundancy_activity_last_index = redundancy_activityLogs.indexOf('Device Reset');
        }
        redundancy_activityLogs = redundancy_activity_last_index > 0 ? redundancy_activityLogs.slice(fragment_activity_last_index, redundancy_activity_last_index) : fragment_activity_last_index > 0 ? redundancy_activityLogs.slice(fragment_activity_last_index, redundancy_activityLogs.length) : [];

        //convert reboot activity log string from array
        reboot_activityLogs = redundancy_activity_last_index > 0 ? reboot_activityLogs.slice(redundancy_activity_last_index, reboot_activityLogs.length) : [];

        const phases = { unicast_activityLogs, fragment_activityLogs, redundancy_activityLogs, reboot_activityLogs };

        for (const phase in phases) {
            phases[phase] = phases[phase].reduce((previousValue, currentValue, currentIndex) => {
                currentValue = currentValue.trim().replace(/ +/g, ' ')
                if (currentIndex % 2 == 0) {
                    previousValue.push(`${currentValue}`);
                }
                else {
                    previousValue.push(` → ${currentValue}`);
                }
                return previousValue;

            }, []);
            phases[phase] = phases[phase].join(',').replace(/, →/g, ' →').replace(/,/g, '\n');

        }
        return phases;

    }

    const getCurrentPhaseFromDeviceSessionProgress = (progress) => {
        let current_phase = { phase_name: "Unicast Phase", progress: progress.progress_unicast_phase, in_progress: true };
        if (progress.progress_unicast_phase === 100) {
            current_phase.phase_name = "Fragment Phase";
            current_phase.progress = progress.progress_fragment_phase;
        } if (progress.progress_fragment_phase === 100) {
            current_phase.phase_name = "REDUNDANCY_PHASE";
            current_phase.progress = progress.progress_redun_fragment_phase;
        } if (progress.progress_redun_fragment_phase === 100) {
            current_phase.phase_name = "REBOOT_PHASE";
            current_phase.progress = progress.progress_reboot_phase;
        }
        return current_phase;
    }


    React.useEffect(() => {

        async function fetch() {
            let  sessiondata:any = await calculateProgress(props.row);
            setSessionData(sessiondata);
        }

        fetch();

    },[])
    
    if(sessiondata) {
        const isFullBar = (sessiondata.status == 'WRAPPING' || sessiondata.status == "Running" || sessiondata.status == 'InProgress' || sessiondata.status == 'Paused') ? false : true;
        const barPercent = isFullBar ? 100 : (sessiondata.progress?.total ?? 0);
        return <div style={{width: "100px"}} className="text-center">
            <ProgressBar striped animated={!isFullBar} now={barPercent} /> &nbsp;<span>{Number(barPercent).toFixed(2)}%</span>
        </div>
    } else {
        return <div style={{width: "100px"}}>&nbsp;<ProgressBar striped variant="primary" now={0} />
        <div className="text-center mt-1">{`${0}%`}</div></div>
    }
})

export default MyCampaignsSessions;