import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';

import { strings } from "./../../services/Localization";

import { faList } from '@fortawesome/pro-regular-svg-icons'

import { getAppBase } from '../../utils/consts';

import {  TAB_ADD_GROUP  } from '../../datatypes/tabsconstants'

const detailPageNav = ( navigate, tab) => {
    
    navigate( `${getAppBase()}/groups/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/groups`}})
}


const GroupAddProps:DetailPagePropsType =  {
    getBreadCrumbArr: ( breadCrumbArgs ) => {
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '-1';

        const breadCrumb:BreadCrumbType[] = [
            {label: "Groups",  url: prevPageUrl},
            {label: "Add Group",  url:''}
        ]
        return breadCrumb;
    },    
    getActions : (actionArgs, navigate) => {
        
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';

        const actions: DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.NAV_MANAGE_GROUPS,
                icon: faList,
                key: TAB_ADD_GROUP,
                action: (id) => detailPageNav(navigate, TAB_ADD_GROUP),
                content: {
                    type: 'SchemaModal',
                    url:'/rest/groups?get_schema=dassui,post',
                    backUrl: prevPageUrl
                }
            }                 
            
        ]

        return actions;
    }
}

export default GroupAddProps;


