import React, {useEffect} from "react";
import { strings } from "./../../services/Localization";
import { DataTable } from '../../components/Common';
import { ActionType, BulkActionType, ColumnType, DataTableOption } from "../../components/Common/DataTable/DataTypes";
import { PageButtonType } from '../../datatypes/datatypes';
import{ DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";
import { dialog } from '../../components/Common';
import { IUser } from "../../dassTypes";
import { faTrashAlt, faRefresh, faLockOpen, faLock } from '@fortawesome/pro-regular-svg-icons';
import  { dateTimeString, formatPayload } from '../../utils/filters';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "./../../utils/Toaster";


interface IRowType {
	confirmed: false;
	data: string;
	decrypted: boolean;
	fcnt: number;
	id: number;
	port: number;
	timestamp: string;
	transmissionStatus: number;
	errorcode: number;
	decoded: any;

}

// interface IGroupsStates {
//     showAlertModal: boolean;
//     editUserId: string;
//     pageTitle: string;
//     refresh:boolean;
// }


/*
export enum McPayloadError {
    NO_ERROR = 0,
    NO_FCNT_PROVIDED = 1,
    PAYLOAD_SIZE_EXCEEDS_LIMIT = 2,
    NO_MCAST_GRP_ID_PROVIDED = 3,

    UNKNOWN_ERROR = 4,

    NO_DEVADDRESS_PROVIDED = 5,
    NO_DATA_PROVIDED = 6,
    NO_NWKSKEY_PROVIDED = 7,
    MCAST_MAC_FRAME_FAILED = 8,
    NO_DEV_IN_MCAST_GROUP = 9,
    NO_GTW_FOR_MCAST = 10,
    PARTIAL_MCAST_DOWNLINK = 11,
    DUPLICATE_MSG_NUMBER = 12,
    MCAST_INTERNAL_ERROR = 13,
};
*/

const mcErrorCodes = [
	"Unknown error 0",          "Wrong FCNT",                       "Message is too long",                "Invalid Multicast Group ID",
	"Unknown Error",            "No McAddrs provided",              "Message Empty",                      "No McNwkKey provided",
	"MAC frame failed",         "No devices in Multicast Group",	"No Gateway available for Multicast", "Partial Multicast Downlink",
	"Duplicate Message number", "Multicast internal error"
];




function renderDecodedPayloadTo(decodedObj: object): JSX.Element {

	if (!decodedObj) { return null; }
	const newDecodedObj = {};

	Object.keys(decodedObj).forEach((key) => {
		if (decodedObj[key] != null){
			newDecodedObj[key] = decodedObj[key];
		}
	});

	// finding the key with most characters
	let longestKeyLength = null;
	for (const key of Object.keys(newDecodedObj)) {

		if (!longestKeyLength) {
			longestKeyLength = key.length;
		}
		else if (key.length > longestKeyLength) {
			longestKeyLength = key.length;
		}
	}

	let html = "";
	for (const key of Object.keys(newDecodedObj)) {

		var keyString = key;
		var keyLength = keyString.length;
		for (var i = keyLength; i < longestKeyLength; i++) {
			keyString += ' ';
		}

		var result;
		if (typeof newDecodedObj[key] === "object") {
			var jsonStringify = JSON.stringify(newDecodedObj[key]).replace(/,/g, ", ");
			var strReplace = jsonStringify.replace(/\"([^(\")"]+)\":/g,"$1:").slice(1, -1);
			result = strReplace.replace(/\"|'|null| _/gi, "");
		} else {
			result = newDecodedObj[key];
		}
		html += '<div class="row"><div class="col-md-12 key-container">' + keyString + ' = ' + result + '</div></div>';
	}

	return <div className="column-inside-container container" dangerouslySetInnerHTML={{__html: html}} />;
}

interface GroupDataState {

	groupid:string;
	user:IUser;
	apps:string[];
	refresh:boolean;
	workingCount:number;
	currentGroup:string;
	device_status:string
	activation:string;
	payloads:object[];
	customFilter:object,
	lorawanVersion:string;
	show_mac_msg:boolean,
	RFRegion: string;
	groupMulticastsMessage:string;
	can_list_profile:string;
	isRowDeleted?:boolean;
}

const GroupActivityDataTable:React.FC<{groupid:string, user: IUser}> = ( props ) =>  {

    const groupid = props.groupid;
    const initState = {
		groupid: groupid ,
		workingCount:0,
		currentGroup:null,
		user:props.user,
		device_status:undefined,
		activation:undefined,
		payloads:[],
		customFilter:{ mac_msg: false },
		lorawanVersion:undefined,
		show_mac_msg:false,
		RFRegion: "",
		groupMulticastsMessage:strings.GROUP_MULTICASTS_MESSAGE,
		can_list_profile:"can_list_profile",
		apps:[],
		refresh:false,
		isRowDeleted:false
	}

	const [state, setState] = React.useState<GroupDataState>(initState);

	useEffect(() => {
	},[]);

	const refreshTable = (isRowDeleted=false) => { 

		setState(prevState => {
            let refresh = (prevState.refresh === true)  ? false : true;
            return {...prevState, refresh:refresh,isRowDeleted:isRowDeleted}
        })
	} // Fixme how to do refresh here pending

	const deletePayloadInBulk =  async ( payloads ) => { // Fixme getting undefined value
        
		// const count = payloads?.length ;
		if(payloads && payloads.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to delete the selected multicast message(s)`,
                description: "",
                actionLabel: 'Delete',
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(payloads.map((payload) => {
                        return GenericDassQuery("/rest/multicast/" + groupid + "/payloads/dl/" + payload.id, { method: "DELETE" });
                    })).then((values) => {
                        refreshTable(true);
                        toast.success("Multicast messages successfully deleted");
                    });
                } catch (e) {
                    toast.error(e.message);
                }
            }
        }
    }


    const deletePayload =  async (payload: IRowType) => {
        const confirmDialogeSettings = {
            title:  "Do you want to delete this multicast message",
            description: "",
            actionLabel: 'Delete',
        };
      
        if (await dialog(confirmDialogeSettings) === true) {
            try {

                Promise.resolve(GenericDassQuery("/rest/multicast/" + groupid + "/payloads/dl/" + payload.id, { method: "DELETE" })).then((values) => {
                    
                    refreshTable(true);
                    
                    toast.success("Multicast message successfully deleted");

                });
               
            } catch (e) {
                toast.error(e.message);
            }
        }
    }

    const getActions =  () => {
        
		const actions: ActionType<IRowType>[] = [{
			type: "action",
			text: strings.DATA_DELETE_PACKET,
			icon: faTrashAlt,
			action: (packet) => {
				deletePayload(packet)
			}
		}]

		const bulkActions: BulkActionType<IRowType>[] = [{
			type: "action",
			text: strings.DATA_DELETE_PACKETS,
			icon: faTrashAlt,
			action: (packets) => { deletePayloadInBulk(packets) }
		}]

        return { actions, bulkActions }

    }



	const getPageButtons = () => {
        
        const pageButtons:PageButtonType[] = [
            
            {
                title: strings.REFRESH_LIST,
                action: () => {  },
                type: 'refresh',
				icon: faRefresh
            }
        ]

        return pageButtons;
    
	}

    const getDataTableOptions = () => {
		
		const { actions, bulkActions } = getActions();
        
		
		const columns: ColumnType<IRowType>[] = [{
			key: "timestamp",
			title: strings.TIME,
			type: "text",
			render: (x) => dateTimeString(x.timestamp),
			filterable: true,
			filterField: "date",
			filterType: 'daterange',
			filterParams: {
				startField: "from_date",
				endField: "to_date",
				mapper: (x) => x && x.format()
			},
			sortable: true,
			newCellWidth: "200px",
			sortKey: "sort_by_timestamp",
			cellWidth: 15
			//initialSorting: 2,							// default descending sort
			//validSortDirections: [false, true, true],	// always sort

		}, {
			key: "fcnt",
			title: strings.FCNT,
			type: "text",
			filterable: false,
            filterType: 'text',
			newCellWidth: "70px",
			dataAlign: "center",
            filterField: 'fcnt',
            filterParams: {
                mapper: (x) => x || undefined
            },
			render: (x) => x.fcnt != undefined ? x.fcnt.toString() : '',
			cellWidth: 3,
		}, {
			key: "port",
			title: strings.PORT,
			type: "text",
			filterable: true,
            filterType: 'text',
			newCellWidth: "70px",
			filterWidth: "50px",
			inputValidation:"^([a-z0-9\\s\\-\\.])*$",
			filterField: 'search_port',
			dataAlign: "center",
			filterParams: {
                mapper: (x) => x || undefined
			},
			render: (x) => x.port != undefined ? x.port.toString() : '',
			cellWidth: 3,
		},
		{
			key: "transmissionStatus",
			title: strings.STATUS,
			type: "text_with_tooltip",
			filterable: false,
			filterField: 'transmissionStatus',
			filterType: 'select',
			cellWidth: 8,
			newCellWidth: "70px",
			dataAlign: "center",
			filterParams: {
				options: [
					{ label: strings.GATEWAY_STATUS_ANY, value: "" },
					{ label: strings.PACKET_STATUS_0,    value: 0 },
					{ label: strings.PACKET_STATUS_1,    value: 1 },
					{ label: strings.PACKET_STATUS_2,    value: 2 },
					{ label: strings.PACKET_STATUS_3,    value: 3 },
					{ label: strings.PACKET_STATUS_4,    value: 4 },
					{ label: strings.PACKET_STATUS_5,    value: 5 }
				]
			},
			render: (x) => (
				x.transmissionStatus == 0 && strings.PACKET_STATUS_0 ||
				x.transmissionStatus == 1 && strings.PACKET_STATUS_1_1 ||
				x.transmissionStatus == 2 && strings.PACKET_STATUS_2 ||
				x.transmissionStatus == 3 && strings.PACKET_STATUS_3 || 
				x.transmissionStatus == 4 && strings.PACKET_STATUS_4 ||
				x.transmissionStatus == 5 && strings.PACKET_STATUS_5) + 
					(x.transmissionStatus === 1 && typeof x.errorcode === "number" && x.errorcode !== 0 ? "*" : ""),

			render_tooltip: x => {
				if (typeof x.errorcode === "number"  &&  x.errorcode !== 0  &&  mcErrorCodes[x.errorcode]) {
					return mcErrorCodes[x.errorcode] || "Unknown Error";
				}
				return null;
			},


		}, {
			key: "decrypted",
			type: "icon_with_tooltip",
			title: strings.DECRYPTED,
			newCellWidth: "100px",
			render_icon: (row) => row.decrypted ? faLockOpen : faLock,
			dataAlign: 'center',
			cellWidth: 'nowrap'
		}, {

			key: "data",
			title: strings.DATA,
			type: "text",
			render: (x) => x.data && formatPayload(x.data)
		},

		{
			key: "decoded",
			title: strings.SHOW_DECODE_PAYLOAD_SHORT,
			type: "text",
			render: row => renderDecodedPayloadTo(row.decoded),
		}
        ];
		
        let options: DataTableOption = {
            url:  `/uiapi/rest/multicast/${groupid}/payloads/dl`,
            query_param: {all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress',mac_msg: false},
            serial_number:false,
            id_field:'id',
			defaultSortField: 'sort_by_timestamp',
			defaultSortOrder: 'desc',
            oboe_path:'pages.*',
            available_key: 'id',
			countLabel:'Messages',
			columns,
			actions,
			bulkActions,
        }
        
        return options;
	}    

    return (<DataTable isRowDeleted={state.isRowDeleted}  name={`group-activity-${groupid}`} countLabel={'Messages Stored'}  dataTableOption={getDataTableOptions()} pageButtons={getPageButtons()} scope="child" refresh={state.refresh} />)
}


export  default GroupActivityDataTable

