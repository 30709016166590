import React from 'react';

import { Outlet  } from 'react-router-dom';


const NstContainer = () => {

    return (<Outlet />)
    
}

export default NstContainer;