import React, {useState, useEffect, useContext } from "react";
import { useParams, useNavigate  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faPlay, faStop, faTriangleExclamation }  from '@fortawesome/free-solid-svg-icons';
import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
import AppContext from '../../context/AppContext'
import { dialog } from '../../components/Common';
import PageContent from "../PageContent";
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "./../../utils/Toaster";
import { TAB_SERVER_GRAPH } from '../../datatypes/tabsconstants'
import { appBaseUrl, getAppBase } from '../../utils/consts';
import{ DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";
import { IUser } from "../../../src/dassTypes";
import { ActionType, ColumnType, DataTableOption } from "../../../src/components/Common/DataTable/DataTypes";

declare const constants;
interface IServerStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    pageTitle: string;
    editEntityId: string | null;            // The entity in this view is a gateway
    schemaMethod: "post" |"put" | "" | string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
}

interface IServerProps { }

const ServersStatus: React.FC<IServerProps> = (props) =>  {

    const { id, tabname } = useParams();
    const navigate = useNavigate();
    const AppContextObj = useContext(AppContext);

    const stateInit: IServerStates = {
        loggedUser: null,
        showAlertModal: false,
        pageTitle: strings.GATEWAYS_TITLE,
        editEntityId: '',
        schemaMethod: "",
        breadCrumbArr: [{label: strings.NAV_NST_SERVERS, url:''}],
        refresh:false
    };

    const [state, setState] = useState<IServerStates>(stateInit)


    useEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {
            if (checkLogin(AppContextObj.user)) {
                setState(prevState => { return {...prevState, loggedUser: AppContextObj.user}})
            } else {
                window.location.href = appBaseUrl + "/signin";
            }
            //LoggedIn();
        }
    },[])


    const detailPageNav = ( navigate, tab, id, row = null) => {
        if(id) {
            
            navigate(`${getAppBase()}/servers-status/${id}/${tab}`, {state: {row: row, prevPageUrl:`${getAppBase()}/servers-status`}});
        }
    }

    interface IOmcServers  {

        status: string;
        int_ip_address: string;
        last_rebooted_time?: Date;
        last_status_not_ok_time?: Date;
        last_status_ok_time?: Date;
        num_active_threads: number;
        restPort: number;
        server: string;
        server_function: string;
        server_id: string;
        server_load_avg: number;
        server_max_memory: string;
        server_status: string;
        server_used_memory: string;
        serverVersion: string;
        subnets_ip: [];
        timestamp: Date;
        type: string;
    
        }

    const stopServer = async ( server: IOmcServers ) => {

        const confirmDialogeSettings = {
            title:  `You are about to Stop ${server.server_function}: ${server.int_ip_address} ?`,
            description:'Confirm',
            actionLabel: strings.NST_SERVERS_STOP_SERVER,
        }
        if(await dialog(confirmDialogeSettings) === true) {
            try {
                await GenericDassQuery("/rest/omc/start_stop_server", {
                    method: "PUT",
                    data: { 
                        action: "stop",
                        ip_address: server.int_ip_address,
                        server_type: server.server_function
                    }
                });
                toast.success("Server Stopped !!");
            } catch (e) {
                toast.error(e.message);
            }
            refreshTable();
        }
    }

    const startServer = async ( server: IOmcServers ) => {

        const confirmDialogeSettings = {
            title:  `You are about to (Re)Start ${server.server_function}: ${server.int_ip_address} ?`,
            description:'Confirm',
            actionLabel: strings.NST_SERVERS_RESTART_SERVER,
        }
        if(await dialog(confirmDialogeSettings) === true) {
            try {
                await GenericDassQuery("/rest/omc/start_stop_server", {
                    method: "PUT",
                    data: { 
                        action: "restart",
                        ip_address: server.int_ip_address,
                        server_type: server.server_function
                    }
                });
                toast.success("Server (Re)Started !!");
            } catch (e) {
                toast.error(e.message);
            }
            refreshTable();
        }
    }


    const  getActions = () => {

        const actions: ActionType[] = [
                {
                    type: "action",
                    text: strings.NST_SERVERS_RESTART_SERVER,
                    icon: faPlay,
                    action: (server) => startServer(server),
                    visible: (server) => server?.type !== "hw" && AppContextObj.user.omc_mng_servers,
                },
                {
                    type: "action",
                    text: strings.NST_SERVERS_STOP_SERVER,
                    icon: faStop,
                    action: (server) => stopServer(server),
                    visible: (server) => server?.type !== "hw" && AppContextObj.user.omc_mng_servers,
                }
        ];

        return actions;
    }


/*

   "server": "NS_LB-127.0.0.1",
    "timestamp": "2022-04-09T04:24:34.042+0000",
    "numActiveThreads": 59,
    "dbLookupMs": 0,
    "serverLoadAvg": 0.015267175622284412,
    "serverMaxMemory": 60293120,
    "serverUsedMemory": 8578600,
    "_links": "woah",
    "serverId": "NS_LB-127.0.0.1",
    "type": "sw",
    "serverFunction": "NS_LB",
    "alarmStatus": "NONE",
    "serverStatus": "Running",
    "intIpAddress": "127.0.0.1",
    "subnetsIp": [
      [
        "wan",
        "34.251.223.196"
      ]
    ],
    "lastStatusOk": "2022-05-03T21:35:20.159+0000",
    "restPort": 7210,
    "lastStatusNotOk": "2022-05-02T13:33:41.815+0000",
    "lastRebooted": "2022-04-27T05:10:34.532+0000",
    "serverVersion": "R6.2-RC1"

*/


    const  initDataTable = () => {

        const actions = getActions();

        const columns: ColumnType[] = [
            {
                key: 'bulk_action_checkbox',
                type: "bulk_action_checkbox",
                title: 'Bulk Action',
                filterable: false,
                cellWidth: 2,
                newCellWidth: "2%"
            },
            {
                key: "server_function",
                cellWidth: 10,
                type: "text",
                title: strings.TABLE_NST_SERVER,
                filterable: false,
                detailLink: true,
                detailPageNav: (row) => detailPageNav(navigate, TAB_SERVER_GRAPH, row.server_id, row),
                // customClass: 'font-monospace nowarp'
            },
            {
                key: "int_ip_address",
                title: strings.TABLE_NST_IP_ADDRESS,
                cellWidth: 20,
                type: "text",
                filterable: false,
            },
            {
                key: "server_id",
                title: strings.TABLE_NST_METRICS,
                cellWidth: 100,
                cellWidthType: "%",
                type: "text",
                filterable: false,
                render: (x) => {
                    let res: JSX.Element[] = [];
                    const info    = "badge ow-nst-server-status-info-normal"
                    const normal  = "badge ow-nst-server-status-info-ok";
                    const warning = "badge ow-nst-server-status-info-warning";
                    const crtical = "badge ow-nst-server-status-info-critical";
            
                    if (typeof x.server_max_memory === "string")  {
                        const value = Math.round(parseInt(x.server_max_memory) / 10000000) / 100
                        res.push(<span key="1" className={info}>{"Max heap [GB]: " + value + " "}</span>);;
                    }
                    if (typeof x.server_used_memory === "string")  {
                        const value       = Math.round(parseInt(x?.server_used_memory) / 10000000) / 100;
                        const maxValue    = Math.round(parseInt(x.server_max_memory) / 10000000) / 100;
                        const statusClass = (value * 100)/(maxValue) > parseInt(x?.threshold_value?.used_heap_sev) ? crtical
                                          : (value * 100)/(maxValue) > parseInt(x?.threshold_value?.used_heap_warn) ? warning 
                                          : normal
                        res.push(<span key="2" className={statusClass}>{"Used memory[GB]: " + value + " "}</span>);;
                    }
                    if (typeof x.num_active_threads === "number")  {
                        const value = x.num_active_threads;
                        res.push(<span key="3" className={info}>{"Num active threads: " + value }</span>);;
                    }
                    if (typeof x.server_load_avg === "number")  {
                        const value = Math.floor(x.server_load_avg * 100);
                        const statusClass = value > parseInt(x?.threshold_value?.serv_load_sev) ? crtical
                                          : value > parseInt(x?.threshold_value?.serv_load_warn) ? warning 
                                          : normal
                        res.push(<span key="4" className={statusClass}>{"Server load [%]: " + value }</span>);;
                    }
                    if (typeof x.mem_total === "string")  {
                        const value = Math.floor(parseInt(x.mem_total)  / 10000000) / 100;
                        res.push(<span key="5" className={info}>{"Total memory [GB]: " + value + " "}</span>);;
                    }
                    if (typeof x.mem_used === "string")  {
                        const value       = Math.floor(parseInt(x.mem_used)  / 10000000) / 100;
                        const maxValue    = Math.floor(parseInt(x.mem_total) / 10000000) / 100;
                        const statusClass = (value * 100)/(maxValue) > parseInt(x?.threshold_value?.used_mem_sev) ? crtical 
                                          : (value * 100)/(maxValue) > parseInt(x?.threshold_value?.used_mem_warn) ? warning 
                                          : normal
                        res.push(<span key="6" className={statusClass}>{"Used memory[GB]: " + value + " "}</span>);;
                    }
                    if (typeof x.disk_size === "string")  {
                        const value = Math.floor(parseInt(x.disk_size)  / 10000000) / 100;
                        res.push(<span key="7" className={info}>{"Disk Size[GB]: " + value + " "}</span>);;
                    }
                    if (typeof x.disk_free_percent === "string")  {
                        const value       = Math.floor(parseInt(x.disk_free_percent))
                        const statusClass = value < parseInt(x?.threshold_value?.disk_free_sev) ? crtical
                                          : value < parseInt(x?.threshold_value?.disk_free_warn) ? warning
                                          : normal
                        res.push(<span key="9" className={statusClass}>{"Disk Free Size[%]: " + value }</span>);;
                    }
                    if (typeof x.cpu_load_15_min === "number")  {
                        const value       = Math.floor(x.cpu_load_15_min * 100);
                        const statusClass = value > parseInt(x?.threshold_value?.cpu_load_sev) ? crtical
                                          : value > parseInt(x?.threshold_value?.cpu_load_warn) ? warning 
                                          : normal
                        res.push(<span key="10" className={statusClass}>{"Avg load [%]: " + value }</span>);
                    }
                    if (typeof x.max_conn === "number")  {
                        const value = x.max_conn
                        res.push(<span key="11" className={info}>{"Max connection: " + value}</span>);
                    }
                    if (typeof x.om_db_conn === "number")  {
                        const value       = x.om_db_conn;
                        const statusClass = value > parseInt(x?.threshold_value?.db_conn_sev) ? crtical 
                                          : normal
                        res.push(<span key="12" className={statusClass}>{"om_db conn: " + value}</span>);
                    }
                    return (<div className="ow-nst-server-status-info">{res}</div>) as any;
                }
            },
            {
                key: "type", // Fixme Just added to have unique key
                title: strings.TABLE_NST_SERVER_STATUS,
                cellWidth: 2,
                type: "text",
                filterable: false,
                render: (x) => {
                    let res: JSX.Element[] = [];
                    if(x.status === "MINOR")
                    res.push(<FontAwesomeIcon icon={faTriangleExclamation} color="#e49626" size="xl"></FontAwesomeIcon>)
                    if(x.status === "CRITICAL")
                    res.push(<FontAwesomeIcon icon={faTriangleExclamation} color="#a94442" beatFade size="xl"></FontAwesomeIcon>)
                    return (<div className="ow-nst-server-status-info">{res}</div>) as any;
                },
                dataAlign: "center",
                newCellWidth: "70px"

            },
            {
                key: "status",
                title: strings.TABLE_NST_ALARM_STATUS,
                cellWidth: 20,
                type: "text",
                filterable: false,
            },

            {
                key: "server_state",
                title: strings.TABLE_NST_SERVER_STATE,
                cellWidth: 20,
                type: "text",
                filterable: false,
            },
        ];


        const options: DataTableOption = {
            url:'/uiapi/rest/omc/server_details',
            query_param: {
                get_pages: true,
                limit: DEFAULT_RECORD_LIMIT,
                stream: "progress",
            } as any,                       // TODO: if optional parameters are not provided here, then where?
            serial_number: false,
            id_field: 'server_id',
            oboe_path: 'pages.*',
            available_key: 'server_id',
            columns,
            actions: actions,
        }

        return options;
	}

    const refreshTable = () => {
        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })
    }


    const  getPageButtons = () => {

        var  pageButtons: PageButtonType[] = [
            {
                title: strings.REFRESH,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh,
            },
        ]

        return pageButtons;
    }
    return (<PageContent
            name={`server-status`} 
            id={id} 
            tabname={tabname} 
            actions={getActions()} 
            breadCrumbArr={state.breadCrumbArr} 
            pageButtons={getPageButtons()} 
            countLabel={`Server State`} 
            dataTableOption={initDataTable()} 
            refresh={state.refresh}>
        </PageContent>)

}

export default ServersStatus;