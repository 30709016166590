// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid {
    display: grid;
    grid-gap: 10px;
    flex: 1 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(550px,1fr));
}
.grid-coloum-2 {
    grid-template-columns: repeat(auto-fill, minmax(550px,1fr));
}
.grid-coloum-1 {
    grid-template-columns: repeat(auto-fill, minmax(750px,1fr));
    }
.parent-container {
    column-count: 2;
    column-gap: 10px;
    width: 100%;
  }

  .schema-item {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    break-inside: avoid;
    grid-template-columns: repeat(auto-fill, minmax(200px,1fr));
  grid-auto-rows: 0;
  }

  /* MOBILE MEDIA QUERY */
@media (max-width: 767px) {
    .parent-container {
        column-count: 1;
      }
}`, "",{"version":3,"sources":["webpack://./app_react/src/components/SchemaModal/SchemaLayout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,cAAc;IACd,2DAA2D;AAC/D;AACA;IACI,2DAA2D;AAC/D;AACA;IACI,2DAA2D;IAC3D;AACJ;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,SAAS;IACT,aAAa;IACb,4BAA4B;IAC5B,mBAAmB;IACnB,mBAAmB;IACnB,2DAA2D;EAC7D,iBAAiB;EACjB;;EAEA,uBAAuB;AACzB;IACI;QACI,eAAe;MACjB;AACN","sourcesContent":[".grid {\n    display: grid;\n    grid-gap: 10px;\n    flex: 1 0 auto;\n    grid-template-columns: repeat(auto-fill, minmax(550px,1fr));\n}\n.grid-coloum-2 {\n    grid-template-columns: repeat(auto-fill, minmax(550px,1fr));\n}\n.grid-coloum-1 {\n    grid-template-columns: repeat(auto-fill, minmax(750px,1fr));\n    }\n.parent-container {\n    column-count: 2;\n    column-gap: 10px;\n    width: 100%;\n  }\n\n  .schema-item {\n    margin: 0;\n    display: grid;\n    grid-template-rows: 1fr auto;\n    margin-bottom: 10px;\n    break-inside: avoid;\n    grid-template-columns: repeat(auto-fill, minmax(200px,1fr));\n  grid-auto-rows: 0;\n  }\n\n  /* MOBILE MEDIA QUERY */\n@media (max-width: 767px) {\n    .parent-container {\n        column-count: 1;\n      }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
