// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .img_padding_bottom {
    padding-bottom: 10px;
} */

 
.form-signin{
    max-width: 330px !important;
    padding: 15px;
    margin: 0 auto;
}
.form-signin .form-signin-heading, .form-signin .checkbox{
    margin-bottom: 10px;
}
.navbar-dark .navbar-nav .nav-link {
    color: rgb(255 255 255);

    font-weight: normal !important;
}



 

/*
textarea:focus, input:focus{
    outline: none;
}


.input-height {
    height:50px;
  }*/
`, "",{"version":3,"sources":["webpack://./app_react/src/pages/SignIn/signin.css"],"names":[],"mappings":"AAAA;;GAEG;;;AAGH;IACI,2BAA2B;IAC3B,aAAa;IACb,cAAc;AAClB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,uBAAuB;;IAEvB,8BAA8B;AAClC;;;;;;AAMA;;;;;;;;IAQI","sourcesContent":["/* .img_padding_bottom {\n    padding-bottom: 10px;\n} */\n\n \n.form-signin{\n    max-width: 330px !important;\n    padding: 15px;\n    margin: 0 auto;\n}\n.form-signin .form-signin-heading, .form-signin .checkbox{\n    margin-bottom: 10px;\n}\n.navbar-dark .navbar-nav .nav-link {\n    color: rgb(255 255 255);\n\n    font-weight: normal !important;\n}\n\n\n\n \n\n/*\ntextarea:focus, input:focus{\n    outline: none;\n}\n\n\n.input-height {\n    height:50px;\n  }*/\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
