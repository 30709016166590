import React from 'react';

import { createContext } from 'react';

const DataTableChildContext = createContext(null);

export { DataTableChildContext }

const tableSettings = {
    visibleColumnsIndexArr:[],
    hiddenColumnsIndexArr:[]
};

export { tableSettings  }

const DataTableChildState  =  ( props ) => {

    return  (
            <DataTableChildContext.Provider value={props.values}>
               {props.children}
            </DataTableChildContext.Provider>
            )
}


export { DataTableChildState };