import React, { useState, useEffect } from "react";

import ReactDOM from 'react-dom';

import { Modal, Spinner } from 'react-bootstrap';

interface SpinnerDialog {
    show: boolean;

}

const SpinnerDialog: React.FC<SpinnerDialog> = ( props ) => {
  const [show, setShow] = useState(false);

  
  useEffect(() => {
    setShow(preShow => props.show);
  },[props.show])

   return (<Modal
                show={show}
                backdrop="static"
                keyboard={false}
                centered
            >
            <Modal.Body>
                <Spinner animation="border" />
            </Modal.Body>
            
        </Modal>);
}

export { SpinnerDialog };


class spinnerClass  {

  static  show =  () => {
     ReactDOM.render(<SpinnerDialog  show={true}/>, document.getElementById("dailog"));
  }

  static  hide = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById("dailog"))
  }

  
}


export { spinnerClass }

